import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import Modal from 'components/UI/Modal';
import './style.scss';
import { deactivateBucket } from 'services/buckets/operations';
import { getSocket } from 'services/sockets';
import { focusInput } from 'utils/forms';
import { Drug } from 'components/drugs/types';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Alert, Button, Collapse } from 'react-bootstrap';
import { getIntraysWithIncludedDrug } from 'services/orders/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExclusionInTrayTable from 'components/orders/Modals/ExclusionInTrayTable';
import { Order } from 'components/orders/types';
import { Bucket, DeactivatedReason } from './types';

interface FormValues {
  deactivatedReason: DeactivatedReason;
}

const initialValues: FormValues = {
  deactivatedReason: DeactivatedReason.BACK_ORDER,
};

interface DeactivateBucketModalProps {
  isOpen: boolean;
  bucket: Bucket;
  drug: Drug | undefined;
  onClose: () => void;
}

function DeactivateBucketModal({ isOpen, bucket, drug, onClose }: DeactivateBucketModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ordersINTRAY, setOrdersINTRAY] = useState<Order[]>([]);
  const [excludedDistributedId, setExcludedDistributedId] = useState<string[]>([]);
  const [showOrders, setShowOrders] = useState<boolean>(false);

  useEffect(() => {
    const fetchOrdersINTRAY = async () => {
      if (isOpen) {
        setOrdersINTRAY(await getIntraysWithIncludedDrug(bucket.id).then((res) => res.orders));
      }
    };

    fetchOrdersINTRAY();
  }, [isOpen, bucket.id, setOrdersINTRAY]);

  const handleOrderUpdated = useCallback(
    (updatedOrder: Order) => {
      setOrdersINTRAY((prevOrders) => {
        const orderIndex = prevOrders.findIndex((order) => order.id === updatedOrder.id);

        if (orderIndex !== -1) {
          const newOrders = [...prevOrders];
          newOrders[orderIndex] = updatedOrder;
          return newOrders;
        }
        return prevOrders;
      });
    },
    [setOrdersINTRAY],
  );

  useEffect(() => {
    const socket = getSocket();
    socket?.on('orders.updated', handleOrderUpdated);

    return () => {
      socket?.off('orders.updated', handleOrderUpdated);
    };
  }, [handleOrderUpdated]);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    try {
      await deactivateBucket(bucket.id, values.deactivatedReason)(dispatch);
      setSubmitting(false);
      handleClose();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED TO DEACTIVATE BUCKET', setFieldError);
    }
  };

  const handleClose = () => {
    setExcludedDistributedId([]);
    onClose();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ errors, touched, handleSubmit, isSubmitting, setFieldValue }) => (
        <Modal
          show={isOpen}
          onHide={handleClose}
          modalClass="deactivate-bucket-modal"
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          disabledSubmitButton={
            ordersINTRAY.filter((o) => !o.drugs.find((d) => d.DIN === bucket.DIN)?.isFlagged)
              .length !== excludedDistributedId.length
          }
          title={t('deactivateBucket', {
            position: bucket.position,
            DIN: bucket.DIN,
            drugName: drug ? drug.name.toUpperCase() : '-',
          })}
          submitButtonText={t('deactivate')}
        >
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Form
              id="DeactivateBucket_form"
              noValidate
              onSubmit={(e) => {
                if (Object.keys(errors).length > 0) {
                  focusInput(Object.keys(errors)[0]);
                }
                handleSubmit(e);
              }}
            >
              <Form.Group>
                <Form.Label>{t('reason')}</Form.Label>
                <select
                  name="deactivatedReason"
                  defaultValue={DeactivatedReason.BACK_ORDER}
                  onChange={(e) =>
                    setFieldValue('deactivatedReason', e.target.value as DeactivatedReason)
                  }
                  className={
                    errors.deactivatedReason && touched.deactivatedReason
                      ? ' custom-select error-select'
                      : 'custom-select'
                  }
                >
                  {Object.values(DeactivatedReason)
                    .filter((reason) => reason !== DeactivatedReason.EXPIRED_DRUGS)
                    .map((reason) => (
                      <option key={reason} value={reason}>
                        {t(reason)}
                      </option>
                    ))}
                </select>
                <ErrorMessage
                  name="deactivatedReason"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
            </Form>
          </div>
          {ordersINTRAY.length > 0 && (
            <>
              <Alert variant="warning">
                <div className="exclusions-alert-message">
                  <p>{t('linkOrdersNeedManualDistribution', { nbOrders: ordersINTRAY.length })}</p>

                  <Button
                    onClick={() => setShowOrders(!showOrders)}
                    aria-controls="exclusions-collapse-table"
                    aria-expanded={showOrders}
                    variant="link"
                  >
                    {showOrders ? (
                      <FontAwesomeIcon icon="angle-up" />
                    ) : (
                      <FontAwesomeIcon icon="angle-down" />
                    )}
                  </Button>
                </div>
                <Collapse in={showOrders}>
                  <div id="exclusions-collapse-table">
                    <ExclusionInTrayTable
                      bucketDIN={bucket.DIN}
                      orders={ordersINTRAY}
                      excludedDistributedId={excludedDistributedId}
                      setExcludedDistributedId={setExcludedDistributedId}
                    />
                  </div>
                </Collapse>
              </Alert>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
}

export default DeactivateBucketModal;
