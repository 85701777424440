export function parseForce(force: string): number {
  const match = force.match(/^\s*(\d+(?:\.\d+)?)\s*([^\d\s]+)/);
  if (!match) return 0;
  const [, value, unit] = match;
  const numValue = parseFloat(value);

  switch (unit.toLowerCase()) {
    case 'g':
      return numValue * 1000;
    case 'mg':
      return numValue;
    case 'mcg':
    case 'µg':
      return numValue / 1000;
    default:
      return numValue;
  }
}
