import React from 'react';
import { t } from 'i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DateTime } from 'luxon';
import { Order } from '../types';

interface TwoWeeksModalProps {
  show: boolean;
  order: Order | null;
  handleClose: () => void;
  sendOrder: () => void;
}

function TwoWeeksModal({ show, order, handleClose, sendOrder }: TwoWeeksModalProps) {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{t('warning')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('older2weeks')}</p>
        <hr />
        <p>
          {t('continuePrintOldOrder', {
            lookupId: order?.lookupId,
            createdAt: DateTime.fromISO(order?.createdAt).toFormat('yyyy-MM-dd'),
          })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            sendOrder();
            handleClose();
          }}
        >
          {t('send')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TwoWeeksModal;
