import React from 'react';

interface IconProps {
  selected: boolean;
  disabled: boolean;
}

const ThreeCardsIcon = ({ selected, disabled }: IconProps) => {
  let color = '';
  if (disabled && !selected) {
    color = 'grey';
  } else if (selected) {
    color = '#FFFFFF';
  } else {
    color = '#007bff';
  }
  return (
    <svg
      id="svg-3w"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="251.88679245283018"
      viewBox="0, 0, 400,251.88679245283018"
    >
      <g id="svgg-3w">
        <path
          id="path-3w"
          d="M396.1,9c0-0.3,0-0.5,0-0.7c0-0.5-0.1-0.9-0.2-1.2c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.3-0.2-0.5-0.3-0.7
        c-0.1-0.2-0.2-0.3-0.4-0.5c-0.2-0.2-0.4-0.3-0.7-0.5c-0.3-0.1-0.6-0.2-1-0.3c-0.6-0.1-1.2-0.2-2-0.2c-0.5,0-1.1,0-1.8,0
        c-126.3,0-252.6,0-378.9,0.1c-1.1,0-2,0-2.8,0.1C7.5,4.6,7,4.7,6.6,4.8C6.4,4.9,6.3,4.9,6.1,5C5.8,5.2,5.5,5.3,5.3,5.6
        C5,5.8,4.8,6.1,4.7,6.4c-0.1,0.3-0.2,0.6-0.3,1C4.3,7.9,4.2,8.4,4.2,9s-0.1,1.3-0.1,2.1c-0.1,76-0.2,152-0.3,228v0
        c0,0.4,0,0.8,0,1.2c0,2.7,0.3,4.3,1.2,5.1c0.4,0.4,0.9,0.6,1.6,0.8c0.4,0.1,0.9,0.2,1.5,0.2c0.4,0,0.8,0.1,1.2,0.1
        c0.7,0,1.4,0,2.3,0c62.8,0,125.6,0,188.5,0c0-0.1,0-0.2,0-0.3c63.2,0,126.3,0,189.5,0c6.3,0,6.6-0.3,6.6-6.3c0-76.3,0-152.7,0-229
        C396.2,10.2,396.2,9.6,396.1,9z M181,124.3c0,0-0.1,0-0.1,0c0,30.9,0,61.7,0,92.6c0,5.8-0.8,6.6-6.6,6.6c-47,0-94.1,0-141.1,0
        c-5.3,0-6.9-1.5-6.9-6.8c0.1-61.9,0.2-123.8,0.3-185.7c0-4.5,1.8-6,6.3-5.9c47.4,0.1,94.7,0.1,142.1,0c4.6,0,6.1,1.7,6,6.1
        C180.9,62.2,181,93.3,181,124.3z M373.4,124.5c0,30.7,0,61.4,0,92.1c0,5.9-0.7,6.6-6.6,6.6c-46.9,0-93.7,0-140.6,0
        c-6,0-7.4-1.3-7.4-7.3c0.1-61.2,0.2-122.5,0.3-183.7c0-6.7,0.7-7.3,7.5-7.3c46.4,0,92.7,0,139.1,0c7.5,0,7.7,0.3,7.7,8
        c0,30.5,0,61.1,0,91.6C373.4,124.5,373.4,124.5,373.4,124.5z M165.7,125.6C165.7,125.6,165.8,125.6,165.7,125.6
        c0.1-25.9,0.1-51.8,0.1-77.7c0-7.9-0.5-8.4-8.5-8.4c-35.7,0-71.5,0-107.2,0c-1.3,0-2.7,0.1-4,0.1c-4.5,0-6.4,1.9-6.4,6.6
        c0,52.6-0.2,105.2-0.3,157.8c0,5.8,0.9,6.7,6.6,6.7c37.9,0,75.8,0,113.7,0.1c4.5,0,6.1-1.5,6-6.1C165.6,178.3,165.7,152,165.7,125.6
        z M146.3,163.9c-6.4,0-11.6,0.2-16.7-0.1c-1.3-0.1-2.6-1.8-3.6-3c-7-8.4-13.9-16.9-21.3-25.8c-6.9,8.2-13.8,16.3-20.8,25
        c-3.1,3.9-4.5,4.1-6.1,4.1c-3.6,0.1-14.9-0.1-14.9-0.1c11.6-12.3,22.3-23.7,33.2-35.2c-10.5-12.2-20.7-24.1-31.9-37
        c6.2,0,11.2-0.2,16.1,0.1c1.5,0.1,3.2,1.5,4.2,2.8c5.7,7.5,11.3,15.2,16.9,22.8c1.2,1.6,2.4,3.1,3.9,5c7.2-8.8,14.3-17.2,20.9-25.9
        c2.8-3.7,3.5-5,6-5c3.6,0,10.7,0.1,15.3,0.1c-11.5,12.9-22.2,24.9-33.1,37.1C124.9,140.3,135.1,151.6,146.3,163.9z M354.4,39.3
        c-37.5,0-75.1,0-112.6,0c-6,0-6.8,0.8-6.8,6.9c-0.1,52.4-0.1,104.8-0.2,157.3c0,5.9,1.1,6.9,7,6.9c37.5,0,75.1,0,112.6,0
        c6.2,0,6.7-0.5,6.7-6.9c0-26,0-52.1,0-78.1c0,0,0.1,0,0.1,0c0-26.4,0-52.8,0-79.1C361.2,40,360.5,39.3,354.4,39.3z M336,186.6
        c-8.6,9.4-21.4,14.1-38.3,14.1c-20.8,0-35.3-6.8-43.4-20.4c-4.3-7.2-6.6-16.7-7.1-28.4h28.4c0,5.9,0.9,10.7,2.8,14.6
        c3.5,7.1,9.9,10.6,19.2,10.6c5.7,0,10.6-1.9,14.8-5.8c4.2-3.9,6.3-9.5,6.3-16.8c0-9.7-3.9-16.1-11.8-19.4
        c-4.5-1.8-11.5-2.7-21.1-2.7v-20.7c9.4-0.1,15.9-1,19.7-2.7c6.4-2.8,9.6-8.6,9.6-17.2c0-5.6-1.6-10.2-4.9-13.7
        c-3.3-3.5-7.9-5.3-13.8-5.3c-6.8,0-11.8,2.2-15,6.5c-3.2,4.3-4.8,10.1-4.7,17.3h-27c0.3-7.3,1.5-14.2,3.8-20.8
        c2.4-5.7,6.1-11,11.1-15.9c3.8-3.4,8.3-6.1,13.5-7.9c5.2-1.8,11.6-2.7,19.2-2.7c14.1,0,25.4,3.6,34,10.9s12.9,17,12.9,29.2
        c0,8.6-2.6,15.9-7.7,21.9c-3.2,3.7-6.6,6.3-10.1,7.6c2.6,0,6.4,2.3,11.4,6.8c7.4,6.8,11,16.2,11,28C349,166.3,344.7,177.2,336,186.6
        z"
          stroke="none"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ThreeCardsIcon;
