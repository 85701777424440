import { Dispatch } from 'redux';
import { Bucket, DeactivatedReason, EditBucketInfos } from 'components/buckets/types';
import {
  getBuckets as APIgetBuckets,
  createBucket as APIcreateBucket,
  updateBucket as APIupdateBucket,
  patchBucket as APIpatchBucket,
  deleteBucket as APIdeleteBucket,
  addLot as APIaddLot,
  activateBucket as APIActivateBucket,
  deactivateBucket as APIDeactivateBucket,
  emptyLots as APIemptyLots,
  loadBucket as APILoadBucket,
  unloadBucket as APIUnloadBucket,
  editQuantity as APIeditQuantity,
  inRefillList as APIinRefillList,
  importBucketList as APIimportBucketList,
  editBucket as APIeditBucket,
  unloadMultipleBucket as APIUnloadMultipleBucket,
  loadMultipleBucket as APILoadMultipleBucket,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  createBucketRequest,
  createBucketResponse,
  getBucketsRequest,
  getBucketsResponse,
  updateBucketRequest,
  updateBucketResponse,
  patchBucketRequest,
  patchBucketResponse,
  deleteBucketRequest,
  deleteBucketResponse,
  activateBucketRequest,
  activateBucketResponse,
  deactivateBucketRequest,
  deactivateBucketResponse,
  emptyLotsRequest,
  emptyLotsResponse,
  loadBucketRequest,
  loadBucketResponse,
  unloadBucketRequest,
  unloadBucketResponse,
  addLotRequest,
  addLotResponse,
  editQuantityResponse,
  editQuantityRequest,
  editBucketResponse,
  editBucketRequest,
  inRefillListResponse,
  inRefillListRequest,
  importBucketListResponse,
  importBucketListRequest,
  unloadMultipleBucketsRequest,
  unloadMultipleBucketsResponse,
  loadMultipleBucketsRequest,
  loadMultipleBucketsResponse,
} from './actions';
import { BucketAction } from './reducers';

type BucketDispatch = Dispatch<BucketAction>;

export function fetchBuckets() {
  return fetchOnceOperation(
    getBucketsRequest,
    getBucketsResponse,
    APIgetBuckets,
    (state) => state.buckets,
    [],
  );
}

export function createBucket(bucket: Bucket) {
  return (dispatch: BucketDispatch) => {
    dispatch(createBucketRequest(bucket));
    return APIcreateBucket(bucket).then((res) => dispatch(createBucketResponse({ ...res })));
  };
}

export function updateBucket(id: string, bucket: Bucket) {
  return (dispatch: BucketDispatch) => {
    dispatch(updateBucketRequest(id, bucket));
    return APIupdateBucket(id, bucket).then((res) => dispatch(updateBucketResponse({ ...res })));
  };
}

export function patchBucket(id: string, bucket: Partial<Bucket>) {
  return (dispatch: BucketDispatch) => {
    dispatch(patchBucketRequest(id, bucket));
    return APIpatchBucket(id, bucket).then((res) => dispatch(patchBucketResponse({ ...res })));
  };
}

export function deleteBucket(id: string) {
  return (dispatch: BucketDispatch) => {
    dispatch(deleteBucketRequest(id));
    return APIdeleteBucket(id).then((res) => dispatch(deleteBucketResponse({ ...res })));
  };
}

export function addLot(bucketId: string, lots: any[]) {
  return (dispatch: BucketDispatch) => {
    dispatch(addLotRequest(bucketId, lots));
    return APIaddLot(bucketId, lots).then((res) => dispatch(addLotResponse(res.bucket, lots)));
  };
}

export function emptyLots(bucketId: string) {
  return (dispatch: BucketDispatch) => {
    dispatch(emptyLotsRequest(bucketId));
    return APIemptyLots(bucketId).then((res) => dispatch(emptyLotsResponse(res.bucket)));
  };
}

export function editQuantity(bucketId: string, newQuantity: number, justification: string) {
  return (dispatch: BucketDispatch) => {
    dispatch(editQuantityRequest(bucketId, newQuantity, justification));
    return APIeditQuantity(bucketId, newQuantity, justification).then((res) =>
      dispatch(editQuantityResponse(res.bucket)),
    );
  };
}

export function editBucket(bucketId: string, bucketInfos: EditBucketInfos) {
  return (dispatch: BucketDispatch) => {
    dispatch(editBucketRequest(bucketId, bucketInfos));
    return APIeditBucket(bucketId, bucketInfos).then((res) =>
      dispatch(editBucketResponse(res.bucket)),
    );
  };
}

export function inRefillList(bucketId: string, isIn: boolean) {
  return (dispatch: BucketDispatch) => {
    dispatch(inRefillListRequest(bucketId, isIn));
    return APIinRefillList(bucketId, isIn).then((res) =>
      dispatch(inRefillListResponse(res.bucket)),
    );
  };
}

export function activateBucket(bucketId: string) {
  return (dispatch: BucketDispatch) => {
    dispatch(activateBucketRequest(bucketId));
    return APIActivateBucket(bucketId).then((res) => dispatch(activateBucketResponse(res.bucket)));
  };
}

export function deactivateBucket(bucketId: string, reason: DeactivatedReason) {
  return (dispatch: BucketDispatch) => {
    dispatch(deactivateBucketRequest(bucketId, reason));
    return APIDeactivateBucket(bucketId, reason).then((res) =>
      dispatch(deactivateBucketResponse(res.bucket)),
    );
  };
}

export function loadBucket() {
  return (dispatch: BucketDispatch) => {
    dispatch(loadBucketRequest());
    return APILoadBucket().then(() => dispatch(loadBucketResponse()));
  };
}

export function loadMultipleBuckets() {
  return (dispatch: BucketDispatch) => {
    dispatch(loadMultipleBucketsRequest());
    return APILoadMultipleBucket().then(() => dispatch(loadMultipleBucketsResponse()));
  };
}

export function unloadBucket(bucketId: string, emptyLots = false) {
  return (dispatch: BucketDispatch) => {
    dispatch(unloadBucketRequest(bucketId, emptyLots));
    return APIUnloadBucket(bucketId, emptyLots).then((res) =>
      dispatch(unloadBucketResponse(res.bucket)),
    );
  };
}

export function unloadMultipleBuckets(bucketIds: string[]) {
  return (dispatch: BucketDispatch) => {
    dispatch(unloadMultipleBucketsRequest(bucketIds));
    return APIUnloadMultipleBucket(bucketIds).then((res) =>
      dispatch(unloadMultipleBucketsResponse(res.buckets)),
    );
  };
}

export function importBucketList(bucketList, organizationName: string) {
  return (dispatch: BucketDispatch) => {
    dispatch(importBucketListRequest(bucketList, organizationName));
    return APIimportBucketList(bucketList, organizationName).then((res) =>
      dispatch(importBucketListResponse(res)),
    );
  };
}

const operations = {
  fetchBuckets,
  createBucket,
  updateBucket,
  patchBucket,
  deleteBucket,
  loadBucket,
  unloadBucket,
  activateBucket,
  deactivateBucket,
  addLot,
  emptyLots,
  editQuantity,
  inRefillList,
  importBucketList,
  editBucket,
  unloadMultipleBuckets,
};

export default operations;
