import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import CustomTooltip from '../CustomTooltip';
import '../style.scss';
import { RatioIncludedExcluded } from '../types';

export interface IncludedExcluded {
  name: string;
  value: number;
}

export interface Ratio {
  included: number;
  excluded: number;
}

interface Props {
  ratio: RatioIncludedExcluded | undefined;
  loading: boolean;
}

const IncludedExcluded: React.FC<Props> = ({ ratio, loading }): JSX.Element => {
  const COLORS = ['#007bff', '#f08234'];
  const { t } = useTranslation();
  const [includedExcluded, setIncludedExcluded] = useState<IncludedExcluded[]>([
    { name: t('included'), value: 0 },
    { name: t('excluded'), value: 0 },
  ]);
  const [ratioState, setRatioState] = useState<Ratio>({ included: 0, excluded: 0 });

  useEffect(() => {
    if (ratio) {
      setIncludedExcluded([
        { name: t('included'), value: ratio.included },
        { name: t('excluded'), value: ratio.excluded },
      ]);

      setRatioState({
        included: (ratio.included / (ratio.included + ratio.excluded)) * 100,
        excluded: (ratio.excluded / (ratio.included + ratio.excluded)) * 100,
      });
    }
  }, [ratio, t]);

  return (
    <div className="componentBase included-exluded-container">
      <h5 style={{ margin: '12px' }}>{t('IncludedExcludedRatio')}</h5>
      <hr className="separator" />
      {loading ? (
        <div style={{ marginLeft: '4.5vw', marginTop: '7.5vh' }}>
          <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
        </div>
      ) : (
        <>
          <ResponsiveContainer width="100%" height="70%">
            <PieChart width={400} height={400}>
              <Pie
                data={includedExcluded}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={70}
                labelLine={false}
                fill="#8884d8"
                dataKey="value"
              >
                {includedExcluded.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#fff"
                    strokeWidth={2}
                  />
                ))}
              </Pie>
              <Tooltip content={CustomTooltip} />
              <Legend align="center" iconType="circle" />
            </PieChart>
          </ResponsiveContainer>
          <div className="included-excluded-icon">
            <span style={{ color: '#007bff' }}>{ratioState.included.toFixed(0)}%</span> /{' '}
            <span style={{ color: '#f08234' }}>{ratioState.excluded.toFixed(0)}%</span>
          </div>
        </>
      )}
    </div>
  );
};

export default IncludedExcluded;
