enum Types {
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_RESPONSE = 'CREATE_USER_RESPONSE',
  GET_USERS_REQUEST = 'GET_USERS_REQUEST',
  GET_USERS_RESPONSE = 'GET_USERS_RESPONSE',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE',
  PATCH_USER_REQUEST = 'PATCH_USER_REQUEST',
  PATCH_USER_RESPONSE = 'PATCH_USER_RESPONSE',
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_RESPONSE = 'DELETE_USER_RESPONSE',
  TOGGLE_VASSYST_REQUEST = 'TOGGLE_VASSYST_REQUEST',
  TOGGLE_VASSYST_RESPONSE = 'TOGGLE_VASSYST_RESPONSE',
  TOGGLE_TWO_FA_REQUEST = 'TOGGLE_TWO_FA_REQUEST',
  TOGGLE_TWO_FA_RESPONSE = 'TOGGLE_TWO_FA_RESPONSE',
}

export default Types;
