/* eslint-disable indent */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Button } from 'react-bootstrap';
import {
  cancelManualDistributionIntray,
  distributeManuallyInclusionsIntray,
  toggleFlaggedDrug,
} from 'services/orders/endpoints';
import { DrugOrder, Order } from '../types';
import './style.scss';
import VisualDistribution from '../ExpandedOrder/BlisterCards/VisualDistribution';
import { someSplittedCards, getUnitedPreview } from '../helpers';
import FlagModal from '../ExpandedOrder/Modals/FlagModal';

interface ExclusionInTrayTableProps {
  bucketDIN: string;
  orders: Order[];
  excludedDistributedId: string[];
  setExcludedDistributedId: (excludedDistributedId: string[]) => void;
}

const ExclusionInTrayTable = ({
  bucketDIN,
  orders,
  excludedDistributedId,
  setExcludedDistributedId,
}: ExclusionInTrayTableProps) => {
  const [seeDistribution, setSeeDistribution] = useState<
    { orderId: string; drugOrder: DrugOrder } | undefined
  >(undefined);
  const [showFlagModalId, setShowFlagModalId] = useState<string | null>(null);

  const updateOrdersDrug = async (orderId: string) => {
    if (excludedDistributedId.includes(orderId)) {
      await cancelManualDistributionIntray(orderId, bucketDIN);
      setExcludedDistributedId(excludedDistributedId.filter((id) => id !== orderId));
    } else {
      await distributeManuallyInclusionsIntray(orderId, bucketDIN);
      setExcludedDistributedId([...excludedDistributedId, orderId]);
    }
    setSeeDistribution(undefined);
  };

  return (
    <table className="new-exclusions-table">
      <thead>
        <tr>
          <th className="icon-cell" />
          <th className="lookupId-cell">#</th>
          <th className="patient-cell">{t('patient')}</th>{' '}
          <th className="tray-cell">
            <FontAwesomeIcon icon="inbox" size="1x" color="grey" />
          </th>
          <th className="button-cell" />
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => {
          const isDistributed = excludedDistributedId.includes(order.id);
          return (
            <>
              <tr>
                <td>{isDistributed && <FontAwesomeIcon icon="check" size="1x" color="green" />}</td>
                <td>#{order.lookupId}</td>
                <td>
                  <Button
                    variant="link"
                    onClick={
                      seeDistribution && seeDistribution.orderId === order.id
                        ? () => setSeeDistribution(undefined)
                        : () =>
                            setSeeDistribution({
                              orderId: order.id,
                              drugOrder: order.drugs.find((d) => d.DIN === bucketDIN)!,
                            })
                    }
                  >
                    {order.patient}
                  </Button>
                </td>
                <td>
                  {order.trayIds[0]}{' '}
                  {order.trayIds.length === 2 ? <> & {order.trayIds[1]}</> : <></>}
                </td>
                {order.drugs
                  .filter((d) => d.DIN === bucketDIN)
                  .map((drug) => (
                    <>
                      <td>
                        <Button
                          variant={isDistributed ? 'danger' : 'success'}
                          disabled={drug.isFlagged !== undefined && drug.isFlagged !== ''}
                          onClick={() => updateOrdersDrug(order.id)}
                        >
                          {isDistributed ? t('cancel') : t('complete')}
                        </Button>
                      </td>
                      <td>
                        <FontAwesomeIcon
                          style={
                            excludedDistributedId.includes(order.id) ? { visibility: 'hidden' } : {}
                          }
                          onClick={
                            drug.isFlagged
                              ? () => toggleFlaggedDrug(order.id, drug.DIN, '')
                              : () => setShowFlagModalId(order.id)
                          }
                          icon="flag"
                          size="1x"
                          color={drug.isFlagged ? 'red' : 'grey'}
                        />

                        <FlagModal
                          show={showFlagModalId === order.id}
                          orderId={order.id}
                          drugDIN={drug.DIN}
                          close={() => setShowFlagModalId(null)}
                        />
                      </td>
                    </>
                  ))}
              </tr>
              {seeDistribution && seeDistribution.orderId === order.id && (
                <tr>
                  <td colSpan={5}>
                    <VisualDistribution
                      blisterCards={
                        someSplittedCards(order.barcodes, seeDistribution.drugOrder.distributions)
                          ? getUnitedPreview(seeDistribution.drugOrder.distributions)
                          : seeDistribution.drugOrder.distributions
                      }
                      barcodes={order.barcodes}
                      DIN={seeDistribution.drugOrder.DIN}
                    />
                  </td>
                </tr>
              )}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExclusionInTrayTable;
