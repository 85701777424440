import React, { useEffect, useRef } from 'react';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Modal, Form } from 'react-bootstrap';
import { t } from 'i18next';
import { LoadingButton } from 'components/shared/LoadingButton';
import { updatePassword } from 'services/users/endpoints';
import { passwordResetValidationSchema } from './validation';
import { User } from './types';
import './styles.scss';

interface FormValues {
  password: string;
  confirmPassword: string;
}

interface PasswordResetModalProps {
  show: boolean;
  onHide: () => void;
  user: User | null;
}

const PasswordResetModal = ({ show, onHide, user }: PasswordResetModalProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initialValues: FormValues = {
    password: '',
    confirmPassword: '',
  };

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    try {
      setSubmitting(true);
      await updatePassword(user!.id, values.confirmPassword);
      onHide();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED UPDATING PASSWORD', setFieldError);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal dialogClassName="password-reset-modal" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('resetPasswordForUser', { userName: user?.userName })}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={passwordResetValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="password">
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control
                  ref={inputRef}
                  defaultValue={initialValues.password}
                  className={errors.password && touched.password ? 'error-text' : ''}
                  type="password"
                  name="password"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('password', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="password"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>{t('confirmPassword')}</Form.Label>
                <Form.Control
                  defaultValue={initialValues.confirmPassword}
                  className={errors.confirmPassword && touched.confirmPassword ? 'error-text' : ''}
                  type="password"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('confirmPassword', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="confirmPassword"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('submit')}
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default PasswordResetModal;
