import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  fas,
  faArchive,
  faCalendar,
  faCheckSquare,
  faChevronRight,
  faCircle,
  faCoffee,
  faMap,
  faMedkit,
  faTasks,
  faTimes,
  faPen,
  faFlag,
  faExchangeAlt,
  faHandHoldingMedical,
  faFile,
  faEye,
  faEyeSlash,
  faEraser,
  faPlus,
  faLock,
  faSearchMinus,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  fas,
  faArchive,
  faCalendar,
  faCheckSquare,
  faChevronRight,
  faCircle,
  faCoffee,
  faMap,
  faMedkit,
  faTasks,
  faTimes,
  faPen,
  faFlag,
  faExchangeAlt,
  faHandHoldingMedical,
  faFile,
  faEye,
  faEyeSlash,
  faEraser,
  faPlus,
  faLock,
  faSearchMinus,
);
