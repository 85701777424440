import { Settings } from 'components/settings/types';
import { SettingsAction } from './reducers';
import types from './types';

export const getSettingsRequest = (): SettingsAction => ({
  type: types.GET_SETTINGS_REQUEST,
});

export const getSettingsResponse = (payload: Settings): SettingsAction => ({
  type: types.GET_SETTINGS_RESPONSE,
  payload,
});

export const updateSettingsRequest = (settings: Settings): SettingsAction => ({
  type: types.UPDATE_SETTINGS_REQUEST,
  payload: settings,
});

export const updateSettingsResponse = (settings: Settings): SettingsAction => ({
  type: types.UPDATE_SETTINGS_RESPONSE,
  payload: settings,
});

export const patchSettingsRequest = (settings: Partial<Settings>): SettingsAction => ({
  type: types.PATCH_SETTINGS_REQUEST,
  payload: settings,
});

export const patchSettingsResponse = (settings: Settings): SettingsAction => ({
  type: types.PATCH_SETTINGS_RESPONSE,
  payload: settings,
});
