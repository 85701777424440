const fr = {
  actions: 'Actions',
  activate: 'Activer',
  active: 'Actives',
  ACTIVE: 'ACTIF',
  actual: 'actuelle',
  actualQuantity: 'Quantité actuelle :',
  additionalCause: 'Raison (supplémentaire)',
  addAdmin: 'Ajouter un administrateur',
  addDrug: 'Ajouter un médicament',
  addLot: 'Ajouter un lot',
  addMachine: 'Ajouter une machine',
  addOrganization: 'Ajouter une organisation',
  addToScheduled: 'Ajouter à la liste à remplir',
  addTray: 'Ajouter un plateau',
  addTray_4cards:
    'Ajouter un plateau (deux (2) plateaux équivalent à quatre (4) pilluliers identiques).',
  addUser: 'Ajouter un utilisateur',
  administrators: 'Administrateurs',
  all: 'Tous',
  allOrg: 'Toutes les organisations',
  alreadyLinked: 'Ce plateau est déjà associé!',
  alreadyOngoing:
    'Vous souhaitez envoyer la commande #{{sendingOrderID}} de {{sendingOrderPatient}}, mais la commande #{{ongoingOrderID}} de {{ongoingOrderPatient}} semble toujours être en cours. Quelle est la conclusion de la commande #{{ongoingOrderID}} ?',
  altAvailable: 'Alternative disponible',
  altDrugInfos: '{{altName}} (DIN {{altDIN}})',
  alternatives: 'Alternatives',
  currentDrugInfos: ' est une alternative pour {{currentName}} (DIN {{currentDIN}}).',
  altModalOrderInfo: 'Voulez-vous modifier la commande de {{patient}} du {{createdAt}} ?',
  appInMaintenance: 'Votre application est en cours de maintenance.',
  archive: 'Archiver',
  archiveDelay: 'Délai d’archivage',
  archiveDelayTwoWeeks: '2 semaines',
  archiveDelayOneMonth: '1 mois',
  archiveDelayThreeMonths: '3 mois',
  archiveDelaySixMonths: '6 mois',
  archiveDelayOneYear: '1 an',
  archived: 'Archivées',
  authorizationRequired: 'Autorisation requise',
  BACKORDER: 'RUPTURE DE STOCK',
  average: 'Moyenne',
  BACK_ORDER: 'Rupture de stock',
  backOrdersAndOrdered: 'En rupture de stock ou commandés',
  barcodes: 'Code à barres commande multicartes',
  barcodeRequired: 'Code à barres requis',
  barcodeMustBe8Char: 'Le code à barre doit faire 8 caractères.',
  barcodeYieldNoOrder: 'Le code à barre {{barcode}} ne correspond à aucune commande.',
  bestSellers: 'Meilleurs vendeurs',
  bucket: 'Cassette',
  buckets: 'Cassettes',
  bucketDetails: 'Cassette #{{position}} : {{DIN}} - {{drugName}} ({{drugForce}})',
  bucketNotFound: "La cassette n'a pas été trouvée",
  BucketPositionDoesNotMatch:
    'Le numéro de la cassette ne correspond pas à celui de la cassette sélectionnée.',
  cancel: 'Annuler',
  cancelDistributionOf: 'Annuler la distribution de {{drug}} {{force}}',
  cards: 'Cartes',
  cause: 'Raison',
  causeText: 'Raison (texte)',
  unlinkFromTray: 'Désassocier le plateau',
  unlinkFromTrayAndContinue: 'Désassocier et poursuivre',
  chooseJSONfile: 'Veuillez choisir un fichier JSON.',
  chooseOrganization: 'Veuillez choisir une organisation.',
  clickHereToRefresh: 'Cliquez ici pour rafraichir la page',
  close: 'Fermer',
  compatible2FAApps: 'Applications compatibles avec le 2FA :',
  complete: 'Compléter',
  completed_One: 'Complétée',
  completed_Other: 'Complétées',
  completeDistributionOf: 'Compléter la distribution de {{drug}} {{force}}',
  completedOn: 'Date de complétion',
  completeAsXCards: 'Compléter à {{nbCards}} cartes',
  confirm: 'Confirmer',
  confirmImport: 'Confirmer l’importation',
  confirmImportStatement: 'Voulez-vous vraiment importer pour :',
  confirmation: 'Confirmation',
  confirmationRequired: 'Validation requise',
  confirmPassword: 'Confirmer le mot de passe',
  confirmPasswordRequired: 'La confirmation du mot de passe est requise.',
  confirmSend: 'Valider et envoyer',
  conflictualDistribution:
    'Distribution conflictuelle détectée, veuillez vérifier la fiche du patient.',
  confirmUnassignTray: 'Confirmer la désassociation du plateau',
  contactChronomedQuestion:
    'Veuillez contacter votre représentant Chronomed pour de plus amples renseignements.',
  continuePrintOldOrder:
    'Souhaitez-vous quand même envoyer la commande #{{lookupId}} émise le {{createdAt}}?',
  continueWith: 'Voulez-vous poursuivre avec la commande #{{lookupId}}?',
  cubiBarcodeAlreadyInActiveOrder:
    "Veuillez archiver la commande {{lookupId}}, afin d'importer le code-barres Cubi {{barcode}}",
  cubiOrderNotFound: 'Commande(s) cubi introuvable(s)',
  date: 'Date',
  dashboard: 'Tableau de bord',
  dailyUnload: 'Remplissage cassette',
  deactivate: 'Désactiver',
  deactivateBucket: 'Désactiver la cassette #{{position}} {{drugName}} (DIN {{DIN}})',
  definitiveOperation: 'Cette opération est permanente!',
  delete: 'Supprimer',
  deleteDrugConfirmation: 'Êtes-vous sûr de vouloir supprimer le médicament?',
  deleteMachineConfirmation: 'Êtes-vous sûr de vouloir supprimer la machine?',
  deleteMachineOrderAlert: '{{count}} commande(s) {{status}}',
  deleteOrganizationConfirmation: "Êtes-vous sûr de vouloir supprimer l'organisation?",
  deleteOrganizationMachinesWarning: 'La ou les machine(s) suivante(s) seront supprimée(s).',
  deleteUserConfirmation: ' Êtes-vous sûr de vouloir continuer ?',
  deleteUserMessage: 'Cet utilisateur sera définitivement supprimé.',
  deprecatedDINs: 'DINs Obsolètes',
  deprecatedDINWarning: 'Ce DIN est obsolète',
  deselectAll: 'Tout désélectionner',
  detectedChanges: 'Changements détectés',
  DIN: 'DIN',
  dinAlreadyExist: 'Le DIN existe déjà.',
  DINDoesNotMatch: 'Le DIN ne correspond pas à la cassette sélectionnée.',
  DINMustBe8charsLong: 'Le DIN doit avoir 8 caractères.',
  DINRequired: 'DIN est requis.',
  doNotEdit: 'Ne pas modifier',
  drug: 'Médicament',
  drugsExpired: 'Médicament(s) expiré(s)',
  drugExpireSoon: 'Les lots de {{nb}} cassette(s) seront bientôt expirés (1 mois).',
  drugSkipped: 'Médicament sauté',
  drugs: 'Médicaments',
  EANWarning: 'UPC (EAN) à 13 chiffres.',
  EXCHANGE: 'Changement',
  EXCHANGECAP: 'CHANGEMENT',
  edit: 'Modifier',
  editBucket: 'Modifier la cassette #{{position}} : {{drugName}} - {{force}}',
  editDrug: 'Modifier un médicament',
  editOrder: 'Modifier la commande',
  editOrganization: "Modifier l'organisation {{organizationName}}",
  editQuantity: 'Modifier la quantité de #{{position}} : {{DIN}} - {{drugName}}',
  editTray: 'Modifier le(s) plateau(x)',
  emailAddress: 'Adresse courriel',
  emailAddressAlreadyTaken: 'Ce courriel est déjà pris.',
  emailAddressRequired: "L'adresse courriel est requise.",
  emptyAndUnload: 'Vider & Décharger',
  emptyBucketBeforeRefill:
    'Assurez-vous de bien vider la cassette avant de procéder au remplissage.',
  encounterError: 'Une erreur est survenue.',
  enable2FA: 'Activer le 2FA',
  enterAtLeastALot: ' Vous devez entrer au moins un lot.',
  enterExpirationDate: "Entrer la date d'expiration",
  enterLotNumber: 'Entrer le numéro de lot',
  enterReason: 'Veuillez entrer la justification :',
  enterQuantity: 'Entrer la quantité',
  enter2FACode: 'Entrer le code 2FA',
  error: 'Erreur',
  error401: 'Erreur HTTP 401',
  error404: 'Erreur HTTP 404',
  error500: 'Erreur HTTP 500',
  errorAtUploadCubiOrders: "Une erreur est survenue lors de l'importantion des commandes Cubi.",
  errorImportDin: "Aucun DIN n'a été fourni lors de l'importation de la commande.",
  errorFetchingData: 'Erreur lors de la récupération des données.',
  excluded: 'Exclus',
  excludedNotDistributed: "Des exclus n'ont pas été distribués.",
  expirationDate: "Date d'expiration",
  expireSoon: 'Expire prochainement',
  EXPIRED: 'EXPIRÉ',
  EXPIRED_DRUGS: 'Médicament expiré',
  EXPIRINGSOON: 'EXPIRE BIENTÔT',
  expiredDrugsAlert: 'Les lots de {{nb}} cassette(s) ont expirés.',
  expiredDrugs: 'Médicaments expirés',
  expiredOn: 'Expiré le {{expDate}}.',
  extend: 'Prolonger',
  failed: 'Échouée',
  failedOn: 'Échouée le',
  fieldOnlyAcceptNumbers: 'Ce champ accepte seulement des nombres.',
  fieldOnlyAcceptAlphanumeric: 'Ce champ accepte seulement des caractères alphanumériques.',
  fileRequired: 'Fichier requis.',
  finish: 'Terminer',
  fillingRequired: 'Remplissage requis',
  QtAvailable: 'Quantité disponible',
  firstName: 'Prénom',
  firstNameRequired: 'Le prénom est requis.',
  flag: 'Signalement',
  fri: 'VEN',
  from: 'de',
  errorOccurred: 'Une erreur est survenue',
  format: 'Forme',
  general: 'Général',
  generic: 'Générique',
  genericAvailable: 'Un générique est disponible',
  generateOrders: 'Générer les commandes',
  halfTrayNumber: 'Numéro demi plateau',
  hello: 'Bonjour',
  hideArchivedOrders: 'Cacher les commandes archivées',
  homePage: "Page d'accueil",
  importBestSellers: 'Importer les meilleurs vendeurs',
  importDrugList: 'Importer une liste de médicaments',
  include: 'Inclure',
  included: 'Inclus',
  IncludedExcludedRatio: 'Ratio Inclus/Exclus',
  incorrectDINFormat: 'Format du DIN incorrect.',
  incorrectDINLength: 'Longueur du DIN incorrecte.',
  incorrectUPCFormat: 'Format du UPC incorrect.',
  incorrectUPCLength: 'Longueur du UPC incorrecte.',
  invalidFormat: 'Format invalide.',
  invalidFormatName:
    "Format invalide d'au moins un champ nom. \n (Sont acceptés les caractères alphanumériques, les accents, les points, les paranthèses ainsi que les symboles + et -).",
  invalidFormatForce:
    "Format invalide d'au moins un champ force. \n (Sont acceptés les caractères alphanumériques, les points, les barres obliques ainsi que le symboles +).",
  invalidForceField: 'Ce champ accepte seulement les chiffres, points et virgules.',
  invalidJSONFile: 'Fichier JSON invalide.',
  invalidEmail: 'Adresse courriel invalide.',
  invalidLabelFormat: "Format d'étiquette invalide.",
  invalidToken: 'Code 2FA invalide.',
  invalidSearchQuery: 'Recherche invalide.',
  invalidSymbolInText: 'Le texte contient des symboles invalides.',
  invalidPasswordFormat:
    'Le mot de passe doit contenir au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial.',
  invalidUsername: "Nom d'utilisateur invalide.",
  internalServerError: 'Erreur du serveur interne',
  jsonContentError: 'Il semble y avoir un problème avec le contenu du fichier JSON.',
  justification: 'Justification',
  label: 'Étiquette',
  labelDoesNotMatch: "L'étiquette ne correspond pas à la cassette sélectionnée.",
  language: 'Langue',
  lastName: 'Nom',
  last7Days: '7 derniers jours',
  lastNameRequired: 'Le nom est requis.',
  lastUpdatedOn: 'Dernière mise à jour le',
  link: 'Associer',
  linkedOrderNoTray:
    '* La commande #{{order}} de {{patient}} ne sera plus associée à aucun plateau.',
  linkedOrderOneTray: "* La commande #{{order}} de {{patient}} ne comptera qu'un plateau.",
  linkingWithOrder:
    ' Vous souhaitez associer la commande #{{lookupId}} de {{patient}}, émise le {{createdAt}}.',
  linkOrderToTray: 'Associer la commande à un plateau',
  linkToTray: 'Associer à un plateau',
  linkOrdersNeedManualDistribution:
    'Attention! {{nbOrders}} commandes associées nécessiteront une distribution manuelle.',
  listBucketToUnload: 'Liste des cassettes à décharger (DIN):',
  load: 'Charger',
  loadMultipleMessage:
    'Vous êtes sur le point de charger plusieurs cassettes, pour continuer appuyez sur "Envoyer".',
  login: 'Connexion',
  logout: 'Déconnexion',
  lotExpiration: 'Un lot de cette cassette va expirer le {{expDate}}.',
  lotNumber: 'Numéro de lot',
  LOWQUANTITY: 'QUANTITÉ FAIBLE',
  manage: 'Gérer',
  manageExpired: 'Gérer les lots expirés',
  MAINTENANCE: 'Maintenance',
  MAINTENANCECAP: 'MAINTENANCE',
  machineAlreadyExist: 'La machine existe déjà.',
  machineDistribution: 'Distribution Machine',
  machineNameRequired: 'Le nom de la machine est requis.',
  machines: 'Machines',
  maximumPasswordLength: 'Le mot de passe doit contenir au maximum 64 caractères.',
  meanBucket: 'Moy. de molécules/pilulier',
  meanBucketByMonth: 'Moy. de cassette/mois',
  meanDurationUse: "Durée moyenne d'utilisation",
  meanDrug: 'Moyenne de mx/commande',
  meanDrugAndTotalAbr: 'Total/Moyenne',
  meanDrugByPatient: 'Moyenne de médicaments par patient',
  meanSpeed: 'Vitesse moyenne/pilulier',
  meanUsage: 'Durée d’utilisation par jour',
  minimumPasswordLength: 'Le mot de passe doit contenir au moins 8 caractères.',
  mon: 'LUN',
  monthlyQty: 'Quantité mensuelle',
  mustReconnect: 'Veuillez vous reconnecter.',
  multipleLoading: 'Chargement multiple',
  multipleUnloading: 'Déchargement multiple',
  multipleUnloadBucket: 'Décharger plusieurs cassettes',
  multipleLoadBucket: 'Charger plusieurs cassettes',
  name: 'Nom',
  nameRequired: 'Nom est requis.',
  nbPatientsDaily: 'Nb patients/jour',
  need10more: '*10 unités en extra requises',
  newDIN: 'Nouveau DIN',
  newestOrderExist:
    'Une commande plus récente existe pour {{patient}} (commande #{{lookupId}} en date du {{createdAt}}).',
  newOrdersAvailable: '{{nb}} nouvelle(s) commande(s) sont disponibles.',
  noDataToDisplay: 'Nous n’avons pas de donnée à afficher.',
  noDINDrug: 'AUCUN DIN',
  notEnoughtQtyForTrays:
    "L'inventaire des cassettes suivantes est insuffisant pour effectuer l'ensemble des commandes associées et la commande #{{lookupId}} de {{patient}}.",
  notValidatedDrug: 'Médicament en attente de validation',
  notValidatedDrugCount: 'Médicaments non validés ({{notValidatedDrugCount}})',
  noOrdersToDisplay: "Il n'y a pas de commande à afficher",
  noIncludedDrugs: 'Aucun médicament dans la distribution machine.',
  numberTotalDrug: 'Nombre total de médicaments',
  of_du: 'du',
  ok: 'OK',
  oldDIN: 'Ancien DIN',
  older2weeks: 'Cette commande date de plus de deux (2) semaines.',
  orEmailAddress: 'ou adresse courriel',
  order: 'Commande',
  orders: 'Commandes',
  optionnal: ' (optionnel)',
  ORDEREDCAP: 'COMMANDÉ',
  ORDERED: 'Commandé',
  orderTrays: 'Plateau.x pour la commande #{{order}} de {{patient}} émise le {{createdAt}}.',
  organization: 'Organisation',
  organizationAlreadyExist: "L'organisation existe déjà.",
  organizationNameRequired: "Le nom de l'organisation est requis.",
  organizations: 'Organisations',
  OTHERCAP: 'AUTRE',
  OTHER: 'Autre',
  pageNotFound: 'Page introuvable',
  passedDate: 'La date doit être dans le futur',
  password: 'Mot de passe',
  passwordMustMatch: 'Les mots de passe ne correspondent pas.',
  passwordRequired: 'Le mot de passe est requis.',
  resetPasswordForUser: 'Mise à jour du mot de passe de {{userName}}',
  patient: 'Patient',
  PatientForThisMonth: 'Patients pour ce mois',
  PatientPerDay: 'Patient par jour',
  PatientPerMonth: 'Patient par mois',
  pleaseEnterDIN: 'Veuillez entrer un DIN',
  pleaseEnterExpirationDate: "Veuillez entrer une date d'expiration",
  pleaseEnterLotNumber: 'Veuillez entrer un numéro de lot',
  pleaseEnterMessage: 'Veuillez entrer un message.',
  pleaseEnterQuantity: 'Veuillez entrer une quantité.',
  pleaseEnterJustification: 'Veuillez saisir une justification.',
  pleaseEnterUPC: 'Veuillez entrer un UPC',
  pleaseReEnter2FACode: 'Veuillez ré-entrer le code 2FA',
  pleaseEnterTrayNumber: 'Veuillez entrer un numéro de plateau.',
  pleaseScanTheLabel: 'Veuillez scanner le code à barres de la cassette.',
  pleaseScanTheCardLabel: 'Veuillez scanner le code à barres de la carte.',
  prescriptionSoftware: "Logiciel d'officine",
  print: 'Imprimer',
  printDate: "Date d'impression",
  qtymonth: 'Qté/mois',
  qtyToDistribute: 'Quantité à distribuer : {{qty}}.',
  quantity: 'Quantité',
  quantityCantBeNegative: 'La quantité ne peut être négative.',
  quantityMustBeAtLeast1: "La quantité doit être d'au moins 1.",
  quantityMustBeMax5000: 'La quantité doit être moins de 5000.',
  quantityTotalMustBeMax5000: 'La quantité totale doit être moins de 5000.',
  quantityMustBeNumber: 'La quantité doit être un nombre.',
  reason: 'Raison',
  realQuantity: 'Quantité réelle',
  refill: 'Remplissage',
  refillNow: 'Remplir maintenant',
  rememberThisDevice: 'Se souvenir de cet appareil',
  removeFromList: 'Retirer de la liste à remplir',
  removeLot: 'Retirer le lot',
  removeDrugFromMachine: ' Ce médicament sera retiré de la distribution machine.',
  replaceTray: 'Remplacer le plateau #{{tray}}',
  report: 'Rapport',
  reports: 'Rapports',
  restore: 'Restaurer',
  returnToLogin: 'Retour à la page de connexion',
  role: 'Rôle',
  sat: 'SAM',
  save: 'Sauvegarder',
  scanTheBarcodeOnChronopacBucket: 'Scanner le code à barres de la cassette Chronopac.',
  scanTheBarcodeOnDrugContainer: 'Scanner le code à barres (UPC) du pot de médicaments.',
  scanQrCodeInstructions:
    'Veuillez scanner le code QR ci-dessous avec votre application d’authentification.',
  scanCubiBarcode: 'Scanner le code à barre CUBI',
  search: 'Recherche',
  seconds: 'secondes',
  selectAStat: 'Sélectionner une statistique',
  seeList: 'Voir la liste',
  selectAll: 'Tout sélectionner',
  selectedFormat: 'format(s) sélectionné(s)',
  send: 'Envoyer',
  sendBack: 'Renvoyer',
  sendToVAssystSuccessfulled: 'Message envoyé à V-Assyst avec succès !',
  sendToVAssystFailed: "Échec de l'envoi du message à V-Assyst.",
  sendVAssyst: 'Vers V-Assyst',
  setBucketQtyTo0: 'Souhaitez-vous vider la cassette et mettre la quantité en inventaire à zéro ?',
  settings: 'Paramètres',
  sessionExpired: 'Votre session a expiré.',
  sessionExpireSoon: 'Votre session se termine bientôt.\n {{time}}s.',
  skipToastMessage: 'Cassette #{{bucketPosition}} de la commande #{{lookupId}} a été sautée.',
  status: 'Statut',
  start: 'Débuter',
  startedOn: 'Démarrée le',
  strength: 'Force',
  software: 'Logiciel',
  submit: 'Soumettre',
  sun: 'DIM',
  switchOrdersInTray:
    '* Le plateau #{{tray}} sera dorénavant associé à {{patient}} (commande #{{order}} émise le {{createdAt}}).',
  switchToManualDistribution: 'Transférer à la distribution manuelle',
  switchDistributionInfo:
    ' Vous souhaitez distribuer manuellement {{drug}} {{force}} (DIN {{DIN}}) ?',
  technicians: 'Techniciens',
  thisMonth: 'Ce mois',
  toRefill: 'À remplir',
  TOREFILL: 'À REMPLIR',
  totalDrug: 'Total de molécules incluses',
  tray: 'Plateau',
  trayAlreadyAssigned:
    'Ce plateau est déjà associé à la commande #{{orderAssigned}} de {{patientAssigned}} émise le {{createdAt}}.',
  trayIdRequired: 'Le numéro de plateau est requis.',
  trayNumber: 'Numéro de plateau',
  tue: 'MAR',
  thu: 'JEU',
  twoFA: 'Authentification à deux facteurs',
  twoFASetup: 'Configuration de l’authentification à deux facteurs',
  twoFAEnabled:
    'L’authentification à deux facteurs est activée, vous allez devoir scanner le code-QR à la connexion.',
  twoFADisabled: 'L’authentification à deux facteurs est désactivée.',
  unlink: 'Désassocier',
  unassigned: 'Non assigné',
  unassignInfos:
    'Voulez-vous désassocier le plateau #{{tray}} de la commande #{{order}} de {{patient}} émise le {{createdAt}} ?',
  uniqueBarcodes: 'Il y a des codes-barres identiques! Êtes-vous certain de vouloir continuer?',
  unknownDrug: 'MÉDICAMENT INCONNU',
  unknownDIN: 'Aucun médicament connu pour ce DIN',
  unload: 'Décharger',
  unloading: 'Déchargement',
  unregisteredFormat: 'Format non-enregistré.',
  unspecified: 'Non spécifié',
  unvalidatedUPC: 'UPCs Non Validés',
  updateList: 'Mettre à jour la liste',
  updateListEraseInfos: 'La mise à jour de la liste effacera les informations en cours de saisie.',
  UPCArrayMissing: 'Liste UPC manquante.',
  UPCDoesNotMatch: 'Le UPC ne correspond pas au médicament sélectionné.',
  UPCMustBeAtLeast8charsLong: "L'UPC doit avoir au moins 8 caractères.",
  UPCMustBeAtLeast12charsLong: "L'UPC doit avoir au moins 12 caractères.",
  UPCMustBeAtMost13charsLong: "L'UPC doit avoir au plus 13 caractères.",
  UPCRequired: "L'UPC est requis.",
  updateOrders: 'Mettre à jour les commandes',
  updateUnprocess: 'Archiver les commandes non-traitées',
  updateCubiOrder: 'Actualiser depuis CUBI',
  userName: "Nom d'utilisateur",
  user: 'Utilisateur',
  userCubiNotAuthorized: 'Utilisateur CUBI non autorisé.',
  userNotFound: 'Utilisateur non trouvé.',
  userNameAlphanumericValidation:
    "Le nom d'utilisateur ne doit contenir que des caractères alphanumériques.",
  userNameAlreadyTaken: "Ce nom d'utilisateur est déjà pris.",
  userNameRequired: "Le nom d'utilisateur est requis.",
  users: 'Utilisateurs',
  validateDrug: 'Valider le médicament',
  validateNewUpc: 'Valider les nouveaux UPCS ',
  vAssyst: 'V-Assyst',
  value: 'Valeur',
  verify: 'Vérifier',
  wantToEditOrderOf: 'Voulez-vous modifier la commande de',
  warning: 'Attention !',
  warning2weeks: 'Attention, cette commande date de plus de 2 semaines.',
  warningDiscsCompatibility: 'Attention, veuillez vous assurer que les disques sont compatibles!',
  warningTrayWithExclusions:
    'Attention, ces commandes en plateaux nécessitent une nouvelle distribution manuelle de {{drugName}} {{drugForce}} (DIN {{drugDIN}}).',
  whatDoYouWantToDo: 'Que voulez-vous faire ?',
  wholeQtyOnly: 'La quantité doit être un nombre entier.',
  willLinkTrayWithOrder: 'Le plateau sera associé à la commande #{{lookupId}}.',
  writeDrugChanged: 'Indiquer le changement de génériques sur la fiche.',
  wrongUsernameOrPassword: "Nom d'utilisateur ou mot de passe incorrect. Veuillez réessayer.",
  weeks: 'Semaines',
  wen: 'MER',
  yes: 'Oui',
};

export default fr;
