enum Types {
  CREATE_MACHINE_REQUEST = 'CREATE_MACHINE_REQUEST',
  CREATE_MACHINE_RESPONSE = 'CREATE_MACHINE_RESPONSE',
  GET_MACHINES_REQUEST = 'GET_MACHINES_REQUEST',
  GET_MACHINES_RESPONSE = 'GET_MACHINES_RESPONSE',
  UPDATE_MACHINE_REQUEST = 'UPDATE_MACHINE_REQUEST',
  UPDATE_MACHINE_RESPONSE = 'UPDATE_MACHINE_RESPONSE',
  DELETE_MACHINE_REQUEST = 'DELETE_MACHINE_REQUEST',
  DELETE_MACHINE_RESPONSE = 'DELETE_MACHINE_RESPONSE',
}

export default Types;
