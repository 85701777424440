import { Dispatch } from 'redux';
import {
  OrganizationCreateFields,
  Organization,
  OrganizationStatus,
} from 'components/organizations/types';
import {
  getOrganizations as APIgetOrganizations,
  createOrganization as APIcreateOrganization,
  updateOrganization as APIupdateOrganization,
  updateAllOrganizationsStatus as APIupdateAllOrganizationsStatus,
  patchOrganization as APIpatchOrganization,
  deleteOrganization as APIdeleteOrganization,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  createOrganizationRequest,
  createOrganizationResponse,
  getOrganizationsRequest,
  getOrganizationsResponse,
  updateOrganizationRequest,
  updateOrganizationResponse,
  patchOrganizationRequest,
  patchOrganizationResponse,
  deleteOrganizationRequest,
  deleteOrganizationResponse,
  updateAllOrganizationsStatusRequest,
  updateAllOrganizationsStatusResponse,
} from './actions';
import { OrganizationAction } from './reducers';

type OrganizationDispatch = Dispatch<OrganizationAction>;

export function fetchOrganizations() {
  return fetchOnceOperation(
    getOrganizationsRequest,
    getOrganizationsResponse,
    APIgetOrganizations,
    (state) => state.organizations,
    [],
  );
}

export function createOrganization(organization: OrganizationCreateFields) {
  return (dispatch: OrganizationDispatch) => {
    dispatch(createOrganizationRequest(organization));
    return APIcreateOrganization(organization).then((res) =>
      dispatch(createOrganizationResponse(res.organization)),
    );
  };
}

export function updateOrganization(id: string, organization: Organization) {
  return (dispatch: OrganizationDispatch) => {
    dispatch(updateOrganizationRequest(id, organization));
    return APIupdateOrganization(id, organization).then((res) =>
      dispatch(updateOrganizationResponse(res.organization)),
    );
  };
}

export function updateAllOrganizationsStatus(status: OrganizationStatus) {
  return (dispatch: OrganizationDispatch) => {
    dispatch(updateAllOrganizationsStatusRequest(status));
    return APIupdateAllOrganizationsStatus(status).then((res) =>
      dispatch(updateAllOrganizationsStatusResponse(res.organizations)),
    );
  };
}

export function patchOrganization(id: string, organization: Partial<Organization>) {
  return (dispatch: OrganizationDispatch) => {
    dispatch(patchOrganizationRequest(id, organization));
    return APIpatchOrganization(id, organization).then((res) =>
      dispatch(patchOrganizationResponse({ ...res })),
    );
  };
}

export function deleteOrganization(id: string) {
  return (dispatch: OrganizationDispatch) => {
    dispatch(deleteOrganizationRequest(id));
    return APIdeleteOrganization(id).then((res) =>
      dispatch(deleteOrganizationResponse(res.organization)),
    );
  };
}

const operations = {
  fetchOrganizations,
  createOrganization,
  updateOrganization,
  patchOrganization,
  deleteOrganization,
};

export default operations;
