export type MachineId = string;

export type nbCards = 1 | 2 | 3 | 4;

export enum DrugOrderExcludedReason {
  BUCKET_DEACTIVATED = 'BUCKET_DEACTIVATED',
  BUCKET_MISSING = 'BUCKET_MISSING',
  QUANTITY_INVALID = 'QUANTITY_INVALID',
  MANUAL_EXCLUSION = 'MANUAL_EXCLUSION',
  NO_QUANTITY = 'NO_QUANTITY',
  UNEQUAL_DISTRIBUTION = 'UNEQUAL_DISTRIBUTION',
  DIN_UNAVAILABLE = 'DIN_UNAVAILABLE',
  DUPLICATE_DIN = 'DUPLICATE_DIN',
}

export enum OrderStatus {
  ARCHIVED = 'ARCHIVED',
  PENDING = 'PENDING',
  INTRAY = 'INTRAY',
  ONGOING = 'ONGOING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  UNPROCESS = 'UNPROCESS',
  UNKNOWN = 'UNKNOWN',
}

export enum DrugOrderStatus {
  UNDISTRIBUTED = 'UNDISTRIBUTED',
  ONGOING = 'ONGOING',
  DISTRIBUTED = 'DISTRIBUTED',
  NO_STOCK = 'NO_STOCK',
  EXPIRED_IN_BUCKET = 'EXPIRED_IN_BUCKET',
  SKIPPED = 'SKIPPED',
}

export enum OrderType {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  COMPLETED = 'COMPLETED',
}

export type DescriptionMatrix = [string, string, string, string];
export type CardMap = [
  [string, string, string, string, string, string, string],
  [string, string, string, string, string, string, string],
  [string, string, string, string, string, string, string],
  [string, string, string, string, string, string, string],
];

export interface DrugDistribution {
  barcode: string;
  cardMap: CardMap;
  isExcluded?: boolean;
  excludedReason?: DrugOrderExcludedReason;
  status?: DrugOrderStatus;
}

export interface DrugOrder {
  DIN: string;
  distributions: DrugDistribution[];
  lotNumber?: string;
  isFlagged?: string;
}

export interface OrderProgression {
  startedAt: Date;
  machineId: MachineId;
  lastProgressionReceivedAt?: string;
  completedOn?: string;
  percentage: number;
  isCompleted: boolean;
  nbCardsProduced?: nbCards;
}

export type OrderFailureStatus = 'BUSY' | 'FAILURE';

export interface OrderFailure {
  failedAt: Date;
  machineId: MachineId;
  status: OrderFailureStatus;
  statusCode?: string;
  statusReason?: string;
  progression?: number;
}

export interface NewestOrder {
  id: string;
  lookupId: string;
  createdAt: string;
  byPass: boolean;
}

export interface Order {
  id: string;
  barcodes: string[];
  organizationId: string;
  lookupId: string;
  machineId: string;
  patient: string;
  foyer?: string;
  status: OrderStatus;
  createdAt: string;
  drugs: DrugOrder[];
  progression?: OrderProgression;
  failure?: OrderFailure;
  permissions?: {
    canTrigger: boolean;
    canCancel: boolean;
    canDelete: boolean;
    canRestore: boolean;
  };
  nbCards: nbCards;
  newestOrder?: NewestOrder;
  trayIds: string[];
}
