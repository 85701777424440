import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initializeTelemetry } from 'utils/telemetry';
import { initializeTranslation } from 'utils/translation';
import store from './services/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.scss';

initializeTelemetry();

initializeTranslation();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

window.addEventListener('error', onError);
window.addEventListener('unhandledrejection', onUnhandledRejection);

export function onError(evt: ErrorEvent): void {
  const errorMessage = evt.error.message;

  logError(errorMessage, evt.error.stack);
}

function onUnhandledRejection(evt: PromiseRejectionEvent) {
  let errorMessage = 'Unhandled promise rejection';
  let state: Record<string, unknown> | undefined;

  if (typeof evt.reason === 'object') {
    errorMessage = evt.reason.message || errorMessage;
    state = evt.reason;
  } else if (typeof evt.reason === 'string') {
    errorMessage = evt.reason;
  }

  logError(errorMessage, undefined, state);
}

export function logError(
  errorMessage: string,
  stacktrace?: string,
  state?: Record<string, unknown>,
): void {
  const debug = document.getElementById('debug');
  if (debug && window.location.hash.includes('debug')) {
    debug.innerText = JSON.stringify(
      {
        errorMessage,
        stacktrace,
        state,
        wat: 'alkdjf',
      },
      null,
      2,
    );
  }
}
