import React from 'react';
import { Drug } from 'components/drugs/types';
import { t } from 'i18next';
import { Button, Modal } from 'react-bootstrap';
import { manuallyExcludeDrug } from 'services/orders/endpoints';
import { DrugOrder } from '../../types';

interface ManualExclusionModalProps {
  drugOrder: DrugOrder;
  drug: Drug | undefined;
  orderId: string;
  show: boolean;
  close: () => void;
}

const ManualExclusionModal = ({
  drugOrder,
  drug,
  orderId,
  show,
  close,
}: ManualExclusionModalProps) => (
  <Modal show={show}>
    <Modal.Header>
      <Modal.Title>{t('switchToManualDistribution')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {t('switchDistributionInfo', {
        drug: drug ? drug.name.toUpperCase() : '- UNKNOWN DRUG -',
        force: drug && drug.force ? drug.force.toUpperCase() : '',
        DIN: drugOrder.DIN,
      })}
      {t('removeDrugFromMachine')}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => manuallyExcludeDrug(orderId, drugOrder.DIN)}>{t('confirm')}</Button>
      <Button variant="secondary" onClick={close}>
        {t('cancel')}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ManualExclusionModal;
