import React, { SyntheticEvent, useState, useRef, useEffect } from 'react';
import links from 'utils/links';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { login, refresh2FA } from 'services/login/operations';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { initializeSocket } from 'services/sockets';
import { LoadingButton } from 'components/shared/LoadingButton';
import logo from 'images/logo.png';
import TwoFAModal from './Modals/TwoFAModal';
import './login.scss';

const LoginPage = ({ history, location }: RouteComponentProps): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [is2FARequired, setIs2FARequired] = useState<boolean>(false);
  const [reEnter2FA, setReEnter2FA] = useState<boolean>(false);
  const [twoFAToken, setTwoFAToken] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<any | undefined>(undefined);
  const [rememberDevice, setRememberDevice] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordFieldRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setErrorMessage('');
    setReEnter2FA(false);
  }, [usernameRef, passwordFieldRef]);

  const handleRefresh2FA = async () => {
    try {
      const res: any = await dispatch(refresh2FA(userId, twoFAToken, rememberDevice));
      if (res.payload.error) {
        const jsonBody = await res.payload.error.response?.json();
        if (jsonBody.invalidToken) {
          setErrorMessage(t('invalidToken'));
          setTwoFAToken('');
        }
      } else {
        setTwoFAToken('');
        setReEnter2FA(false);
        setIs2FARequired(false);
        completeLoginProcess(res);
      }
    } catch (error) {
      setErrorMessage(t('errorOccurred'));
    }
  };

  const handleLogin = (e: SyntheticEvent) => {
    e.preventDefault();
    setErrorMessage('');

    const regex = /^[a-zA-Z0-9_.-]+$/;
    if (usernameRef?.current?.value && !regex.test(usernameRef.current.value)) {
      setErrorMessage(t('invalidUsername'));
      return;
    }
    setIsLoading(true);

    login(
      usernameRef?.current?.value,
      passwordFieldRef?.current?.value,
    )(dispatch).then(async (res: any) => {
      setIsLoading(false);

      if (twoFAToken) {
        handleRefresh2FA();
      }

      if (res.payload.twoFARequired) {
        setIs2FARequired(true);
        setUserId(res.payload.userId);
        return;
      }

      if (res.payload.reEnter2FA) {
        setReEnter2FA(true);
        setUserId(res.payload.userId);
        return;
      }

      if (res.payload.error) {
        const jsonBody = await res.payload.error.response?.json();
        const { error } = jsonBody;
        setErrorMessage(t(error) || t('errorOccurred'));
        return;
      }

      if (passwordFieldRef.current) {
        passwordFieldRef.current.value = '';
      }

      completeLoginProcess(res);
    });
  };

  const completeLoginProcess = async (res: any) => {
    await res;
    if (passwordFieldRef.current) {
      passwordFieldRef.current.value = '';
    }

    if (res.payload.infos) {
      initializeSocket();
      history.push(location.state ? location.state.from : links.paths.home);
    } else if (res.payload.error) {
      const jsonBody = await res.payload.error.response?.json();
      const { error } = jsonBody;
      setErrorMessage(t(error) || t('errorOccurred'));
    }
  };

  return (
    <div className="loginPage">
      <div className="center">
        <form id="Login_form" onSubmit={handleLogin}>
          <div className="logoChrono">
            <img src={logo} alt="chronomed.ca" className="logoChronoImage" />
          </div>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>{t('userName')}</Form.Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faUser} />
                </span>
              </div>
              <Form.Control
                type="text"
                autoFocus
                name="username"
                isInvalid={!!errorMessage}
                ref={usernameRef}
                onChange={() => {
                  setErrorMessage('');
                  setReEnter2FA(false);
                }}
              />
            </div>
            <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="password">
            <Form.Label>{t('password')}</Form.Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </div>
              <Form.Control type="password" name="password" ref={passwordFieldRef} />
            </div>
            <Form.Control.Feedback type="invalid" />
          </Form.Group>
          {reEnter2FA && (
            <>
              <Form.Group controlId="twoFAToken">
                <Form.Label>{t('enter2FACode')}</Form.Label>
                <Form.Control
                  type="text"
                  name="twoFAToken"
                  value={twoFAToken}
                  onChange={(e) => setTwoFAToken(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="rememberDevice" className="twoFAmodal-check">
                <Form.Check
                  type="checkbox"
                  label={t('rememberThisDevice')}
                  checked={rememberDevice}
                  onChange={(e) => setRememberDevice(e.target.checked)}
                />
              </Form.Group>
            </>
          )}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <LoadingButton
            loading={isLoading}
            variant="primary"
            type="submit"
            className="connexion"
            disabled={twoFAToken === '' && reEnter2FA}
          >
            {t('login')}
          </LoadingButton>
        </form>

        {is2FARequired && (
          <TwoFAModal
            show={is2FARequired}
            close={() => setIs2FARequired(false)}
            userId={userId}
            setTwoFAToken={setTwoFAToken}
            handleRefresh2FA={handleRefresh2FA}
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
