import React from 'react';
import { DateTime } from 'luxon';

interface DateCellProps {
  date: string | undefined;
}

const DateCell = ({ date }: DateCellProps) => {
  const dateFormated = DateTime.fromISO(date);

  return (
    <div className="timestamps">
      <b>{date ? dateFormated.toFormat('yyyy-MM-dd') : ''}</b>
      <br />
      <div style={{ fontSize: '0.9em', opacity: 0.9 }}>
        {date ? dateFormated.toFormat('HH:mm:ss') : ''}
      </div>
    </div>
  );
};

export default DateCell;
