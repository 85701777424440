/* eslint-disable */
import React from 'react';
import {
  DrugDistribution,
  DrugOrderExcludedReason,
  DrugOrderStatus,
} from 'components/orders/types';
import BlisterCardRow from './BlisterCardRow';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

interface SingleBlisterCardProps {
  barcode: string;
  blisterCard: DrugDistribution;
  DIN: string;
  traySide: string;
}

const SingleBlisterCard = ({ barcode, blisterCard, DIN, traySide }: SingleBlisterCardProps) => {
  const renderTooltip = (props: any) => (
    <Tooltip id={`tooltip-${DIN}-${blisterCard.barcode}`} {...props}>
      {t('drugSkipped')}
    </Tooltip>
  );

  const conflictDistributionWarning = () => (
    <div style={{ textAlign: 'center', margin: '3% auto', maxWidth: '75%' }}>
      <strong>{t('conflictualDistribution')}</strong>
    </div>
  );
  return (
    <div className={traySide}>
      <div className="blister-card-id">
        {blisterCard.status === DrugOrderStatus.SKIPPED ? (
          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            <FontAwesomeIcon icon={faForward} color="red" className="skipIcon" />
          </OverlayTrigger>
        ) : null}
        <strong>{barcode}</strong>
      </div>
      {blisterCard.excludedReason === DrugOrderExcludedReason.DUPLICATE_DIN
        ? conflictDistributionWarning()
        : Array.from({ length: 7 }, (_, i) => (
            <BlisterCardRow
              key={`week-${i}-${DIN}-${blisterCard.barcode}`}
              DIN={DIN}
              distribution={blisterCard}
              day={i}
            />
          ))}
    </div>
  );
};

export default SingleBlisterCard;
