import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { Table, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DrugUPCTableProps {
  drugId: string;
  handleValidate?: (upc: string) => void;
  handleInvalidate?: (upc: string) => void;
}

const DrugUPCTable = ({ drugId, handleValidate, handleInvalidate }: DrugUPCTableProps) => {
  const { t } = useTranslation();
  const validating = !!(handleInvalidate && handleValidate);
  const drugs = useSelector((state: RootState) => state.drugs);
  const drug = drugs.find((drug) => drug.id === drugId);

  let rows: JSX.Element[] | undefined = [];

  if (validating) {
    rows = drug?.newUPC.map((upc, index) => (
      <tr key={index}>
        <td>{upc}</td>
        <td>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button variant="outline-success" onClick={() => handleValidate(upc)}>
              <FontAwesomeIcon icon="check" />
            </Button>
            <Button variant="outline-danger" onClick={() => handleInvalidate(upc)}>
              <FontAwesomeIcon icon="ban" />
            </Button>
          </div>
        </td>
      </tr>
    ));
  } else {
    rows = drug?.UPC.map((upc) => (
      <tr key={upc}>
        <td>{upc}</td>
      </tr>
    ));
  }

  return (
    <Table responsive bordered striped style={{ marginTop: '1%' }}>
      <thead>
        <tr>
          <th>{t('UPC')}</th>
          {validating && <th style={{ width: '20%' }}>{t('actions')}</th>}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};
export default DrugUPCTable;
