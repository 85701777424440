import { NewUser, User } from 'components/users/types';
import { get, post, put, patch, del } from '../api';

export const getUsers = (filter) => get('/users', {}, { filter });

export const getUser = (id: string) => get('/users/:id', { id });

export const createUser = (user: NewUser) => post('/users', {}, user);

export const updateUser = (id: string, user: User) => put('/users/:id', { id }, user);

export const patchUser = (id: string, user: Partial<User>) => patch('/users/:id', { id }, user);

export const deleteUser = (id: string) => del('/users/:id', { id });

export const updatePassword = (id: string, password: string) =>
  patch('/users/:id/password', { id }, { password });

export const toggleVAssyst = (id: string) => patch('/users/:id/vassyst', { id });

export const toggleTwoFA = (id: string) => patch('/users/:id/2fa', { id });
