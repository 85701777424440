import { ErrorResponse, HttpResponseError } from 'services/api';

export const handleHttpResponseError = async (
  err: any,
  message: string,
  setFieldError: (field: string, message: string) => void,
) => {
  if (err instanceof HttpResponseError) {
    const body: ErrorResponse = await err.response.json();
    Object.entries(body.error.fields).forEach(([name, error]) => {
      setFieldError(name, error);
    });
  }
  console.error(message, err);
};
