import { t } from 'i18next';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { Button, Modal } from 'react-bootstrap';
import { DateTime } from 'luxon';
import { patchOrder } from 'services/orders/endpoints';
import { PrescriptionSoftware } from 'components/organizations/types';
import { LoadingButton } from 'components/shared/LoadingButton';
import { Order, OrderStatus } from '../../../types';
import AddTrayForm from './AddTrayForm';
import TrayEditionRow from './TrayEditionRow';

interface EditTraysModalProps {
  show: boolean;
  order: Order;
  handleClose: () => void;
}

const EditTraysModal = ({ show, order, handleClose }: EditTraysModalProps) => {
  const organizations = useSelector((state: RootState) => state.organizations);
  const prescriptionSoftware = organizations.find(
    (org) => org.id === order.organizationId,
  )?.prescriptionSoftware;
  const isCubi: boolean =
    !!prescriptionSoftware && prescriptionSoftware.name === PrescriptionSoftware.Cubi;

  const [addingTray, setAddingTray] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleUnlink = async () => {
    try {
      setIsLoading(true);
      await patchOrder(order.id, { trayIds: [], status: OrderStatus.PENDING });
      handleClose();
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('editTray')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('orderTrays', {
            patient: order.patient,
            createdAt: DateTime.fromISO(order!.createdAt).toFormat('yyyy-MM-dd'),
            order: order.lookupId,
          })}
        </p>
        {order.trayIds.map((t) => (
          <TrayEditionRow order={order} trayId={t} isCubi={isCubi} handleClose={handleClose} />
        ))}
        {!isCubi && !addingTray && (
          <Button
            disabled={order.nbCards === 3 || order.nbCards === 4}
            onClick={() => setAddingTray(true)}
          >
            {t('addTray')}
          </Button>
        )}
        {isCubi && (
          <LoadingButton loading={isLoading} onClick={handleUnlink}>
            {t('unlink')}
          </LoadingButton>
        )}
        {addingTray && (
          <div>
            <AddTrayForm order={order} setCompleted={() => setAddingTray(false)} />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditTraysModal;
