import React from 'react';
import { t } from 'i18next';
import { Table } from 'react-bootstrap';
import classnames from 'classnames';
import { Bucket } from '../types';

interface LotTableProps {
  bucket: Bucket;
}

const LotTable = ({ bucket }: LotTableProps) => {
  const oneMonthFromNow = new Date(new Date().setMonth(new Date().getMonth() + 1))
    .toISOString()
    .slice(0, 7);

  return (
    <Table striped>
      <thead>
        <tr>
          <th scope="col"># Lot</th>
          <th scope="col">{t('quantity')}</th>
          <th scope="col">{t('expirationDate')}</th>
        </tr>
      </thead>
      <tbody>
        {bucket.lots
          ?.filter((lot) => lot.quantity > 0)
          .map((lot) => (
            <tr
              className={classnames({
                isExpiringSoon: lot.expirationDate <= oneMonthFromNow,
              })}
            >
              <td>{lot.lotNumber}</td>
              <td>{lot.quantity}</td>
              <td>{lot.expirationDate}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default LotTable;
