import React, { useState } from 'react';
import { Order, OrderStatus } from 'components/orders/types';
import { Formik } from 'formik';
import { getTrayOrder, patchOrder, removeTray, replaceTray } from 'services/orders/endpoints';
import * as yup from 'yup';
import { Button, Form } from 'react-bootstrap';
import { t } from 'i18next';
import { handleHttpResponseError } from 'components/shared/helpers';
import AlreadyLinkedAlert from '../AlreadyLinkedAlert';

interface FormValues {
  newTrayId: string | undefined;
}

const initialValues: FormValues = {
  newTrayId: undefined,
};

interface ReplaceTrayFormProps {
  order: Order;
  initialTrayId: string;
  setCompleted: () => void;
}

interface FormSubmitProps {
  values: FormValues;
  setSubmitting: (isSubmitting: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
}

const ReplaceTrayForm = ({ order, initialTrayId, setCompleted }: ReplaceTrayFormProps) => {
  const [alreadyInTray, setAlreadyInTray] = useState<{ order: Order; trayLinking: string } | null>(
    null,
  );

  const unlinkAndReplace = async () => {
    if (alreadyInTray) {
      if (alreadyInTray.order.trayIds.length > 1) {
        await patchOrder(alreadyInTray.order.id, { nbCards: 2 });
      } else {
        await patchOrder(alreadyInTray.order.id, { status: OrderStatus.PENDING });
      }
      await removeTray(alreadyInTray.order.id, alreadyInTray.trayLinking);
      await replaceTray(order.id, initialTrayId, alreadyInTray.trayLinking);
      setAlreadyInTray(null);
      setCompleted();
    }
  };

  const formSubmit = async ({ values, setSubmitting, setFieldError }: FormSubmitProps) => {
    setSubmitting(true);
    setAlreadyInTray(null);

    const inTray = await getTrayOrder(values.newTrayId!).then((res) => res.order);

    if (!inTray) {
      try {
        await replaceTray(order.id, initialTrayId, values.newTrayId!);
        setSubmitting(false);
        setCompleted();
      } catch (err) {
        handleHttpResponseError(err, 'FAILED TO REPLACE TRAY', setFieldError);
      }
    } else {
      setAlreadyInTray({ order: inTray, trayLinking: values.newTrayId! });
    }

    setSubmitting(false);
  };
  const schema = yup.object().shape({
    newTrayId: yup
      .string()
      .matches(/^[0-9]+$/, t('fieldOnlyAcceptNumbers'))
      .required(t('pleaseEnterTrayNumber')),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        formSubmit({ values, setSubmitting, setFieldError });
      }}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <Form.Label>{t('replaceTray', { tray: initialTrayId })}</Form.Label>
            <div className="edit-form-row">
              <Form.Group controlId="newTrayId">
                <Form.Control
                  type="text"
                  name="newTrayId"
                  autoFocus
                  value={values.newTrayId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.newTrayId}
                />
                <Form.Control.Feedback type="invalid">{errors.newTrayId}</Form.Control.Feedback>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting || alreadyInTray !== null}
              >
                {t('submit')}
              </Button>{' '}
              <Button onClick={setCompleted}> {t('cancel')}</Button>
            </div>
          </Form>
          {alreadyInTray !== null && (
            <AlreadyLinkedAlert linkedOrder={alreadyInTray.order} nextAction={unlinkAndReplace} />
          )}
          <hr />
        </>
      )}
    </Formik>
  );
};

export default ReplaceTrayForm;
