import ExpiredDrugsModal from 'components/buckets/ExpiredDrugsModal/ExpiredDrugsModal';
import { t } from 'i18next';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import links from 'utils/links';

const LotExpireSoon = (): JSX.Element => {
  const buckets = useSelector((state: RootState) => state.buckets);
  const [showExpiredModal, setShowExpiredModal] = useState<boolean>(false);

  const oneMonthFromNow = new Date(new Date().setMonth(new Date().getMonth() + 1))
    .toISOString()
    .slice(0, 7);
  const thisMonth = new Date().toISOString().slice(0, 7);
  /* eslint-disable indent */

  const expireSoonDrugs: string[] = buckets
    ? buckets
        .filter(
          (b) =>
            b.nearestExpirationDate &&
            b.nearestExpirationDate <= oneMonthFromNow &&
            b.nearestExpirationDate > thisMonth &&
            !b.isDeactivated,
        )
        .map((b) => b.id)
    : [];

  const expiredDrugs: string[] = buckets
    ? buckets
        .filter(
          (b) =>
            b.nearestExpirationDate && b.nearestExpirationDate <= thisMonth && !b.isDeactivated,
        )
        .map((b) => b.id)
    : [];
  /* eslint-enable indent */

  return (
    <>
      {(expireSoonDrugs.length > 0 || expiredDrugs.length > 0) && (
        <Alert variant="danger">
          <Alert.Heading>{t('warning')}</Alert.Heading>
          {expireSoonDrugs.length > 0 && (
            <p>
              {t('drugExpireSoon', { nb: expireSoonDrugs.length })}{' '}
              <div className="d-flex justify-content-end">
                <Button href={links.paths.buckets} variant="danger" className="float-end">
                  {t('seeList')}
                </Button>
              </div>
            </p>
          )}
          {expiredDrugs.length > 0 && (
            <p>
              <hr />
              {t('expiredDrugsAlert', { nb: expiredDrugs.length })}
              <div className="d-flex justify-content-end">
                <Button
                  className="float-end"
                  style={{ marginLeft: 3 }}
                  variant="danger"
                  onClick={() => setShowExpiredModal(true)}
                >
                  {t('manageExpired')}
                </Button>
              </div>
            </p>
          )}
        </Alert>
      )}
      <ExpiredDrugsModal
        isOpen={showExpiredModal}
        bucketsId={expiredDrugs}
        onClose={() => setShowExpiredModal(false)}
      />
    </>
  );
};

export default LotExpireSoon;
