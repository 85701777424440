import React, { useEffect, useState } from 'react';
import { dashboard } from 'services/dashboard/endpoints';
import IncludedExcluded from './Statistics/IncludedExcluded';
import PatientPerDay from './Statistics/PatientPerDay';
import TotalMeanDrug from './Statistics/TotalMeanDrug';
import MeanSpeed from './Statistics/MeanSpeed';
import MeanDailyUsage from './Statistics/MeanDailyUsage';
import DailyUnload from './Statistics/DailyUnload';
import './style.scss';
import {
  MeanSpeadandDailyUsage,
  PatientPerDayStats,
  RatioIncludedExcluded,
  TotalDrugMean,
  UnloadPerDay,
} from './types';

interface DashboardProps {
  organizationId: string;
}

interface Statistics {
  unloadPerDay: UnloadPerDay[];
  ratio: RatioIncludedExcluded;
  meanDuration: MeanSpeadandDailyUsage;
  patientPerDay: PatientPerDayStats[];
  totalDrugMean: TotalDrugMean;
}

const Dashboard = ({ organizationId }: DashboardProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<Statistics | null>(null);

  useEffect(() => {
    setLoading(true);
    const fetchDashboardStatistics = async () => {
      try {
        const { stats } = await dashboard(organizationId);
        setStatistics(stats);
      } catch (error: any) {
        console.error(`Error while fetching daily unload data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardStatistics();
  }, [organizationId]);
  return (
    <>
      <div className="dashboard">
        <DailyUnload unloadPerDay={statistics?.unloadPerDay} loading={loading} />
        <IncludedExcluded ratio={statistics?.ratio} loading={loading} />
        <MeanSpeed meanDuration={statistics?.meanDuration} loading={loading} />
        <MeanDailyUsage meanDuration={statistics?.meanDuration} loading={loading} />
        <PatientPerDay patientPerDay={statistics?.patientPerDay} loading={loading} />
        <TotalMeanDrug totalDrugMean={statistics?.totalDrugMean} loading={loading} />
      </div>
    </>
  );
};

export default Dashboard;
