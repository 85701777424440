import React from 'react';
import { t } from 'i18next';
import { Button, Modal } from 'react-bootstrap';

interface LoadingErrorModalProps {
  error: string | null;
  handleClose: () => void;
}

const LoadingErrorModal = ({ error, handleClose }: LoadingErrorModalProps) => (
  <Modal show={!!error} onHide={handleClose} backdrop="static" centered className="error-modal">
    <Modal.Header closeButton>
      <Modal.Title>{t('error')}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{error}</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        {t('close')}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default LoadingErrorModal;
