import React, { useState } from 'react';
import { t } from 'i18next';
import * as yup from 'yup';
import { FieldArray, Formik, FormikHelpers, isEmptyArray } from 'formik';
import { handleHttpResponseError } from 'components/shared/helpers';
import { LoadingButton } from 'components/shared/LoadingButton';
import { Alert, Button, Form } from 'react-bootstrap';
import { changeNbCards } from 'services/orders/endpoints';
import { parseBarcode } from 'components/orders/helpers';
import { nbCards, Order } from 'components/orders/types';

interface BarcodesFormProps {
  order: Order;
  selectedNbcards: nbCards;
  close: (selected: nbCards) => void;
}

interface FormValues {
  barcodes: string[];
}

const validationSchema = yup.object().shape({
  barcodes: yup.array().of(yup.string().required('barcodeRequired')),
});

function BarcodesForm({ order, selectedNbcards, close }: BarcodesFormProps) {
  const [hasDuplicates, setHasDuplicates] = useState<boolean>(false);
  const prevNbCards: nbCards = order.barcodes.length as nbCards;

  const initialValues = {
    barcodes: order.barcodes.concat(new Array(selectedNbcards - order.barcodes.length).fill('')),
  };

  const hasDuplicatesfct = (array: Array<string>) => {
    const seen = new Set<string>();
    const duplicates: number[] = [];
    array.forEach((item, index) => {
      if (seen.has(item)) {
        duplicates.push(index);
      } else {
        seen.add(item);
      }
    });
    return duplicates;
  };

  const handleConfirm = async (values: FormValues) => {
    await changeNbCards(order.id, selectedNbcards, values.barcodes);
    close(selectedNbcards);
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);
    try {
      const duplicates = hasDuplicatesfct(values.barcodes);
      if (!isEmptyArray(duplicates)) {
        setHasDuplicates(true);
        return;
      }
      await changeNbCards(order.id, selectedNbcards, values.barcodes);
      setSubmitting(false);
      close(selectedNbcards);
    } catch (err) {
      handleHttpResponseError(err, 'FAILED CHANGE NB CARDS', setFieldError);
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="barcodes">
            <FieldArray
              name="barcodes"
              render={() => (
                <div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '1%',
                    }}
                  >
                    <Form.Label>{t('pleaseScanTheCardLabel')}</Form.Label>
                  </div>
                  {values.barcodes.map((barcode, index) => (
                    <div
                      key={index}
                      style={{
                        marginBottom: '1%',
                      }}
                    >
                      <div className="row">
                        <div className="col">
                          <Form.Control
                            type="text"
                            name={`barcodes[${index}]`}
                            value={parseBarcode(barcode)}
                            readOnly={index < order.barcodes.length}
                            placeholder={parseBarcode(values.barcodes[index])}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={!!errors.barcodes && !!errors.barcodes[index]}
                          />
                          <Form.Control.Feedback type="invalid" className="d-block">
                            {errors.barcodes && t(errors.barcodes[index])}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            />
            {hasDuplicates && <Alert variant="warning">{t('uniqueBarcodes')}</Alert>}
          </Form.Group>
          <div className="buttonLayout">
            <Button variant="secondary" onClick={() => close(prevNbCards)}>
              {t('cancel')}
            </Button>
            {!hasDuplicates && (
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('submit')}
              </LoadingButton>
            )}
            {hasDuplicates && (
              <LoadingButton
                variant="warning"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
                onClick={() => handleConfirm(values)}
              >
                {t('confirm')}
              </LoadingButton>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default BarcodesForm;
