import {
  Organization,
  OrganizationCreateFields,
  OrganizationPublicFields,
  OrganizationStatus,
} from 'components/organizations/types';
import types from './types';

export type OrganizationReducerState = Organization[];

export type OrganizationAction =
  | { type: types.CREATE_ORGANIZATION_REQUEST; payload: { organization: OrganizationCreateFields } }
  | {
      type: types.CREATE_ORGANIZATION_RESPONSE;
      payload: { organization: OrganizationPublicFields };
    }
  | { type: types.GET_ORGANIZATIONS_REQUEST }
  | {
      type: types.GET_ORGANIZATIONS_RESPONSE;
      payload: { organizations: OrganizationPublicFields[] };
    }
  | {
      type: types.UPDATE_ORGANIZATION_REQUEST;
      payload: { id: string; organization: Organization };
    }
  | {
      type: types.UPDATE_ORGANIZATION_RESPONSE;
      payload: { organization: OrganizationPublicFields };
    }
  | {
      type: types.UPDATE_ALL_ORGANIZATIONS_STATUS_REQUEST;
      payload: { status: OrganizationStatus };
    }
  | {
      type: types.UPDATE_ALL_ORGANIZATIONS_STATUS_RESPONSE;
      payload: { organizations: OrganizationPublicFields[] };
    }
  | {
      type: types.PATCH_ORGANIZATION_REQUEST;
      payload: { id: string; organization: Partial<Organization> };
    }
  | { type: types.PATCH_ORGANIZATION_RESPONSE; payload: { organization: OrganizationPublicFields } }
  | { type: types.DELETE_ORGANIZATION_REQUEST; payload: { organizationId: string } }
  | {
      type: types.DELETE_ORGANIZATION_RESPONSE;
      payload: { organization: OrganizationPublicFields };
    };

export default function reducers(
  state: OrganizationReducerState = null!,
  action: OrganizationAction,
): OrganizationReducerState {
  switch (action.type) {
    case types.GET_ORGANIZATIONS_RESPONSE:
    case types.UPDATE_ALL_ORGANIZATIONS_STATUS_RESPONSE: {
      return action.payload.organizations;
    }
    case types.CREATE_ORGANIZATION_RESPONSE:
      return (state || []).concat(action.payload.organization);
    case types.UPDATE_ORGANIZATION_RESPONSE:
    case types.PATCH_ORGANIZATION_RESPONSE:
      return state.map((organization) => {
        if (organization.id === action.payload.organization.id) {
          return action.payload.organization;
        }
        return organization;
      });
    case types.DELETE_ORGANIZATION_RESPONSE:
      return state.filter((organization) => organization.id !== action.payload.organization.id);
    default:
      return state;
  }
}
