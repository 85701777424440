import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import './style.scss';
import { t } from 'i18next';
import { logout } from 'services/login/operations';

const MaintenancePage = (): JSX.Element => (
  <div className="error-page maintenance">
    <span className="error-icon">
      <FontAwesomeIcon icon="wrench" color="grey" />
    </span>
    <p className="error-title">{t('appInMaintenance')}</p>
    <span className="error-code">
      {t('contactChronomedQuestion')}
      <br />
      (581) 990-8132
    </span>

    <Button onClick={logout}>{t('logout')}</Button>
  </div>
);

export default MaintenancePage;
