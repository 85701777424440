import { Drug } from 'components/drugs/types';
import { DrugAction } from './reducers';
import types from './types';

export const createDrugRequest = (drug: Omit<Drug, 'id' | 'newUPC'>): DrugAction => ({
  type: types.CREATE_DRUG_REQUEST,
  payload: { drug },
});

export const createDrugResponse = (drug: Drug): DrugAction => ({
  type: types.CREATE_DRUG_RESPONSE,
  payload: { drug },
});

export const getDrugsRequest = (): DrugAction => ({
  type: types.GET_DRUGS_REQUEST,
});

export const getDrugsResponse = (payload: { drugs: Drug[] }): DrugAction => ({
  type: types.GET_DRUGS_RESPONSE,
  payload,
});

export const updateDrugRequest = (id: string, drug: Drug): DrugAction => ({
  type: types.UPDATE_DRUG_REQUEST,
  payload: { id, drug },
});

export const updateDrugResponse = (drug: Drug): DrugAction => ({
  type: types.UPDATE_DRUG_RESPONSE,
  payload: { drug },
});

export const validateDrugRequest = (): DrugAction => ({
  type: types.VALIDATE_DRUG_REQUEST,
});

export const validateDrugResponse = (drug: Drug): DrugAction => ({
  type: types.VALIDATE_DRUG_RESPONSE,
  payload: { drug },
});

export const addDrugUPCRequest = (id: string, UPC: string): DrugAction => ({
  type: types.ADD_DRUG_UPC_REQUEST,
  payload: { id, UPC },
});

export const validateDrugUPCResponse = (drug: Drug): DrugAction => ({
  type: types.VALIDATE_DRUG_UPC_RESPONSE,
  payload: { drug },
});

export const validateDrugUPCRequest = (id: string, UPC: string): DrugAction => ({
  type: types.VALIDATE_DRUG_UPC_REQUEST,
  payload: { id, UPC },
});

export const invalidateDrugUPCResponse = (drug: Drug): DrugAction => ({
  type: types.INVALIDATE_DRUG_UPC_RESPONSE,
  payload: { drug },
});

export const invalidateDrugUPCRequest = (id: string, UPC: string): DrugAction => ({
  type: types.INVALIDATE_DRUG_UPC_REQUEST,
  payload: { id, UPC },
});

export const addDrugUPCResponse = (drug: Drug): DrugAction => ({
  type: types.ADD_DRUG_UPC_RESPONSE,
  payload: { drug },
});

export const patchDrugRequest = (id: string, drug: Drug): DrugAction => ({
  type: types.PATCH_DRUG_REQUEST,
  payload: { id, drug },
});

export const patchDrugResponse = (drug: Drug): DrugAction => ({
  type: types.PATCH_DRUG_RESPONSE,
  payload: { drug },
});

export const deleteDrugRequest = (drugId: string): DrugAction => ({
  type: types.DELETE_DRUG_REQUEST,
  payload: { drugId },
});

export const deleteDrugResponse = (drug: Drug): DrugAction => ({
  type: types.DELETE_DRUG_RESPONSE,
  payload: { drug },
});

export const importDrugListRequest = (drugList: Omit<Drug, 'id' | 'newUPC'>[]): DrugAction => ({
  type: types.IMPORT_DRUGLIST_REQUEST,
  payload: { drugList },
});

export const importDrugListResponse = (drugs: Drug[]): DrugAction => ({
  type: types.IMPORT_DRUGLIST_RESPONSE,
  payload: { drugs },
});
