import { Drug } from 'components/drugs/types';
import { get, post, put, patch, del } from '../api';

export const getDrugs = (filter) => get('/drugs', {}, { filter });

export const createDrug = (drug: Omit<Drug, 'id' | 'newUPC'>) => post('/drugs', {}, drug);

export const importDrugList = (drugList: Omit<Drug, 'id' | 'newUPC'>[]) =>
  post('/drugs/importDrugList', {}, drugList);

export const updateDrug = (id: string, drug: Drug) => put('/drugs/:id', { id }, drug);

export const patchDrug = (id: string, drug: Drug) => patch('/drugs/:id', { id }, drug);

export const validateDrug = (id: string) => put('/drugs/:id/validateDrug', { id });

export const addDrugUPC = (id: string, UPC: string) => put('/drugs/:id/addUPC', { id }, { UPC });

export const validateDrugUPC = (id: string, UPC: string) =>
  put('/drugs/:id/validateUPC', { id }, { UPC });

export const invalidateDrugUPC = (id: string, UPC: string) =>
  put('/drugs/:id/invalidateUPC', { id }, { UPC });

export const deleteDrug = (id: string) => del('/drugs/:id', { id });
