import React from 'react';
import { t } from 'i18next';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DateTime } from 'luxon';
import { patchOrder } from 'services/orders/endpoints';
import { Order } from '../types';

interface NewestOrderModalProps {
  show: boolean;
  currentOrder: Order | null;
  handleClose: () => void;
  sendOrder: () => void;
}

function NewestOrderModal({ show, currentOrder, handleClose, sendOrder }: NewestOrderModalProps) {
  const byPassNewest = async () => {
    await patchOrder(currentOrder!.id, {
      newestOrder: { ...currentOrder!.newestOrder!, byPass: true },
    });
    sendOrder();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Attention !</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t('newestOrderExist', {
            lookupId: currentOrder?.newestOrder?.lookupId,
            patient: currentOrder?.patient,
            createdAt: DateTime.fromISO(currentOrder?.newestOrder?.createdAt).toFormat(
              'yyyy-MM-dd',
            ),
          })}
        </p>
        <hr />
        <p>
          {t('continuePrintOldOrder', {
            lookupId: currentOrder?.lookupId,
            createdAt: DateTime.fromISO(currentOrder?.createdAt).toFormat('yyyy-MM-dd'),
          })}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('cancel')}
        </Button>
        <Button variant="primary" onClick={byPassNewest}>
          {t('send')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewestOrderModal;
