import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import links from 'utils/links';
import './style.scss';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const Page401 = (): JSX.Element => (
  <div className="error-page">
    <span className="error-icon">
      <FontAwesomeIcon icon="lock" color="grey" />
    </span>
    <p className="error-title">{t('authorizationRequired')}</p>
    <span className="error-code">{t('error401')}</span>
    <Link to={links.home()}>
      <Button>{t('homePage')}</Button>
    </Link>
  </div>
);

export default Page401;
