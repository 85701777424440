import {
  UserRole,
  UserPermissions,
  TechPermissions,
  SuperTechPermissions,
  ChronoTechPermissions,
  AdminPermissions,
} from './types';

export function getUserRole(permissions: UserPermissions[]): UserRole {
  if (AdminPermissions.every((permission) => permissions.includes(permission))) {
    return UserRole.ADMIN;
  }
  if (ChronoTechPermissions.every((permission) => permissions.includes(permission))) {
    return UserRole.CHRONO_TECH;
  }
  if (SuperTechPermissions.every((permission) => permissions.includes(permission))) {
    return UserRole.SUPER_TECH;
  }
  if (TechPermissions.every((permission) => permissions.includes(permission))) {
    return UserRole.TECH;
  }

  return UserRole.UNKNOWN;
}
