import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Order } from 'components/orders/types';
import { ProgressBar } from 'react-bootstrap';
import { getSocket } from 'services/sockets';

interface OngoingStatusCellProps {
  order: Order;
}

const OngoingStatusCell = ({ order }: OngoingStatusCellProps) => {
  const [percentage, setPercentage] = useState<number>(order.progression?.percentage || 0);
  const [trayInProgress, setTrayInProgress] = useState<string | null>(null);

  useEffect(() => {
    const socket = getSocket();
    socket?.on('orders.progress', (progressData) => {
      if (progressData.machineId === order.machineId) {
        if (progressData.percentage) {
          setPercentage(progressData.percentage);
        }
        if (progressData.trayId) {
          setTrayInProgress(progressData.trayId);
        }
      }
    });

    return () => {
      socket?.off('orders.progress');
    };
  }, [setPercentage, order.machineId]);
  return (
    <>
      {order.progression && order.trayIds.length > 0 && (
        <>
          <FontAwesomeIcon
            icon="inbox"
            size="1x"
            color="#00aaff"
            className="trayInProcessIcon"
            style={{ marginRight: 15 }}
          />
          {trayInProgress}
          <ProgressBar
            now={Math.round(percentage * 100)}
            label={`${Math.round(percentage * 100)}%`}
          />
        </>
      )}
      {order.progression && order.trayIds.length === 0 && (
        <>
          <FontAwesomeIcon
            icon="sync"
            size="1x"
            color="#007bff"
            className="pendingIcon"
            style={{ marginRight: 15 }}
          />

          <ProgressBar
            now={Math.round(percentage * 100)}
            label={`${Math.round(percentage * 100)}%`}
          />
        </>
      )}{' '}
      {!order.progression && (
        <>
          <FontAwesomeIcon
            icon="sync"
            size="1x"
            color="#007bff"
            className="pendingIcon"
            style={{ marginRight: 15 }}
          />{' '}
          {order.trayIds.length > 0 ? order.trayIds[0] : <></>}{' '}
          {order.trayIds.length === 2 ? <> & {order.trayIds[1]}</> : <></>}
        </>
      )}
    </>
  );
};

export default OngoingStatusCell;
