import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import ResourcesHandler from 'components/ResourcesHandler';
import { RootState } from 'services/store';
import { fetchOrganizations } from 'services/organizations/operations';
import { fetchUsers } from 'services/users/operations';
import Dashboard from 'components/dashboard/Dashboard';
import './style.scss';

interface Organization {
  id: string;
  name: string;
}

const DashboardPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const organizationId = useSelector((state: RootState) => state.login.organizationId);
  const organizations = useSelector((state: RootState) => state.organizations);
  const [currentOrganizationId, setCurrentOrganizationId] = useState<string>(organizationId || '');
  const { t } = useTranslation();

  const handleSelectOrganization = (orgId: string | null) => {
    if (orgId) {
      setCurrentOrganizationId(orgId);
    }
  };

  const getPage = () => (
    <div>
      <div className="dashboard-title">
        <h2 style={{ marginLeft: '10px' }}>
          {t('dashboard')} (
          {new Date().toLocaleString('default', { month: 'long', year: 'numeric' })})
        </h2>
        {organizationId === '*' && (
          <Dropdown style={{ marginLeft: '10px' }} onSelect={handleSelectOrganization}>
            <Dropdown.Toggle id="dropdown-basic">
              {currentOrganizationId === '*'
                ? t('allOrg')
                : organizations.find((org) => org.id === currentOrganizationId)?.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item key="*" eventKey="*">
                {t('allOrg')}
              </Dropdown.Item>
              {organizations.map((org: Organization) => (
                <Dropdown.Item key={org.id} eventKey={org.id}>
                  {org.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <Dashboard organizationId={currentOrganizationId} />
    </div>
  );

  return (
    <ResourcesHandler
      resources={[organizations]}
      resourceFetchers={[() => dispatch(fetchOrganizations()), () => dispatch(fetchUsers())]}
      getContents={getPage}
    />
  );
};

export default DashboardPage;
