import React, { useState, useEffect } from 'react';
import { Form, Button, Collapse } from 'react-bootstrap';
import { ErrorMessage, FieldArray, useField, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { FormValues } from '../DrugFormModal';

interface DeprecatedDINsProps {
  handleBlur: FormikProps<FormValues>['handleBlur'];
  setFieldValue: FormikProps<FormValues>['setFieldValue'];
  values: FormikProps<FormValues>['values'];
}

function DeprecatedDINs({ handleBlur, setFieldValue, values }: DeprecatedDINsProps) {
  const [field, meta] = useField('deprecatedDINs');
  const [showDeprecatedDINs, setShowDeprecatedDINs] = useState<boolean>(false);

  useEffect(() => {
    if (meta.error && meta.touched) {
      setShowDeprecatedDINs(true);
    }
  }, [meta.error, meta.touched]);

  return (
    <Form.Group controlId="deprecatedDINs">
      <FieldArray
        name="deprecatedDINs"
        render={(arrayHelpers) => (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1%',
              }}
            >
              <Form.Label
                onClick={() => setShowDeprecatedDINs(!meta.error ? !showDeprecatedDINs : true)}
              >
                {`${t('deprecatedDINs')} `}
                {showDeprecatedDINs ? (
                  <FontAwesomeIcon icon="angle-up" />
                ) : (
                  <FontAwesomeIcon icon="angle-down" />
                )}
              </Form.Label>
              <Button
                onClick={() => arrayHelpers.push('')}
                variant="outline-secondary"
                size="sm"
                hidden={!showDeprecatedDINs}
              >
                <FontAwesomeIcon icon="plus" />
              </Button>
            </div>
            <Collapse in={showDeprecatedDINs}>
              <div>
                {values.deprecatedDINs.map((deprecatedDIN, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '1%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Form.Control
                        type="text"
                        name={`deprecatedDINs[${index}]`}
                        value={deprecatedDIN}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          const newDeprecatedDINs = [...values.deprecatedDINs];
                          newDeprecatedDINs[index] = event.target.value;
                          setFieldValue('deprecatedDINs', newDeprecatedDINs);
                        }}
                      />
                      <Button
                        variant="outline-danger"
                        style={{ marginLeft: '1%' }}
                        onClick={async () => arrayHelpers.remove(index)}
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />
                      </Button>
                    </div>
                    <ErrorMessage
                      name={`${field.name}[${index}]`}
                      render={(msg) => <span className="error-message">{t(msg)}</span>}
                    />
                  </div>
                ))}
              </div>
            </Collapse>
          </div>
        )}
      />
    </Form.Group>
  );
}

export default DeprecatedDINs;
