import { Dispatch } from 'redux';
import { NewUser, User } from 'components/users/types';
import {
  getUsers as APIgetUsers,
  createUser as APIcreateUser,
  updateUser as APIupdateUser,
  patchUser as APIpatchUser,
  deleteUser as APIdeleteUser,
  toggleVAssyst as APItoggleVAssyst,
  toggleTwoFA as APItoggleTwoFA,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  createUserRequest,
  createUserResponse,
  getUsersRequest,
  getUsersResponse,
  updateUserRequest,
  updateUserResponse,
  patchUserRequest,
  patchUserResponse,
  deleteUserRequest,
  deleteUserResponse,
  toggleVAssystRequest,
  toggleVAssystResponse,
  toggleTwoFARequest,
  toggleTwoFAResponse,
} from './actions';
import { UserAction } from './reducers';

type UserDispatch = Dispatch<UserAction>;

export function fetchUsers() {
  return fetchOnceOperation(
    getUsersRequest,
    getUsersResponse,
    APIgetUsers,
    (state) => state.users,
    [],
  );
}

export function createUser(user: NewUser) {
  return (dispatch: UserDispatch) => {
    dispatch(createUserRequest(user));
    return APIcreateUser(user).then((res) => dispatch(createUserResponse({ ...res })));
  };
}

export function updateUser(id: string, user: User) {
  return (dispatch: UserDispatch) => {
    dispatch(updateUserRequest(id, user));
    return APIupdateUser(id, user).then((res) => dispatch(updateUserResponse({ ...res })));
  };
}

export function patchUser(id: string, user: Partial<User>) {
  return (dispatch: UserDispatch) => {
    dispatch(patchUserRequest(id, user));
    return APIpatchUser(id, user).then((res) => dispatch(patchUserResponse({ ...res })));
  };
}

export function deleteUser(id: string) {
  return (dispatch: UserDispatch) => {
    dispatch(deleteUserRequest(id));
    return APIdeleteUser(id).then((res) => dispatch(deleteUserResponse({ ...res })));
  };
}

export function toggleVAssyst(id: string) {
  return (dispatch: UserDispatch) => {
    dispatch(toggleVAssystRequest(id));
    return APItoggleVAssyst(id).then((res) => dispatch(toggleVAssystResponse({ ...res })));
  };
}

export function toggleTwoFA(id: string) {
  return (dispatch: UserDispatch) => {
    dispatch(toggleTwoFARequest(id));
    return APItoggleTwoFA(id).then((res) => dispatch(toggleTwoFAResponse({ ...res })));
  };
}

const operations = {
  fetchUsers,
  createUser,
  updateUser,
  patchUser,
  deleteUser,
  toggleVAssyst,
};

export default operations;
