/* eslint-disable indent */
// eslint is disable because of a conflict with prettier
import React, { useEffect, useRef } from 'react';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Modal, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { t } from 'i18next';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { LoadingButton } from 'components/shared/LoadingButton';
import { createOrganization, updateOrganization } from 'services/organizations/operations';
import { createMachine } from 'services/machines/operations';
import Types from 'services/organizations/types';
import './style.scss';
import { Organization, OrganizationStatus, PrescriptionSoftware } from './types';

const validationSchema = yup.object().shape({
  organizationName: yup
    .string()
    .matches(/^[a-zA-Z0-9-]+$/, t('fieldOnlyAcceptAlphanumeric'))
    .required('organizationNameRequired'),
  status: yup.string().oneOf(Object.values(OrganizationStatus)),
  prescriptionSoftware: yup.string().oneOf(Object.values(PrescriptionSoftware)),
  authUser: yup.string().matches(/^[a-zA-Z0-9]+$/, t('fieldOnlyAcceptAlphanumeric')),
  authPwd: yup.string().matches(/^[a-zA-Z0-9-]+$/, t('fieldOnlyAcceptAlphanumeric')),
});

interface FormValues {
  organizationName: string;
  status: OrganizationStatus;
  prescriptionSoftware: PrescriptionSoftware | undefined;
  authUser: string | undefined;
  authPwd: string | undefined;
}

interface OrganizationFormModalProps {
  show: boolean;
  close: () => void;
  organization?: Organization;
}

const OrganizationFormModal = ({ show, close, organization }: OrganizationFormModalProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dispatch = useDispatch<Dispatch<any>>();

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  const initialValues = {
    organizationName: organization ? organization.name : '',
    status: organization?.status ? organization.status : OrganizationStatus.ACTIVE,
    prescriptionSoftware:
      organization && organization.prescriptionSoftware
        ? organization.prescriptionSoftware.name
        : undefined,
    authUser: organization?.prescriptionSoftware?.authUser
      ? organization.prescriptionSoftware.authUser
      : undefined,
    authPwd: organization?.prescriptionSoftware?.authPassword
      ? organization.prescriptionSoftware.authPassword
      : undefined,
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);

    if (organization) {
      try {
        const editedOrganization: Organization = {
          id: organization.id,
          name: values.organizationName,
          createdAt: organization.createdAt,
          status: values.status,
          prescriptionSoftware: values.prescriptionSoftware
            ? {
                name: values.prescriptionSoftware,
                authUser: values.authUser,
                authPassword: values.authPwd,
              }
            : undefined,
        };

        await updateOrganization(organization.id, editedOrganization)(dispatch);

        setSubmitting(false);
        close();
      } catch (err) {
        handleHttpResponseError(err, 'FAILED EDITING ORGANIZATION', setFieldError);
      }
    } else {
      try {
        const response = await createOrganization({
          name: values.organizationName,
          status: values.status,
          prescriptionSoftware: values.prescriptionSoftware
            ? {
                name: values.prescriptionSoftware,
                authUser: values.authUser,
                authPassword: values.authPwd,
              }
            : undefined,
        })(dispatch);
        if (response.type === Types.CREATE_ORGANIZATION_RESPONSE) {
          await createMachine({
            id: response.payload.organization.name, // BYPASS this will eventually need to change to real id
            organizationId: response.payload.organization.id,
            name: values.organizationName,
          })(dispatch);
        }
        setSubmitting(false);
        close();
      } catch (err) {
        handleHttpResponseError(err, 'FAILED UPLOAD ORGANIZATION', setFieldError);
      }
    }
    setSubmitting(false);
  };

  return (
    <Modal show={show} onHide={close} className="organization-form-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {organization
            ? t('editOrganization', { organizationName: organization.name })
            : t('addOrganization')}
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="organizationName">
                <Form.Label>{t('name')}</Form.Label>
                <Form.Control
                  ref={inputRef}
                  defaultValue={initialValues.organizationName}
                  className={
                    errors.organizationName && touched.organizationName ? 'error-text' : ''
                  }
                  type="text"
                  name="organizationName"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('organizationName', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="organizationName"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
              <Form.Group className="organization-status">
                <Form.Label>{t('status')}</Form.Label>
                <select
                  name="status"
                  defaultValue={
                    organization?.status ? organization?.status : OrganizationStatus.ACTIVE
                  }
                  onChange={(e) => setFieldValue('status', e.target.value as OrganizationStatus)}
                  className={
                    errors.status && touched.status ? 'custom-select error-select' : 'custom-select'
                  }
                >
                  {Object.values(OrganizationStatus).map((os) => (
                    <option key={os} value={os}>
                      {t(os)}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  name="status"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
              <hr />
              <h5>{t('prescriptionSoftware')}</h5>
              <div className="prescriptionSoftwareForm">
                <Form.Group>
                  <Form.Label>{t('software')}</Form.Label>
                  <select
                    name="prescriptionSoftware"
                    defaultValue={
                      organization?.prescriptionSoftware
                        ? organization?.prescriptionSoftware?.name
                        : PrescriptionSoftware.None
                    }
                    onChange={(e) =>
                      setFieldValue('prescriptionSoftware', e.target.value as PrescriptionSoftware)
                    }
                    className={
                      errors.prescriptionSoftware && touched.prescriptionSoftware
                        ? 'custom-select error-select'
                        : 'custom-select'
                    }
                  >
                    {Object.values(PrescriptionSoftware).map((ps) => (
                      <option key={ps} value={ps}>
                        {ps}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name="prescriptionSoftware"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('user')}</Form.Label>
                  <Form.Control
                    defaultValue={initialValues.authUser}
                    className={errors.authUser && touched.authUser ? 'error-text' : ''}
                    type="text"
                    name="authUser"
                    autoComplete="new-username"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('authUser', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="authUser"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Control
                    defaultValue={initialValues.authPwd}
                    className={errors.authPwd && touched.authPwd ? 'error-text' : ''}
                    type="password"
                    name="authPwd"
                    autoComplete="new-password"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('authPwd', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="authPwd"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('submit')}
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default OrganizationFormModal;
