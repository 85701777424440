import { CreateMachine, Machine } from 'components/machines/types';
import { MachineAction } from './reducers';
import types from './types';

export const createMachineRequest = (machine: CreateMachine): MachineAction => ({
  type: types.CREATE_MACHINE_REQUEST,
  payload: { machine },
});

export const createMachineResponse = (machine: Machine): MachineAction => ({
  type: types.CREATE_MACHINE_RESPONSE,
  payload: { machine },
});

export const getMachinesRequest = (): MachineAction => ({
  type: types.GET_MACHINES_REQUEST,
});

export const getMachinesResponse = (payload: { machines: Machine[] }): MachineAction => ({
  type: types.GET_MACHINES_RESPONSE,
  payload,
});

export const updateMachineRequest = (id: string, machine: Machine): MachineAction => ({
  type: types.UPDATE_MACHINE_REQUEST,
  payload: { id, machine },
});

export const updateMachineResponse = (machine: Machine): MachineAction => ({
  type: types.UPDATE_MACHINE_RESPONSE,
  payload: { machine },
});

export const deleteMachineRequest = (machineId: string): MachineAction => ({
  type: types.DELETE_MACHINE_REQUEST,
  payload: { machineId },
});

export const deleteMachineResponse = (machine: Machine): MachineAction => ({
  type: types.DELETE_MACHINE_RESPONSE,
  payload: { machine },
});
