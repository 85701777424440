import { CreateMachine, Machine } from 'components/machines/types';
import { get, post, put, del } from '../api';

export const getMachines = (filter) => get('/machines', {}, { filter });

export const getMachine = (id: string) => get('/machines/:id', { id });

export const getActiveOrderSummary = (id: string) =>
  get('/machines/:id/activeOrderSummary', { id });

export const createMachine = (machine: CreateMachine) => post('/machines', {}, machine);

export const updateMachine = (id: string, machine: Machine) =>
  put('/machines/:id', { id }, machine);

export const deleteMachine = (id: string) => del('/machines/:id', { id });
