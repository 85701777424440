import {
  OrganizationCreateFields,
  Organization,
  OrganizationStatus,
} from 'components/organizations/types';
import { get, post, put, patch, del } from '../api';

export const getOrganizations = (filter) => get('/organizations', {}, { filter });

export const getOrganization = (id: string) => get('/organizations/:id', { id });

export const createOrganization = (organization: OrganizationCreateFields) =>
  post('/organizations', {}, organization);

export const updateOrganization = (id: string, organization: Organization) =>
  put('/organizations/:id', { id }, organization);

export const updateAllOrganizationsStatus = (status: OrganizationStatus) =>
  put('/organizations/all/updateStatus', {}, { status });

export const patchOrganization = (id: string, organization: Partial<Organization>) =>
  patch('/organizations/:id', { id }, organization);

export const deleteOrganization = (id: string) => del('/organizations/:id', { id });
