import { Dispatch } from 'redux';
import { Settings } from 'components/settings/types';
import {
  getSettings as APIgetSettings,
  updateSettings as APIupdateSettings,
  patchSettings as APIpatchSettings,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  getSettingsRequest,
  getSettingsResponse,
  updateSettingsRequest,
  updateSettingsResponse,
  patchSettingsRequest,
  patchSettingsResponse,
} from './actions';
import { SettingsAction } from './reducers';

type SettingsDispatch = Dispatch<SettingsAction>;

export function fetchSettings() {
  return fetchOnceOperation(
    getSettingsRequest,
    getSettingsResponse,
    APIgetSettings,
    (state) => state.settings,
    [],
  );
}

export function updateSettings(settings: Settings) {
  return (dispatch: SettingsDispatch) => {
    dispatch(updateSettingsRequest(settings));
    return APIupdateSettings(settings).then((res) => dispatch(updateSettingsResponse({ ...res })));
  };
}

export function patchSettings(settings: Partial<Settings>) {
  return (dispatch: SettingsDispatch) => {
    dispatch(patchSettingsRequest(settings));
    return APIpatchSettings(settings).then((res) => dispatch(patchSettingsResponse({ ...res })));
  };
}

const operations = {
  fetchSettings,
  updateSettings,
  patchSettings,
};

export default operations;
