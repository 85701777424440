import React, { useState, useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Modal, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { t } from 'i18next';
import { LoadingButton } from 'components/shared/LoadingButton';
import { createDrug, updateDrug, validateDrug } from 'services/drugs/operations';
import Types from 'services/drugs/types';
import { nextFocus } from 'components/buckets/formHelper';
import { DrugWarnings, DrugForms } from '../types';
import UPCs from './components/UPCs';
import Alternatives from './components/Alternatives';
import DeprecatedDINs from './components/DeprecatedDINs';

const dinValidation = yup
  .string()
  .matches(/^\d+$/, 'incorrectDINFormat')
  .length(8, 'incorrectDINLength')
  .required('DINRequired');

export const drugSchema = yup.object().shape({
  DIN: dinValidation,
  name: yup
    .string()
    .matches(/^[0-9a-zA-Zà-üÀ-Ü .()+-]+$/, 'invalidFormatName')
    .required('nameRequired'),
  force: yup.string().matches(/^[0-9a-zA-Z /+.]*$/, 'invalidFormatForce'),
  format: yup.string().oneOf(DrugForms, 'unregisteredFormat'),
  UPC: yup
    .array()
    .of(
      yup
        .string()
        .matches(/^\d+$/, 'incorrectUPCFormat')
        .test(
          'lengthCheck',
          'incorrectUPCLength',
          (value) => !value || value.length === 12 || value.length === 13,
        )
        .required('UPCRequired'),
    )
    .required('UPCArrayMissing'),
  newUPC: yup
    .array()
    .of(
      yup
        .string()
        .matches(/^\d+$/, 'incorrectUPCFormat')
        .test(
          'lengthCheck',
          'incorrectUPCLength',
          (value) => !value || value.length === 12 || value.length === 13,
        ),
    )
    .default([]),
  alternatives: yup.array().of(dinValidation),
  deprecatedDINs: yup.array().of(dinValidation),
});

export interface FormValues {
  DIN: string;
  name: string;
  force: string;
  format: string;
  UPC: string[];
  newUPC: string[];
  alternatives: string[];
  deprecatedDINs: string[];
}

const emptyFormValues = {
  DIN: '',
  name: '',
  force: '',
  format: '',
  UPC: [],
  newUPC: [],
  alternatives: [],
  deprecatedDINs: [],
};

const areValuesTheSame = (values: FormValues, initialValues: FormValues): boolean => {
  const areArraysTheSame = (arr1: string[], arr2: string[]): boolean =>
    arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);

  return Object.keys(initialValues).every((key) => {
    if (Array.isArray(initialValues[key])) {
      return areArraysTheSame(values[key], initialValues[key]);
    }
    return initialValues[key] === values[key];
  });
};

interface DrugFormModalProps {
  show: boolean;
  close: () => void;
  onExited: () => void;
  drugId: string | null;
  changeInContextDrugId: (drugId: string | null) => void;
}

const DrugFormModal = ({
  show,
  close,
  onExited,
  drugId,
  changeInContextDrugId,
}: DrugFormModalProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const drugs = useSelector((state: RootState) => state.drugs);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [nextInContextDIN, setNextInContextDIN] = useState<string | undefined>(undefined);
  const drug = drugs.find((drug) => drug.id === drugId);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [initialValues, setInitialValues] = useState<FormValues>(emptyFormValues);

  useEffect(() => {
    const drug = drugs.find((drug) => drug.id === drugId);
    if (drug) {
      setInitialValues({
        DIN: drug.DIN,
        name: drug.name,
        force: drug.force || '',
        format: drug.format || '',
        UPC: drug.UPC || [],
        newUPC: drug.newUPC || [],
        alternatives: drug.alternatives || [],
        deprecatedDINs: drug.deprecatedDINs || [],
      });
    } else {
      setInitialValues({
        ...emptyFormValues,
        DIN: nextInContextDIN || '',
      });
    }
  }, [drugId, drugs, nextInContextDIN]);

  const isUnknownSavedDIN = (DIN: string): boolean =>
    !drugs.find((drug) => drug.DIN === DIN) && !!drug?.alternatives?.includes(DIN);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    try {
      setSubmitting(true);
      const drugInfos = {
        ...values,
        UPC: values.UPC.filter((upc) => upc !== null),
      };
      if (drug) {
        await updateDrug(drug.id, { ...drug, ...drugInfos })(dispatch);
      } else {
        const response = await createDrug(drugInfos)(dispatch);
        if (response.type === Types.CREATE_DRUG_RESPONSE) {
          changeInContextDrugId(response.payload.drug.id);
        }
      }
    } catch (err) {
      handleHttpResponseError(err, 'FAILED CREATE DRUG', setFieldError);
    } finally {
      setSubmitting(false);
    }
  };

  const handleValidate = async (id: string) => {
    setIsValidating(true);
    try {
      await validateDrug(id)(dispatch);
    } catch (err) {
      console.error(err);
    } finally {
      setIsValidating(false);
    }
  };

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => {
        setNextInContextDIN(undefined);
        close();
      }}
      onExited={onExited}
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={drugSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form key={drug?.id} onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t(drug ? 'editDrug' : 'addDrug')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="DIN">
                <Form.Label>{t('DIN')}</Form.Label>
                <Form.Control
                  value={values.DIN}
                  className={errors.DIN && touched.DIN ? 'error-text' : ''}
                  type="text"
                  name="DIN"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value.length === 8) {
                      nextFocus('name');
                    }
                  }}
                  ref={inputRef}
                />
                <ErrorMessage
                  name="DIN"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group controlId="name">
                <Form.Label>{t('name')}</Form.Label>
                <Form.Control
                  value={values.name}
                  className={errors.name && touched.name ? 'error-text' : ''}
                  type="text"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="name"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group controlId="force">
                <Form.Label>{t('strength')}</Form.Label>
                <Form.Control
                  value={values.force}
                  className={errors.force && touched.force ? 'error-text' : ''}
                  type="text"
                  name="force"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="force"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group controlId="format">
                <Form.Label>{t('format')}</Form.Label>
                <Form.Control
                  as="select"
                  value={values.format}
                  className={errors.format && touched.format ? 'error-text' : ''}
                  name="format"
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="" />
                  {DrugForms.map((form) => (
                    <option key={form} value={form}>
                      {form}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <UPCs handleBlur={handleBlur} setFieldValue={setFieldValue} values={values} />

              <Alternatives
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                values={values}
                dependencies={{ isUnknownSavedDIN, setNextInContextDIN, changeInContextDrugId }}
              />

              <DeprecatedDINs
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                values={values}
              />
            </Modal.Body>
            <Modal.Footer>
              {drug && drug.warnings?.includes(DrugWarnings.NOT_VALIDATED) && (
                <LoadingButton
                  variant="success"
                  loading={isValidating}
                  disabled={isValidating}
                  onClick={() => handleValidate(drug!.id)}
                >
                  {t('validateDrug')}
                </LoadingButton>
              )}
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || areValuesTheSame(values, initialValues)}
              >
                {t('submit')}
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default DrugFormModal;
