import Page401 from 'pages/errorPages/Page401';
import Page500 from 'pages/errorPages/Page500';
import React, { useState, useEffect } from 'react';

interface ResourcesHandlerProps {
  resources: any[];
  resourceFetchers: any[];
  getContents: (any) => JSX.Element;
  onPermissionsDenied?: () => JSX.Element;
  onInternalServerError?: () => JSX.Element;
}

const ResourcesHandler = ({
  resources,
  resourceFetchers,
  getContents,
  onPermissionsDenied,
  onInternalServerError,
}: ResourcesHandlerProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const [is401, setIs401] = useState(false);
  const [is500, setIs500] = useState(false);

  const fetchResources = () => {
    Promise.all(
      resourceFetchers.map(async (fetcher) => {
        const res = await fetcher();
        if (res && res.payload && res.payload.res && res.payload.res.response) {
          const { status } = res.payload.res.response;
          switch (status) {
            case 200:
              break;
            case 401:
              setIs401(true);
              break;
            case 500:
              setIs500(true);
              break;
            default:
          }
          setIsLoading(false);
        }
      }),
    );
  };

  useEffect(() => {
    const areResourcesFetched = resources.every((resource) => resource !== null);
    if (!areResourcesFetched) {
      fetchResources();
    } else {
      setIsLoading(false);
    }
  }, [resources]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (is401) {
    return typeof onPermissionsDenied === 'function' ? onPermissionsDenied() : <Page401 />;
  }
  if (is500) {
    return typeof onInternalServerError === 'function' ? onInternalServerError() : <Page500 />;
  }

  return getContents(resources);
};

export default ResourcesHandler;
