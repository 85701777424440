import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { validateDrugUPC, invalidateDrugUPC } from 'services/drugs/operations';
import DrugUPCTable from './DrugUPCTable';

interface DrugDetailsModalProps {
  show: boolean;
  close: () => void;
  onExited: () => void;
  drugId: string;
}

const DrugDetailsModal = ({ show, close, onExited, drugId }: DrugDetailsModalProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const drugs = useSelector((state: RootState) => state.drugs);
  const drug = drugs.find((drug) => drug.id === drugId);
  const [key, setKey] = useState<string>('UPCs');

  const handleValidate = async (upc) => {
    try {
      await validateDrugUPC(drugId, upc)(dispatch);
    } catch (err) {
      console.error(err);
    }
  };
  const handleInvalidate = async (upc) => {
    try {
      await invalidateDrugUPC(drugId, upc)(dispatch);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={show} onHide={close} onExited={onExited}>
      <Modal.Header closeButton>
        <Modal.Title>{`${drug?.DIN} - ${drug?.name?.toUpperCase()}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={key} onSelect={(k) => k && setKey(k)}>
          <Tab eventKey="UPCs" title={t('UPCs')}>
            <DrugUPCTable drugId={drugId} />
          </Tab>
          <Tab eventKey="unvalidatedUPC" title={t('unvalidatedUPC')}>
            <DrugUPCTable
              drugId={drugId}
              handleValidate={handleValidate}
              handleInvalidate={handleInvalidate}
            />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
export default DrugDetailsModal;
