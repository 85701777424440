import React from 'react';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserRow from 'components/users/UserRow';
import { User } from '../../users/types';

interface OrganizationUsersTableProps {
  organizationId: string;
  users: User[];
}

function OrganizationUsersTable({
  organizationId,
  users,
}: OrganizationUsersTableProps): JSX.Element {
  const { t } = useTranslation();

  const rows =
    users &&
    users
      .filter((user) => user.organizationId === organizationId)
      .map((user) => <UserRow user={user} isAdmin showOrganization={false} />);

  return (
    <>
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>{t('userName')}</th>
            <th>{t('lastName')}</th>
            <th>{t('firstName')}</th>
            <th>{t('emailAddress')}</th>
            <th style={{ width: '10%' }}>{t('role')}</th>
            <th>{t('vAssyst')}</th>
            <th>{t('twoFA')}</th>
            <th style={{ width: '15%' }}>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  );
}
export default OrganizationUsersTable;
