export enum DrugWarnings {
  NOT_VALIDATED = 'not_validated',
  UNDER_RECALL = 'under_recall',
}

export const DrugForms = [
  'CAPSULE',
  'CAPSULE ENT.',
  'CAPSULE LA',
  'CAPSULE-12H',
  'CAPSULE-24H',
  'CAPSULE-LA',
  'CHEWABLE TAB',
  'COMP. 21',
  'COMP. 28',
  'COMP. ENT.',
  'COMP. RAPIDE',
  'COMP. CROQ.',
  'COMPRIME',
  'COMPRIME ENT.',
  'COMPRIME SUB',
  'COMPRIME-12H',
  'COMPRIME-24H',
  'COMPRIME-8H',
  'COMPRIME-LA',
  'GELULE',
];

export const DrugForce = ['MG', 'MC', 'UI', 'UNITES', 'UNITES FCC', 'G', 'U', 'MULTI.', 'MEQ'];

export interface Drug {
  id: string;
  DIN: string;
  UPC: string[];
  newUPC: string[];
  name: string;
  force?: string;
  format?: string;
  alternatives?: string[];
  warnings?: DrugWarnings[];
  deprecatedDINs?: string[];
}
