import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { t } from 'i18next';
import { getSocket } from 'services/sockets';
import { Order } from '../types';

interface NewOrdersAlertProps {
  organizationId: string | undefined;
  addNewOrders: (orders: Order[]) => void;
}

const NewOrdersAlert = ({ organizationId, addNewOrders }: NewOrdersAlertProps) => {
  const [newOrders, setNewOrders] = useState<Order[]>([]);

  useEffect(() => {
    const socket = getSocket();
    socket?.on('orders.created', (order: Order) => {
      if (order.organizationId === organizationId) {
        setNewOrders((prevOrders) => [order, ...prevOrders]);
      }
    });

    return () => {
      socket?.off('orders.created');
    };
  }, [organizationId]);

  const handleNewOrders = () => {
    addNewOrders(newOrders);
    setNewOrders([]);
  };

  if (newOrders.length === 0) {
    return null;
  }

  return (
    <Alert
      style={{ marginTop: '20px' }}
      variant="primary"
      dismissible
      onClose={() => setNewOrders([])}
    >
      <b>{t('newOrdersAvailable', { nb: newOrders.length })}</b>
      <br />
      <small>{t('updateListEraseInfos')}</small>
      <div className="d-flex justify-content-end">
        <Button onClick={handleNewOrders}>{t('updateList')}</Button>
      </div>
    </Alert>
  );
};

export default NewOrdersAlert;
