enum Types {
  CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST',
  CREATE_ORGANIZATION_RESPONSE = 'CREATE_ORGANIZATION_RESPONSE',
  GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST',
  GET_ORGANIZATIONS_RESPONSE = 'GET_ORGANIZATIONS_RESPONSE',
  UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST',
  UPDATE_ORGANIZATION_RESPONSE = 'UPDATE_ORGANIZATION_RESPONSE',
  UPDATE_ALL_ORGANIZATIONS_STATUS_REQUEST = 'UPDATE_ALL_ORGANIZATIONS_STATUS_REQUEST',
  UPDATE_ALL_ORGANIZATIONS_STATUS_RESPONSE = 'UPDATE_ALL_ORGANIZATIONS_STATUS_RESPONSE',
  PATCH_ORGANIZATION_REQUEST = 'PATCH_ORGANIZATION_REQUEST',
  PATCH_ORGANIZATION_RESPONSE = 'PATCH_ORGANIZATION_RESPONSE',
  DELETE_ORGANIZATION_REQUEST = 'DELETE_ORGANIZATION_REQUEST',
  DELETE_ORGANIZATION_RESPONSE = 'DELETE_ORGANIZATION_RESPONSE',
  REMOVE_PRESCRIPTION_SOFTWARE_REQUEST = 'REMOVE_PRESCRIPTION_SOFTWARE_REQUEST',
  REMOVE_PRESCRIPTION_SOFTWARE_RESPONSE = 'REMOVE_PRESCRIPTION_SOFTWARE_RESPONSE',
}

export default Types;
