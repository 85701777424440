import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DrugForce } from '../types';
import '../style.scss';

interface DrugForceFieldsProps {
  setFieldValue: (fieldName: string, value: string) => void;
  handleInputChange: (e: any, setFieldValue: (fieldName: string, value: string) => void) => void;
  value: string;
}

const DrugForceFields = ({ handleInputChange, setFieldValue, value }: DrugForceFieldsProps) => {
  const [hasSecondForce, setHasSecondForce] = useState<boolean>(false);
  const [firstInvalid, setFirstInvalid] = useState<boolean>(false);
  const [secondInvalid, setSecondInvalid] = useState<boolean>(false);
  const firstForce = useRef<HTMLInputElement>({} as HTMLInputElement);
  const firstForceUnit = useRef<HTMLSelectElement>({} as HTMLSelectElement);
  const secondForce = useRef<HTMLInputElement>({} as HTMLInputElement);
  const secondForceUnit = useRef<HTMLSelectElement>({} as HTMLSelectElement);

  const handleChange = () => {
    setFirstInvalid(false);
    setSecondInvalid(false);
    const regex = /^[0-9.,]+$/;

    if (firstForce?.current?.value && !regex.test(firstForce.current.value)) {
      setFirstInvalid(true);
    }

    if (hasSecondForce && secondForce?.current?.value && !regex.test(secondForce.current.value)) {
      setSecondInvalid(true);
    }

    if (firstInvalid || secondInvalid) {
      return;
    }

    const firstForceCombined = `${firstForce?.current?.value}${firstForceUnit?.current?.value}`;
    const secondForceCombined = hasSecondForce
      ? ` / ${secondForce?.current?.value}${secondForceUnit?.current?.value}`
      : '';

    handleInputChange(`${firstForceCombined}${secondForceCombined}`, setFieldValue);
  };

  const handleDeleteSecondForce = () => {
    setHasSecondForce(false);
    if (secondForce.current && secondForceUnit.current) {
      secondForce.current.value = '';
      secondForceUnit.current.value = '';
      handleChange();
    }
    handleChange();
  };

  useEffect(() => {
    if (value) {
      const [first, second] = value.split(' / ');

      if (first) {
        const matchFirst = first.match(/^([0-9.,]+)?\s*([a-zA-Z]+)?$/);

        const firstForceValue = matchFirst && matchFirst[1] ? matchFirst[1] : '';
        const firstForceUnitValue = matchFirst && matchFirst[2] ? matchFirst[2] : '';

        if (firstForce.current && firstForceUnit.current) {
          firstForce.current.value = firstForceValue;
          firstForceUnit.current.value = firstForceUnitValue;
        }
      }

      if (second) {
        const matchSecond = second.match(/^([0-9.,]+)?\s*([a-zA-Z]+)?$/);

        const secondForceValue = matchSecond && matchSecond[1] ? matchSecond[1] : '';
        const secondForceUnitValue = matchSecond && matchSecond[2] ? matchSecond[2] : '';

        if (secondForce.current && secondForceUnit.current) {
          secondForce.current.value = secondForceValue;
          secondForceUnit.current.value = secondForceUnitValue;
          setHasSecondForce(true);
        }
      }
    }
  }, [value]);

  return (
    <div className="drug-force-field">
      <Form.Group>
        <Form.Label>{t('strength')}</Form.Label>
        <div className="force-field-row">
          <div className="force-field-input">
            <Form.Control
              onChange={handleChange}
              id="firstForce"
              type="text"
              ref={firstForce}
              isInvalid={firstInvalid}
              value={firstForce.current?.value}
            />
            <Form.Control.Feedback type="invalid">{t('invalidForceField')}</Form.Control.Feedback>
          </div>
          <div className="force-field-unit">
            <Form.Control
              onChange={handleChange}
              as="select"
              name="firstForceUnit"
              ref={firstForceUnit}
              value={firstForceUnit.current?.value}
            >
              {DrugForce.map((form) => (
                <option key={form} value={form}>
                  {form}
                </option>
              ))}
            </Form.Control>

            {!hasSecondForce && (
              <Button onClick={() => setHasSecondForce(true)}>
                <FontAwesomeIcon icon="plus" />
              </Button>
            )}
          </div>
        </div>

        {hasSecondForce && (
          <div className="force-field-row">
            <div className="force-field-input">
              <Form.Control
                onChange={handleChange}
                id="secondForce"
                type="text"
                ref={secondForce}
                isInvalid={secondInvalid}
                value={secondForce.current?.value}
              />
              <Form.Control.Feedback type="invalid">{t('invalidForceField')}</Form.Control.Feedback>
            </div>
            <div className="force-field-unit">
              <Form.Control
                onChange={handleChange}
                as="select"
                name="secondForceUnit"
                ref={secondForceUnit}
                value={secondForceUnit.current?.value}
              >
                {DrugForce.map((form) => (
                  <option key={form} value={form}>
                    {form}
                  </option>
                ))}
              </Form.Control>
              <Button onClick={() => handleDeleteSecondForce()}>
                <FontAwesomeIcon icon="minus" />
              </Button>
            </div>
          </div>
        )}
      </Form.Group>
    </div>
  );
};

export default DrugForceFields;
