import isEmpty from 'lodash.isempty';
import Route from 'route-parser';
import { LoginExpirationMonitor } from 'utils/telemetry';
import { Store } from 'redux';
import store from './store';
import { newExpirationTime } from './login/actions';

export interface ErrorResponse {
  error: {
    message: string;
    fields: Record<string, string>;
    status: number;
  };
}

export class HttpResponseError extends Error {
  status: string;

  code: number;

  response: Response;

  constructor(response: Response) {
    super(`HTTP Error ${response.statusText}`);
    this.status = response.statusText;
    this.response = response;
    this.code = response.status;
  }
}

const checkStatus = async (response: Response, store: Store): Promise<any> => {
  if (response.ok) {
    const newExpiration = response.headers.get('X-LoginExpiration');
    if (newExpiration) {
      store.dispatch(newExpirationTime(newExpiration));
    }
    LoginExpirationMonitor(newExpiration);
    return response.json();
  }

  throw new HttpResponseError(response);
};

const hasDefinedValue = (value) => typeof value !== 'undefined' && value !== null;

const dataAsQueryString = (data: Record<string, string | number>) => {
  const query = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    if (hasDefinedValue(value)) {
      if (value.toString() === '[object Object]') {
        query.append(key, JSON.stringify(value));
      } else {
        query.append(key, value.toString());
      }
    }
  });
  const queryStr = query.toString();
  return queryStr.length > 0 ? `?${queryStr}` : '';
};

const dataAsBodyPayload = (data) => {
  const payload = {};
  Object.entries(data).forEach(([key, value]) => {
    if (hasDefinedValue(value)) {
      payload[key] = value;
    }
  });

  if (isEmpty(payload)) {
    return JSON.stringify(payload);
  }
  return JSON.stringify(data);
};

const perform = async (endpoint, ressources) => {
  ressources = ressources || {};
  return fetch(`/api/v1${endpoint}`, {
    ...ressources,
    headers: {
      ...ressources.headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((r) => checkStatus(r, store));
};

const get = async (endpoint, params = {}, query: Record<string, string | number> = {}) => {
  const ressources = {
    method: 'GET',
  };
  const route = new Route(endpoint);
  return perform(route.reverse(params) + dataAsQueryString(query), ressources);
};

const post = async (endpoint, params = {}, body = {}) => {
  const ressources = {
    method: 'POST',
    headers: {},
    body: dataAsBodyPayload(body),
    credentials: 'include',
  };
  const route = new Route(endpoint);
  return perform(route.reverse(params), ressources);
};

const put = async (endpoint, params = {}, body = {}) => {
  const ressources = {
    method: 'PUT',
    headers: {},
    body: dataAsBodyPayload(body),
  };
  const route = new Route(endpoint);
  return perform(route.reverse(params), ressources);
};

const patch = async (endpoint, params = {}, body = {}) => {
  const ressources = {
    method: 'PATCH',
    headers: {},
    body: dataAsBodyPayload(body),
  };
  const route = new Route(endpoint);
  return perform(route.reverse(params), ressources);
};

const del = async (endpoint, params = {}, query = {}) => {
  const ressources = {
    method: 'DELETE',
  };
  const route = new Route(endpoint);
  return perform(route.reverse(params) + dataAsQueryString(query), ressources);
};

export { get, post, put, patch, del };

export const __tests__ = {
  checkStatus,
};
