import { Reducer, combineReducers } from 'redux';
import buckets from './buckets';
import drugs from './drugs';
import { DrugReducerState } from './drugs/reducers';
import login from './login';
import machines from './machines';
import organizations from './organizations';
import pendingRequests from './pendingRequests';
import { PendingRequestReducerState } from './pendingRequests/reducers';
import settings from './settings';
import users from './users';
import { BucketReducerState } from './buckets/reducers';
import { OrganizationReducerState } from './organizations/reducers';
import { MachineReducerState } from './machines/reducers';
import { SettingsReducerState } from './settings/reducers';
import { LoginState } from './login/reducers';
import { UserReducerState } from './users/reducers';

export interface ApplicationState {
  buckets: BucketReducerState;
  drugs: DrugReducerState;
  login: LoginState;
  organizations: OrganizationReducerState;
  machines: MachineReducerState;
  pendingRequests: PendingRequestReducerState;
  settings: SettingsReducerState;
  users: UserReducerState;
}

const reducers: Reducer<ApplicationState> = combineReducers({
  buckets,
  drugs,
  login,
  organizations,
  machines,
  pendingRequests,
  settings,
  users,
}) as any;

export default reducers;
