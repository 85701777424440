import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import OrganizationsTable from 'components/organizations/OrganizationsTable';
import { RootState } from 'services/store';
import {
  fetchOrganizations,
  updateAllOrganizationsStatus,
} from 'services/organizations/operations';
import { fetchUsers } from 'services/users/operations';
import { fetchMachines } from 'services/machines/operations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import OrganizationFormModal from 'components/organizations/OrganizationFormModal';
import { OrganizationStatus } from 'components/organizations/types';
import { LoadingButton } from 'components/shared/LoadingButton';

const OrganizationsPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const organizations = useSelector((state: RootState) => state.organizations);
  const users = useSelector((state: RootState) => state.users);
  const machines = useSelector((state: RootState) => state.machines);
  const user = useSelector((state: RootState) => state.login);
  const [showOrganizationForm, setShowOrganizationForm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const allUnderMaintenance =
    organizations.filter((o) => o.status !== OrganizationStatus.UNDER_MAINTENANCE).length === 0;

  const handleStatusChange = async () => {
    setIsLoading(true);
    try {
      await updateAllOrganizationsStatus(
        allUnderMaintenance ? OrganizationStatus.ACTIVE : OrganizationStatus.UNDER_MAINTENANCE,
      )(dispatch);
    } catch (err) {
      console.error('ERROR UPDATING ORGANIZATIONS STATUS', err);
    }
    setIsLoading(false);
  };

  const getPage = () => (
    <>
      <h2>{t('organizations')}</h2>
      <div style={{ float: 'right' }}>
        <LoadingButton
          variant={allUnderMaintenance ? 'danger' : 'outline-danger'}
          loading={isLoading}
          onClick={handleStatusChange}
        >
          <FontAwesomeIcon icon="wrench" /> {allUnderMaintenance ? t('complete') : t('start')}
        </LoadingButton>{' '}
        {user?.permissions?.includes('organizations.create') && (
          <Button variant="outline-secondary" onClick={() => setShowOrganizationForm(true)}>
            <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>
      <OrganizationsTable />
      <OrganizationFormModal
        show={showOrganizationForm}
        close={() => setShowOrganizationForm(false)}
      />
    </>
  );

  return (
    <ResourcesHandler
      resources={[organizations, users, machines]}
      resourceFetchers={[
        () => dispatch(fetchOrganizations()),
        () => dispatch(fetchUsers()),
        () => dispatch(fetchMachines()),
      ]}
      getContents={getPage}
    />
  );
};

export default OrganizationsPage;
