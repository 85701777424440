import React from 'react';
import { t } from 'i18next';
import { Alert, Button } from 'react-bootstrap';
import { Order } from 'components/orders/types';
import './style.scss';

interface AlreadyLinkedAlertProps {
  linkedOrder: Order;
  nextAction: () => void;
}

const AlreadyLinkedAlert = ({ linkedOrder, nextAction }: AlreadyLinkedAlertProps) => {
  const trayQty = linkedOrder.trayIds.length;

  return (
    <Alert className="already-linked-alert" variant="warning">
      <div className="already-linked-text">
        <span>{t('alreadyLinked')}</span>
        {trayQty === 2 && (
          <p>
            {t('linkedOrderOneTray', {
              order: linkedOrder.lookupId,
              patient: linkedOrder.patient,
            })}
          </p>
        )}
        {trayQty === 1 && (
          <p>
            {t('linkedOrderNoTray', {
              order: linkedOrder.lookupId,
              patient: linkedOrder.patient,
            })}
          </p>
        )}{' '}
      </div>{' '}
      <Button variant="warning" onClick={nextAction}>
        {t('unlinkFromTrayAndContinue')}
      </Button>
    </Alert>
  );
};

export default AlreadyLinkedAlert;
