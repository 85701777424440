import { OrderType } from 'components/orders/types';

const buckets = () => '/buckets';
const drugs = () => '/drugs';
const login = () => '/login';
const home = () => '/home';
const orders = (status?: OrderType) => `/orders${status ? `?status=${status}` : ''}`;
const organizations = () => '/organizations';
const settings = () => '/settings';
const users = () => '/users';
const verifyAccount = () => '/users/:id/verify';
const dashboard = () => '/dashboard';
const report = () => '/report';
const swaggerDoc = () => '/swaggerDoc';

const paths = {
  buckets: buckets(),
  drugs: drugs(),
  login: login(),
  home: home(),
  orders: orders(':status?' as OrderType),
  organizations: organizations(),
  settings: settings(),
  users: users(),
  verifyAccount: verifyAccount(),
  dashboard: dashboard(),
  report: report(),
  swaggerDoc: swaggerDoc(),
};

const allPaths = {
  buckets,
  drugs,
  login,
  home,
  orders,
  organizations,
  settings,
  users,
  verifyAccount,
  dashboard,
  report,
  swaggerDoc,
  paths,
};

export default allPaths;
