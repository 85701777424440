import { LoginAction, LoginRequestPayload, LoginResponsePayload } from './reducers';
import types from './types';

export const loginRequest = (request: LoginRequestPayload): LoginAction => ({
  type: types.LOGIN_REQUEST,
  payload: request,
});

export const loginResponse = (response: LoginResponsePayload): LoginAction => ({
  type: types.LOGIN_RESPONSE,
  payload: response,
});

export const logoutRequest = (): LoginAction => ({
  type: types.LOGOUT_REQUEST,
  payload: undefined,
});

export const logoutResponse = (response): LoginAction => ({
  type: types.LOGOUT_RESPONSE,
  payload: response,
});

export const newExpirationTime = (response: string): LoginAction => ({
  type: types.NEW_EXPIRATION_TIME,
  payload: response,
});

export const verify2FARequest = (data): LoginAction => ({
  type: types.VERIFY_2FA_REQUEST,
  payload: data,
});

export const verify2FAResponse = (response): LoginAction => ({
  type: types.VERIFY_2FA_RESPONSE,
  payload: response,
});

export const enable2FARequest = (userId: string): LoginAction => ({
  type: types.ENABLE_2FA_REQUEST,
  payload: userId,
});

export const enable2FAResponse = (response): LoginAction => ({
  type: types.ENABLE_2FA_RESPONSE,
  payload: response,
});

export const refresh2FARequest = (data): LoginAction => ({
  type: types.REFRESH_2FA_REQUEST,
  payload: data,
});

export const refresh2FAResponse = (response): LoginAction => ({
  type: types.LOGIN_RESPONSE,
  payload: response,
});
