import React, { ReactNode } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import './Modal.scss';

interface FormModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: () => void;
  isSubmitting: boolean;
  title: string;
  children: ReactNode;
  modalClass?: string;
  disabledSubmitButton?: boolean;
  submitButtonText?: string;
}

const FormModal = ({
  show,
  onHide,
  onSubmit,
  isSubmitting,
  title,
  children,
  modalClass = '',
  disabledSubmitButton,
  submitButtonText,
}: FormModalProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal show={show} onHide={onHide} centered dialogClassName={modalClass}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          {onSubmit && (
            <Button
              variant="primary"
              onClick={onSubmit}
              disabled={isSubmitting || disabledSubmitButton}
            >
              {submitButtonText ?? t('submit')}
            </Button>
          )}
          <Button variant="secondary" onClick={onHide}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FormModal;
