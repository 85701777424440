import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { changeDrug } from 'services/orders/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drug } from 'components/drugs/types';
import './altDrugModal.style.scss';
import { Order } from 'components/orders/types';

interface AltDrugModalProps {
  show: boolean;
  drugsData: { currentDrug: Drug; alternativeDrug: Drug | undefined };
  order: Order;
  handleClose: () => void;
}

function AltDrugModal({ show, drugsData, order, handleClose }: AltDrugModalProps) {
  const [chosenAction, setChosenAction] = useState('noChange');
  const { t } = useTranslation();
  const choicesAlt = [
    { name: t('editOrder'), value: 'changeDrug' },
    { name: t('doNotEdit'), value: 'noChange' },
  ];

  const handleChoice = async () => {
    if (chosenAction === 'changeDrug') {
      handleClose();
      changeDrug(order.id, drugsData.currentDrug.DIN, drugsData.alternativeDrug!.DIN);
    } else {
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('genericAvailable')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          <span className="alt-informations">
            {t('altDrugInfos', {
              altName: drugsData ? drugsData.alternativeDrug?.name.toUpperCase() : 'UNKNOWN',
              altDIN: drugsData ? drugsData.alternativeDrug?.DIN : '-',
            })}{' '}
          </span>
          {t('currentDrugInfos', {
            currentName: drugsData ? drugsData.currentDrug.name.toUpperCase() : 'UNKNOWN',
            currentDIN: drugsData ? drugsData.currentDrug.DIN : '-',
          })}
        </p>
        <hr />
        <p>
          {t('altModalOrderInfo', {
            patient: order.patient,
            createdAt: DateTime.fromISO(order.createdAt).toFormat('yyyy-MM-dd'),
          })}
          <br />
        </p>
        {chosenAction === 'changeDrug' && (
          <Alert variant="warning">
            {t('definitiveOperation')}
            <br />
            <b>
              {' '}
              <FontAwesomeIcon icon="pen" style={{ marginRight: 15 }} />
              {t('writeDrugChanged')}
            </b>
          </Alert>
        )}
        <div className="text-center altChoices">
          <ButtonGroup>
            {choicesAlt.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                name="radio"
                variant="outline-primary"
                value={radio.value}
                checked={chosenAction === radio.value}
                onChange={(e) => setChosenAction(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleChoice}>
          {t('confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AltDrugModal;
