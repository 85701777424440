import React, { useEffect, useState } from 'react';
import './style.scss';
import { DrugDistribution } from 'components/orders/types';
import { getDailyDistribution } from 'components/orders/helpers';

interface BlisterCardRowProps {
  DIN: string;
  distribution: DrugDistribution;
  day: number;
}
const BlisterCardRow = ({ DIN, distribution, day }: BlisterCardRowProps) => {
  const matrix = getDailyDistribution(distribution.cardMap, day);

  return (
    <div className="blister-card-row">
      {' '}
      {matrix.map((qty, i) => (
        <BlisterCardCell key={`card-${i}-${DIN}`} drugQty={qty} />
      ))}
    </div>
  );
};

interface BlisterCardCellProps {
  drugQty: string;
}

const BlisterCardCell = ({ drugQty }: BlisterCardCellProps) => {
  const [classQty, setClassQty] = useState<string>('noQty');

  useEffect(() => {
    if (drugQty === '0') {
      setClassQty('noQty');
    } else if (isPartialQuantity(drugQty)) {
      switch (drugQty) {
        case '0.25':
          setClassQty('quarter');
          break;
        case '0.5':
          setClassQty('half');
          break;
        case '0.75':
          setClassQty('three-quarter');
          break;
        case '':
          setClassQty('noQty');
          break;
        default:
          setClassQty('other');
      }
    } else if (isSpecialQuantity(drugQty)) {
      setClassQty('x-distribute');
    } else {
      setClassQty('whole');
    }
  }, [drugQty, setClassQty]);

  return drugQty === '' ? (
    <span className="noCard">&nbsp;</span>
  ) : (
    <span className={classQty}>{drugQty}</span>
  );
};

function isPartialQuantity(quantity: string) {
  return quantity.endsWith('.5') || quantity.endsWith('.25') || quantity.endsWith('.75');
}

function isSpecialQuantity(quantity: string) {
  const specialQuantity = /[()\-X~]/;
  return specialQuantity.test(quantity);
}
export default BlisterCardRow;
