import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RootState } from 'services/store';
import OrganizationUsersTable from './OrganizationUsersTable';
import OrganizationMachinesTable from './OrganizationMachinesTable';
import AddMachineForm from './AddMachineForm';
import AddUserForm from './AddUserForm';
import { Organization } from '../types';

interface OrganizationDetailsModalProps {
  show: boolean;
  close: () => void;
  organization: Organization;
}

const OrganizationDetailsModal = ({ show, close, organization }: OrganizationDetailsModalProps) => {
  const { t } = useTranslation();
  const [key, setKey] = useState<string>('machines');
  const machines = useSelector((state: RootState) => state.machines);
  const users = useSelector((state: RootState) => state.users);

  return (
    <Modal dialogClassName="organization-details-modal" show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{organization.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs id="organization-details-tabs" activeKey={key} onSelect={(k) => k && setKey(k)}>
          <Tab eventKey="machines" title={t('machines')}>
            <AddMachineForm organization={organization} />
            <OrganizationMachinesTable organizationId={organization.id} machines={machines} />
          </Tab>
          <Tab eventKey="users" title={t('users')}>
            <AddUserForm organizationId={organization.id} />
            <OrganizationUsersTable organizationId={organization.id} users={users} />
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};
export default OrganizationDetailsModal;
