import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { DrugOrder, Order } from '../types';
import BlisterCard from './BlisterCards/DistributionPanel';
import ExcludedRow from './drugRows/ExcludedRow';
import IncludedRow from './drugRows/IncludedRow';
import './style.scss';

interface ExpandedOrderProps {
  order: Order;
}

const activeRowKey = 'ExpandedOrder:activeRow';
const fullListKey = 'ExpandedOrder:fullList';

export type ActiveRow = {
  DIN: string;
  excluded: boolean;
};

const ExpandedOrder = ({ order }: ExpandedOrderProps) => {
  const [activeRow, setActiveRow] = useState<ActiveRow>({ DIN: '', excluded: true });
  const [validUPC, setValidUPC] = useState<boolean>(false);
  const [seeFullList, setSeeFullList] = useState<boolean>(false);

  const includedDrugs: DrugOrder[] = order.drugs.filter((drug) =>
    drug.distributions.some((dist) => !dist.isExcluded),
  );
  const excludedDrugs: DrugOrder[] = order.drugs.filter((drug) =>
    drug.distributions.some((dist) => dist.isExcluded),
  );

  const findDrugAndFilterDistributions = () => {
    const drug = order.drugs.find((d) => d.DIN === activeRow.DIN);
    if (!drug) {
      throw new Error(`Drug with DIN ${activeRow.DIN} not found in order.`);
    }

    const filteredDistributions = drug.distributions.filter(
      (d) => d.isExcluded === activeRow.excluded,
    );

    return {
      ...drug,
      distributions: filteredDistributions,
    };
  };

  const closeActiveRow = () => {
    localStorage.removeItem(activeRowKey);
    localStorage.removeItem(fullListKey);
    setActiveRow({ DIN: '', excluded: true });
    setSeeFullList(false);
    setValidUPC(false);
  };

  useEffect(() => {
    const activeRowLS = localStorage.getItem(activeRowKey);
    const fullListLS = localStorage.getItem(fullListKey);

    if (activeRowLS && order.drugs.find((d) => d.DIN === activeRowLS)) {
      localStorage.setItem(activeRowKey, '');
      setActiveRow({ DIN: activeRowLS, excluded: true });
    }
    if (fullListLS === order.id) {
      localStorage.removeItem(fullListKey);
      setSeeFullList(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    const fullListLS = localStorage.getItem(fullListKey);
    return () => {
      if (activeRow) {
        localStorage.setItem(activeRowKey, activeRow.DIN);
      }
      if (seeFullList) {
        localStorage.setItem(fullListKey, order.id);
      }
      if (!seeFullList && fullListLS === order.id) {
        localStorage.removeItem(fullListKey);
      }
    };
  }, [activeRow, seeFullList, order.id]);

  return (
    <div className="expanded-order">
      <div className="drugs-lists">
        <div className="excluded-list">
          <div className="title-Row">
            <span className="completed-cell" />
            <span className="split-cell" />
            <span className="drugName-cell">{t('drug')}</span>
            <span className="drugForce-cell">{t('strength')}</span>
          </div>
          {excludedDrugs.map((exD) => (
            <ExcludedRow
              key={`excluded-${exD.DIN}`}
              activeRow={activeRow}
              setActiveRow={setActiveRow}
              order={order}
              drugOrder={exD}
              listNum={excludedDrugs.indexOf(exD) + 1}
              splitted={includedDrugs.includes(exD)}
              close={closeActiveRow}
              setValidUPC={setValidUPC}
            />
          ))}
        </div>
        <div className="included-list">
          {seeFullList && (
            <>
              <hr />
              <div className="title-Row">
                <span className="completed-cell" />
                <span className="split-cell" />
                <span className="drugName-cell">{t('drug')}</span>
                <span className="drugForce-cell">{t('strength')}</span>
              </div>
              {includedDrugs.map((inD) => (
                <IncludedRow
                  key={`included-${inD.DIN}`}
                  listNum={excludedDrugs.length + includedDrugs.indexOf(inD) + 1}
                  order={order}
                  drugOrder={inD}
                  splitted={excludedDrugs.includes(inD)}
                  setActiveRow={setActiveRow}
                  activeRow={activeRow}
                />
              ))}
            </>
          )}
        </div>
        <Button variant="outline-secondary" onClick={() => setSeeFullList(!seeFullList)}>
          {seeFullList ? <FontAwesomeIcon icon="eye-slash" /> : <FontAwesomeIcon icon="eye" />}{' '}
          {t('machineDistribution')} ({includedDrugs.length})
        </Button>
      </div>

      {activeRow.DIN && (
        <BlisterCard
          orderId={order.id}
          drugOrder={findDrugAndFilterDistributions()}
          barcodes={order.barcodes}
          status={order.status}
          validUPC={validUPC}
        />
      )}
    </div>
  );
};

export default ExpandedOrder;
