import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import {
  SemanticResourceAttributes,
  SemanticAttributes,
} from '@opentelemetry/semantic-conventions';
import { Span, SpanAttributes } from '@opentelemetry/api';

const traceCollectorOptions = {
  url: '/otel/v1/traces',
  headers: {},
};

let provider: WebTracerProvider | null = null;

export function initializeTelemetry(): void {
  provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'chronomed-client',
    }),
  });
  provider.addSpanProcessor(
    new BatchSpanProcessor(new OTLPTraceExporter(traceCollectorOptions), {
      // The maximum queue size. After the size is reached spans are dropped.
      maxQueueSize: 100,
      // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
      maxExportBatchSize: 10,
      // The interval between two consecutive exports
      scheduledDelayMillis: 500,
      // How long the export can run before it is cancelled
      exportTimeoutMillis: 30000,
    }),
  );

  provider.register();

  // Registering instrumentations / plugins
  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new FetchInstrumentation({
        applyCustomAttributesOnSpan: enhanceXhr,
      }),
      new XMLHttpRequestInstrumentation({
        applyCustomAttributesOnSpan: enhanceXhr,
      }),
    ],
    tracerProvider: provider,
  });

  window.addEventListener('error', onError);
  window.addEventListener('unhandledrejection', onUnhandledRejection);
}

let currentUserName = '';

function enhanceXhr(span: Span): void {
  if (currentUserName) {
    span.setAttribute('user.name', currentUserName);
  }
}

export function trackPageView(): void {
  if (!provider) {
    return;
  }

  const tracer = provider.getTracer('chronomed-client');
  const span = tracer.startSpan('page-view');
  span.setAttribute(SemanticAttributes.HTTP_HOST, document.location.host);
  span.setAttribute(SemanticAttributes.HTTP_METHOD, 'GET');
  span.setAttribute(SemanticAttributes.HTTP_STATUS_CODE, 200);
  span.setAttribute(SemanticAttributes.HTTP_USER_AGENT, navigator.userAgent);
  span.setAttribute('http.path', document.location.pathname + document.location.search);
  span.setAttribute(SemanticAttributes.HTTP_URL, document.location.href);
  span.setAttribute('http.headers.spa_url', document.location.href);
  if (currentUserName) {
    span.setAttribute('user.name', currentUserName);
  }

  span.end();
}

export function logTelemetryUserIn(userName: string): void {
  currentUserName = userName;
}

export function onError(evt: ErrorEvent): void {
  if (!provider) {
    return;
  }

  const errorMessage = evt.error.message;

  // Error in library that we can't avoid, but don't want to log...
  // Input.js?6340:78 Uncaught TypeError: Cannot read property 'style' of undefined
  // at Input._this.handleAutoresize (VM36082 Input.js:78)
  if (
    errorMessage.includes('TypeError') &&
    errorMessage.includes('style') &&
    errorMessage.includes('undefined')
  ) {
    return;
  }

  logError(errorMessage, evt.error.stack);
}

function onUnhandledRejection(evt: PromiseRejectionEvent): void {
  if (!provider) {
    return;
  }

  let errorMessage = 'Unhandled promise rejection';
  let state: SpanAttributes | undefined;

  if (typeof evt.reason === 'object') {
    errorMessage = evt.reason.message || errorMessage;
    state = evt.reason;
  } else if (typeof evt.reason === 'string') {
    errorMessage = evt.reason;
  }

  logError(errorMessage, undefined, state);
}

export function logError(errorMessage: string, stacktrace?: string, state?: SpanAttributes): void {
  if (!provider) {
    return;
  }

  const tracer = provider.getTracer('chronomed-client');
  const span = tracer.startSpan('page-error');
  span.setAttribute(SemanticAttributes.HTTP_HOST, document.location.host);
  span.setAttribute('http.path', document.location.pathname + document.location.search);
  span.setAttribute(SemanticAttributes.HTTP_URL, document.location.href);
  span.setAttribute(SemanticAttributes.HTTP_USER_AGENT, navigator.userAgent);
  span.setAttribute('http.headers.spa_url', document.location.href);
  span.setAttribute('error', true);

  const exceptionTags: SpanAttributes = {
    message: errorMessage,
    'exception.message': errorMessage,
    'exception.stacktrace': stacktrace,
    'exception.type': 'js.Error',
    'log.type': 'error',
  };
  if (currentUserName) {
    exceptionTags['user.name'] = currentUserName;
  }

  if (state) {
    Object.entries(state).map(([key, value]) => (exceptionTags[`log.state.${key}`] = value));
  }

  span.addEvent('exception', exceptionTags);
  span.end();
}

export function LoginExpirationMonitor(expTime: string | null) {
  if (!provider) {
    return;
  }
  const tracer = provider.getTracer('chronomed-client');
  const span = tracer.startSpan('LoginExpiration-Monitor');
  if (!expTime) {
    span.setAttribute('Headers.LoginExpiration', 'Currently not set');
  } else {
    span.setAttribute('Headers.LoginExpiration', expTime);
  }

  span.end();
}
