import React from 'react';
import { t } from 'i18next';
import { Button } from 'react-bootstrap';

const ReportPage = () => (
  <div>
    <h1
      style={{
        marginLeft: 20,
      }}
    >
      {t('reports')}
    </h1>
    <hr />
    <div>
      <h3> {t('report')} 44/30</h3>
      <form>
        <span
          style={{
            marginRight: 20,
          }}
        >
          Foyer:
        </span>
        <select
          style={{
            marginRight: 20,
          }}
          id="foyer"
        >
          <option value="Monday">Lundi</option>
          <option value="Tuesday">Mardi</option>
          <option value="Wednesday">Mercredi</option>
          <option value="Thursday">Jeudi</option>
          <option value="Friday">Vendredi</option>
          <option value="Saturday">Samedi</option>
          <option value="Sunday">Dimanche</option>
        </select>
        <span
          style={{
            marginRight: 20,
          }}
        >
          Période:
        </span>
        <input type="date" id="startDate" name="startDate" />{' '}
        <input
          style={{
            marginRight: 20,
          }}
          type="date"
          id="endDate"
          name="endDate"
        />
        <Button>Générer</Button>
      </form>
    </div>
  </div>
);

export default ReportPage;
