import { OrganizationStatus } from 'components/organizations/types';
import {
  AdminPermissions,
  ChronoTechPermissions,
  SuperTechPermissions,
} from 'components/users/types';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';

export function useCheckPermissions(requiredPermission: string): boolean {
  const loginState = useSelector((state: RootState) => state.login);
  const userPermissions = loginState && loginState.permissions;

  return !!userPermissions?.includes(requiredPermission);
}

export function checkIsAdmin(loginState: RootState['login']) {
  const userPermissions = loginState && loginState.permissions;
  return (
    samePermissions(userPermissions ?? [], AdminPermissions) && loginState.organizationId === '*'
  );
}

export function checkIsChronoTech(loginState: RootState['login']) {
  const userPermissions = loginState && loginState.permissions;
  return samePermissions(userPermissions ?? [], ChronoTechPermissions) && !checkIsAdmin(loginState);
}

export function checkIsSuperTech(loginState: RootState['login']) {
  const userPermissions = loginState && loginState.permissions;
  return (
    samePermissions(userPermissions ?? [], SuperTechPermissions) &&
    !checkIsAdmin(loginState) &&
    !checkIsChronoTech(loginState)
  );
}

export function getOrganizationStatus(
  organizationId: string | undefined,
): OrganizationStatus | undefined {
  const organizations = useSelector((state: RootState) => state.organizations);
  if (organizationId && organizations) {
    return organizations.find((o) => o.id === organizationId)?.status;
  }
  return undefined;
}

function samePermissions(userPermissions: any[], rolePermission: any[]): boolean {
  return rolePermission.every((permission) => userPermissions.includes(permission));
}

export default {
  useCheckPermissions,
  checkIsAdmin,
  checkIsSuperTech,
};
