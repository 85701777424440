import { get, post } from '../api';

interface LoginArgs {
  username?: string | null;
  password?: string | null;
}

const login = ({ username = null, password = null }: LoginArgs) =>
  post('/login', {}, { username, password });

const logout = () => post('/logout', {}, {});

const refreshToken = () => get('/status', {}, {});

const verify2FA = ({
  userId,
  token,
  rememberDevice,
}: {
  userId: string;
  token: string;
  rememberDevice: boolean;
}) => post('/verify2fa', {}, { userId, token, rememberDevice });

const refresh2FA = ({
  userId,
  token,
  rememberDevice,
}: {
  userId: string;
  token: string;
  rememberDevice: boolean;
}) => post('/refresh2fa', {}, { userId, token, rememberDevice });

const enable2FA = ({ userId }: { userId: string }) => post('/enable2fa', {}, { userId });

export { login, logout, verify2FA, enable2FA, refreshToken, refresh2FA };
