import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface ConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  message: JSX.Element | string;
}

function ConfirmationModal({
  show,
  onHide,
  onConfirm,
  message,
}: ConfirmationModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal dialogClassName="confirmation-modal" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{t('confirmation')}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>{message}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          {t('cancel')}
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          {t('confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationModal;
