import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import links from 'utils/links';
import './style.scss';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const Page500 = (): JSX.Element => (
  <div className="error-page">
    <span className="error-icon">
      <FontAwesomeIcon icon="exclamation-triangle" color="grey" />
    </span>
    <p className="error-title">{t('internalServerError')}</p>
    <span className="error-code">{t('error500')}</span>
    <Link to={links.home()}>
      <Button>{t('homePage')}</Button>
    </Link>
  </div>
);

export default Page500;
