import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { ErrorMessage, FieldArray, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { FormValues } from '../DrugFormModal';

interface AlternativesDependencies {
  isUnknownSavedDIN: (DIN: string) => boolean;
  setNextInContextDIN: (DIN: string) => void;
  changeInContextDrugId: (drugId: string | null) => void;
}

interface AlternativesProps {
  handleBlur: FormikProps<FormValues>['handleBlur'];
  setFieldValue: FormikProps<FormValues>['setFieldValue'];
  values: FormikProps<FormValues>['values'];
  dependencies: AlternativesDependencies;
}

function Alternatives({ handleBlur, setFieldValue, values, dependencies }: AlternativesProps) {
  return (
    <Form.Group controlId="alternatives">
      <FieldArray
        name="alternatives"
        render={(arrayHelpers) => (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1%',
              }}
            >
              <Form.Label>{t('alternatives')}</Form.Label>
              <Button onClick={() => arrayHelpers.push('')} variant="outline-secondary" size="sm">
                <FontAwesomeIcon icon="plus" />
              </Button>
            </div>
            {values.alternatives.map((alternative, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '1%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Form.Control
                    type="text"
                    name={`alternatives[${index}]`}
                    value={alternative}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const newAlternatives = [...values.alternatives];
                      newAlternatives[index] = event.target.value;
                      setFieldValue('alternatives', newAlternatives);
                    }}
                  />
                  {dependencies.isUnknownSavedDIN(alternative) && (
                    <Button
                      variant="outline-success"
                      style={{ marginLeft: '1%' }}
                      onClick={() => {
                        dependencies.setNextInContextDIN(alternative);
                        dependencies.changeInContextDrugId(null);
                      }}
                      size="sm"
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Button>
                  )}
                  <Button
                    variant="outline-danger"
                    style={{ marginLeft: '1%' }}
                    onClick={async () => arrayHelpers.remove(index)}
                    size="sm"
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </div>
                {alternative.length === 8 && dependencies.isUnknownSavedDIN(alternative) && (
                  <div style={{ color: 'orange' }}>{t('unknownDrug')}</div>
                )}
                <ErrorMessage
                  name={`alternatives[${index}]`}
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </div>
            ))}
          </div>
        )}
      />
    </Form.Group>
  );
}

export default Alternatives;
