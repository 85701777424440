import { Settings } from 'components/settings/types';
import types from './types';

export type SettingsReducerState = Settings;

export type SettingsAction =
  | { type: types.GET_SETTINGS_REQUEST }
  | { type: types.GET_SETTINGS_RESPONSE; payload: Settings }
  | { type: types.UPDATE_SETTINGS_REQUEST; payload: Settings }
  | { type: types.UPDATE_SETTINGS_RESPONSE; payload: Settings }
  | {
      type: types.PATCH_SETTINGS_REQUEST;
      payload: Partial<Settings>;
    }
  | { type: types.PATCH_SETTINGS_RESPONSE; payload: Settings };

export default function reducers(
  state: SettingsReducerState = null!,
  action: SettingsAction,
): SettingsReducerState {
  switch (action.type) {
    case types.GET_SETTINGS_RESPONSE: {
      return { ...action.payload };
    }
    case types.UPDATE_SETTINGS_RESPONSE:
    case types.PATCH_SETTINGS_REQUEST:
    case types.PATCH_SETTINGS_RESPONSE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
