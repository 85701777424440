import { Dispatch } from 'redux';
import { CreateMachine, Machine } from 'components/machines/types';
import {
  getMachines as APIgetMachines,
  createMachine as APIcreateMachine,
  updateMachine as APIupdateMachine,
  deleteMachine as APIdeleteMachine,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  createMachineRequest,
  createMachineResponse,
  getMachinesRequest,
  getMachinesResponse,
  updateMachineRequest,
  updateMachineResponse,
  deleteMachineRequest,
  deleteMachineResponse,
} from './actions';
import { MachineAction } from './reducers';

type MachineDispatch = Dispatch<MachineAction>;

export function fetchMachines() {
  return fetchOnceOperation(
    getMachinesRequest,
    getMachinesResponse,
    APIgetMachines,
    (state) => state.machines,
    [],
  );
}

export function createMachine(machine: CreateMachine) {
  return (dispatch: MachineDispatch) => {
    dispatch(createMachineRequest(machine));
    return APIcreateMachine(machine).then((res) => dispatch(createMachineResponse(res.machine)));
  };
}

export function updateMachine(id: string, machine: Machine) {
  return (dispatch: MachineDispatch) => {
    dispatch(updateMachineRequest(id, machine));
    return APIupdateMachine(id, machine).then((res) => dispatch(updateMachineResponse({ ...res })));
  };
}

export function deleteMachine(id: string) {
  return (dispatch: MachineDispatch) => {
    dispatch(deleteMachineRequest(id));
    return APIdeleteMachine(id).then((res) => dispatch(deleteMachineResponse(res.machine)));
  };
}

const operations = {
  fetchMachines,
  createMachine,
  updateMachine,
  deleteMachine,
};

export default operations;
