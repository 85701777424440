import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RootState } from 'services/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { checkIsAdmin, checkIsChronoTech } from 'utils/permissions';
import UserRow from './UserRow';
import AdminFormModal from './AdminFormModal';
import UserFormModal from './UserFormModal';
import { UserRole } from './types';
import { getUserRole } from './helpers';

export interface UserTableProps {
  userRoles: UserRole[];
}

function UsersTable({ userRoles }: UserTableProps): JSX.Element {
  const users = useSelector((state: RootState) => state.users);
  const user = useSelector((state: RootState) => state.login);
  const loginState = useSelector((state: RootState) => state.login);
  const isAdmin = checkIsAdmin(loginState) || checkIsChronoTech(loginState);
  const [search, setSearch] = useState<string>('');
  const [invalidSearch, setInvalidSearch] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showAdminForm, setShowAdminForm] = useState<boolean>(false);
  const [showUserForm, setShowUserForm] = useState<boolean>(false);
  const { t } = useTranslation();
  const adminTable: boolean = userRoles.includes(UserRole.ADMIN);
  const techTable: boolean = userRoles.includes(UserRole.TECH);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setInvalidSearch(false);
    const regex = /^[a-zA-Zà-üÀ-Ü\s.,'-]+$/;
    if (regex.test(e.target.value) || !e.target.value) {
      setSearch(e.target.value);
    } else {
      setInvalidSearch(true);
    }
  };

  const handleClose = () => {
    setError(null);
  };

  const rows =
    users &&
    users
      .filter((user) => userRoles.includes(getUserRole(user.permissions)))
      .filter(
        (user) =>
          !search ||
          user.firstName.toLowerCase().includes(search.toLowerCase()) ||
          user.lastName.toLowerCase().includes(search.toLowerCase()),
      )
      .sort((userA, userB) => (userA.lastName < userB.lastName ? -1 : 1))
      .map((user) => (
        <UserRow key={user.id} user={user} isAdmin={isAdmin} showOrganization={isAdmin} />
      ));

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2%' }}>
        <div style={{ position: 'relative', width: '75%' }}>
          <Form.Control
            type="text"
            onChange={handleChangeSearch}
            placeholder={`${t('search')}...`}
            isInvalid={invalidSearch}
          />
          <Form.Control.Feedback type="invalid">{t('invalidSearchQuery')}</Form.Control.Feedback>
          <FontAwesomeIcon
            icon="search"
            color="black"
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              display: 'inline-block',
              fontSize: 20,
              position: 'absolute',
              top: 10,
              right: 12,
              zIndex: 2,
            }}
          />
        </div>
        {adminTable && (
          <Button onClick={() => setShowAdminForm(true)} variant="outline-secondary">
            <FontAwesomeIcon icon="plus" />
          </Button>
        )}
        {techTable && !isAdmin && (
          <Button onClick={() => setShowUserForm(true)} variant="outline-secondary">
            <FontAwesomeIcon icon="plus" />
          </Button>
        )}
      </div>

      <br />
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th>{t('userName')}</th>
            <th>{t('lastName')}</th>
            <th>{t('firstName')}</th>
            <th>{t('emailAddress')}</th>
            {isAdmin && <th>{t('organization')}</th>}
            <th>{t('role')}</th>
            <th>{t('vAssyst')}</th>
            <th>{t('2FA')}</th>
            <th style={{ width: '15%' }}>{t('actions')}</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Modal show={!!error} onHide={handleClose} backdrop="static" centered className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>{t('fillingRequired')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{error}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
      <AdminFormModal show={showAdminForm} close={() => setShowAdminForm(false)} />
      <UserFormModal
        show={showUserForm}
        organizationId={user.organizationId}
        close={() => setShowUserForm(false)}
      />
    </>
  );
}

export default UsersTable;
