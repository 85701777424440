enum Types {
  CREATE_DRUG_REQUEST = 'CREATE_DRUG_REQUEST',
  CREATE_DRUG_RESPONSE = 'CREATE_DRUG_RESPONSE',
  GET_DRUGS_REQUEST = 'GET_DRUGS_REQUEST',
  GET_DRUGS_RESPONSE = 'GET_DRUGS_RESPONSE',
  UPDATE_DRUG_REQUEST = 'UPDATE_DRUG_REQUEST',
  UPDATE_DRUG_RESPONSE = 'UPDATE_DRUG_RESPONSE',
  VALIDATE_DRUG_REQUEST = 'VALIDATE_DRUG_REQUEST',
  VALIDATE_DRUG_RESPONSE = 'VALIDATE_DRUG_RESPONSE',
  ADD_DRUG_UPC_REQUEST = 'ADD_DRUG_UPC_REQUEST',
  ADD_DRUG_UPC_RESPONSE = 'VALIDATE_DRUG_UPC_RESPONSE',
  VALIDATE_DRUG_UPC_REQUEST = 'VALIDATE_DRUG_UPC_REQUEST',
  VALIDATE_DRUG_UPC_RESPONSE = 'VALIDATE_DRUG_UPC_RESPONSE',
  INVALIDATE_DRUG_UPC_REQUEST = 'INVALIDATE_DRUG_UPC_REQUEST',
  INVALIDATE_DRUG_UPC_RESPONSE = 'IN_VALIDATE_UPC_RESPONSE',
  PATCH_DRUG_REQUEST = 'PATCH_DRUG_REQUEST',
  PATCH_DRUG_RESPONSE = 'PATCH_DRUG_RESPONSE',
  DELETE_DRUG_REQUEST = 'DELETE_DRUG_REQUEST',
  DELETE_DRUG_RESPONSE = 'DELETE_DRUG_RESPONSE',
  IMPORT_DRUGLIST_REQUEST = 'IMPORT_DRUGLIST_REQUEST',
  IMPORT_DRUGLIST_RESPONSE = 'IMPORT_DRUGLIST_RESPONSE',
}

export default Types;
