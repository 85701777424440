import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { t } from 'i18next';
import { DrugOrder, DrugOrderStatus } from '../../types';
import './style.scss';
import { ActiveRow } from '../ExpandedOrder';

interface ExcludedDinUnavailableRowProps {
  activeRow: ActiveRow;
  drugOrder: DrugOrder;
  listNum: number;
  close: () => void;
  setActiveRow: (activeRow: ActiveRow) => void;
}

const ExcludedDinUnavailableRow = ({
  activeRow,
  drugOrder,
  listNum,
  close,
  setActiveRow,
}: ExcludedDinUnavailableRowProps) => {
  const isActiveRow = activeRow && activeRow.DIN === drugOrder.DIN;

  const toggleActiveRow = () => {
    if (isActiveRow) {
      close();
    } else {
      setActiveRow({ DIN: drugOrder.DIN, excluded: true });
    }
  };

  return (
    <div className="excluded-row din-unavailable">
      <div className="drug-info">
        <span className="completed-cell">
          <FontAwesomeIcon
            icon={
              drugOrder.distributions.some(
                (distribution) => distribution.status === DrugOrderStatus.DISTRIBUTED,
              )
                ? 'check'
                : 'flag'
            }
            size="1x"
            color="yellow"
          />
        </span>
        <span className="split-cell">{listNum}</span>
        <span className="drugName-cell">
          <Button variant="link" onClick={toggleActiveRow}>
            {t('noDINDrug')}
          </Button>
        </span>

        <span className="drugForce-cell" />
        <span className="flag-cell" />
        <span className="action-cell" />

        <span className="closeActive-cell">
          {isActiveRow && <FontAwesomeIcon onClick={close} icon="times" size="1x" color="grey" />}
        </span>
      </div>

      {isActiveRow && (
        <p className="flag-reason">
          <FontAwesomeIcon icon="flag" color="grey" /> : {t('errorImportDin')}
        </p>
      )}
    </div>
  );
};

export default ExcludedDinUnavailableRow;
