import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { FieldArray, FormikErrors, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { FormValues } from './EditBucketModal';
import { Drug } from '../../drugs/types';

interface UPCsProps {
  errors: FormikErrors<FormValues>;
  handleBlur: FormikProps<FormValues>['handleBlur'];
  setFieldValue: FormikProps<FormValues>['setFieldValue'];
  values: FormikProps<FormValues>['values'];
  drug?: Drug;
}

function UPCs({ errors, handleBlur, setFieldValue, values, drug }: UPCsProps) {
  return (
    <Form.Group controlId="UPCs">
      <FieldArray
        name="UPCs"
        render={(arrayHelpers) => (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1%',
              }}
            >
              <Form.Label>UPCs</Form.Label>
              <Button onClick={() => arrayHelpers.push('')} variant="outline-secondary" size="sm">
                <FontAwesomeIcon icon="plus" />
              </Button>
            </div>
            {values.UPCs?.map((upc, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '1%',
                }}
              >
                <div className="row">
                  <div className="col">
                    <Form.Control
                      type="text"
                      name={`UPCs[${index}]`}
                      value={upc}
                      placeholder={drug?.UPC ? drug.UPC[index] : ''}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        if (values.UPCs) {
                          const UPCS = [...values.UPCs];
                          UPCS[index] = event.target.value;
                          setFieldValue('UPCs', UPCS);
                        }
                      }}
                      isInvalid={!!errors.UPCs && !!errors.UPCs[index]}
                    />
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.UPCs && t(errors.UPCs[index])}
                    </Form.Control.Feedback>
                    {upc.length === 13 && (
                      <Form.Control.Feedback
                        type="valid"
                        className="d-block"
                        style={{ color: 'orange' }}
                      >
                        {t('EANWarning')}
                      </Form.Control.Feedback>
                    )}
                  </div>
                  <div className="col-auto">
                    <Button
                      variant="outline-danger"
                      style={{ marginLeft: '1%', width: 'auto' }}
                      onClick={async () => arrayHelpers.remove(index)}
                      size="sm"
                    >
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      />
    </Form.Group>
  );
}

export default UPCs;
