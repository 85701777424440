import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './components/shared/icons';
import { login as loginFct } from './services/login/endpoints';
import Layout from './components/Layout';
import { initializeSocket } from './services/sockets';

import './App.scss';

const App = () => {
  useEffect(() => {
    loginFct({})
      .then((res) => {
        if (res && res.infos) {
          initializeSocket();
        }
      })
      .catch((err) => {
        console.error('ERROR Logging in, probably expired or missing token.', err);
      });
  }, []);

  return <Layout />;
};

export default App;
