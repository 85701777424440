import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import logger from 'redux-logger';
import reducers, { ApplicationState } from './reducers';

let store = createStore(reducers, applyMiddleware(thunk, promise));
if (typeof process !== 'undefined' && process.env.NODE_ENV !== 'production') {
  store = createStore(reducers, applyMiddleware(thunk, promise, logger));
}

export default store;

export type RootState = ApplicationState;

export type AppDispatch = typeof store.dispatch;
