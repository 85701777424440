import React from 'react';
import { Order } from 'components/orders/types';
import { checkIsAdmin, checkIsChronoTech } from 'utils/permissions';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';

interface PatientCellProps {
  order: Order;
}

const PatientCell = ({ order }: PatientCellProps) => {
  const organizations = useSelector((state: RootState) => state.organizations);
  const patientOrganization = organizations.find((org) => org.id === order.organizationId);
  const loginState = useSelector((state: RootState) => state.login);
  const isAdmin = checkIsAdmin(loginState);
  const isChronoTech = checkIsChronoTech(loginState);

  return (
    <div className="timestamps">
      {order.patient}
      <br />
      {(isAdmin || isChronoTech) && (
        <div style={{ fontSize: '0.9em', opacity: 0.9 }}>
          {patientOrganization ? patientOrganization.name : ''}
        </div>
      )}
    </div>
  );
};

export default PatientCell;
