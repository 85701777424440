import React, { useCallback, useEffect, useState } from 'react';
import { RootState } from 'services/store';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import '../style.scss';
import { getSocket } from 'services/sockets';
import { getOrders } from 'services/orders/endpoints';
import { Order, OrderStatus, OrderType } from '../types';
import { orderColumns } from './OrderColumns';
import SearchBar from './SearchBar';
import ExpandedOrder from '../ExpandedOrder';
import NewOrdersAlert from './NewOrdersAlert';

interface OrdersTableProps {
  status?: OrderType;
}

export interface OrderTableOptions {
  perPage: number;
  page: number;
  sort: string;
  sortDirection: number;
  query: string;
  organizationView: string;
}

const defaultPerPage = 100;

const defaultOptions: OrderTableOptions = {
  page: 1,
  perPage: defaultPerPage,
  sort: 'createdAt',
  sortDirection: -1,
  query: '',
  organizationView: '',
};

function OrdersTable({ status }: OrdersTableProps): JSX.Element {
  const login = useSelector((state: RootState) => state.login);
  const [data, setData] = useState<Order[]>([]);
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [options, setOptions] = useState<OrderTableOptions>(defaultOptions);
  const { t } = useTranslation();

  const handlePerRowsChange = async (newPerPage: number, currentPage: number) => {
    loadOrders({
      ...options,
      page: currentPage,
      perPage: newPerPage,
    });
  };
  const loadOrders = async (newOptions: OrderTableOptions) => {
    if (newOptions === options) {
      return;
    }
    setLoading(true);
    setOptions(newOptions);
    const { orders, totalCount } = await getOrders(status || OrderType.ACTIVE, newOptions);
    updateOrdersList(orders, totalCount);
    setLoading(false);
  };
  const handlePageChange = (page) => {
    handlePerRowsChange(options.perPage, page);
  };

  const handleOrderUpdated = useCallback(
    (order: Order) => {
      const found = data.findIndex((x) => x.id === order.id);
      if (found >= 0) {
        const newData = data.concat();
        if (
          [OrderStatus.ARCHIVED, OrderStatus.CANCELLED].includes(order.status) ||
          (status === OrderType.ARCHIVED &&
            [OrderStatus.PENDING, OrderStatus.INTRAY].includes(order.status))
        ) {
          newData.splice(found, 1);
        } else {
          newData[found] = order;
        }
        setData(newData);
      }
    },
    [data, status],
  );

  useEffect(() => {
    const socket = getSocket();
    socket?.on('orders.updated', handleOrderUpdated);

    return () => {
      socket?.off('orders.updated', handleOrderUpdated);
    };
  }, [handleOrderUpdated]);

  useEffect(() => {
    handlePerRowsChange(options.perPage, options.page);
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateOrdersList = (orders: Order[], totalCount: number) => {
    setData(orders);
    setTotalRows(totalCount);
  };

  const addNewOrders = (newOrders: Order[]) => {
    setData([...newOrders, ...data]);
    setTotalRows(totalRows + newOrders.length);
  };

  const ExpandableComponent = ({ data }) => (
    <ExpandedOrder key={`expanded-${data.id}`} order={data} />
  );

  return (
    <section>
      <SearchBar options={options} setOptions={setOptions} loadOrders={loadOrders} />
      <NewOrdersAlert organizationId={login.organizationId} addNewOrders={addNewOrders} />
      <DataTable
        className="orders-table"
        columns={orderColumns(status)}
        data={data}
        noDataComponent={t('noOrdersToDisplay')}
        expandableRows
        highlightOnHover
        expandableRowsComponent={ExpandableComponent}
        expandOnRowClicked
        responsive
        expandableRowExpanded={(o) => expandedRows[o.id]}
        onRowExpandToggled={(expanded, o) => setExpandedRows({ ...expandedRows, [o.id]: expanded })}
        onRowClicked={(o) => setExpandedRows({ ...expandedRows, [o.id]: !expandedRows[o.id] })}
        progressPending={loading}
        sortServer
        defaultSortFieldId="createdAt"
        defaultSortAsc={false}
        onSort={(column, order) => {
          loadOrders({
            ...options,
            sort: column.id as string,
            sortDirection: (order as string) === 'asc' ? 1 : -1,
          });
        }}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationPerPage={defaultPerPage}
        paginationRowsPerPageOptions={[25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: 'Nombre par page',
        }}
      />
    </section>
  );
}

export default OrdersTable;
