import {
  OrganizationCreateFields,
  Organization,
  OrganizationPublicFields,
  OrganizationStatus,
} from 'components/organizations/types';
import { OrganizationAction } from './reducers';
import types from './types';

export const createOrganizationRequest = (
  organization: OrganizationCreateFields,
): OrganizationAction => ({
  type: types.CREATE_ORGANIZATION_REQUEST,
  payload: { organization },
});

export const createOrganizationResponse = (
  organization: OrganizationPublicFields,
): OrganizationAction => ({
  type: types.CREATE_ORGANIZATION_RESPONSE,
  payload: { organization },
});

export const getOrganizationsRequest = (): OrganizationAction => ({
  type: types.GET_ORGANIZATIONS_REQUEST,
});

export const getOrganizationsResponse = (payload: {
  organizations: OrganizationPublicFields[];
}): OrganizationAction => ({
  type: types.GET_ORGANIZATIONS_RESPONSE,
  payload,
});

export const updateOrganizationRequest = (
  id: string,
  organization: Organization,
): OrganizationAction => ({
  type: types.UPDATE_ORGANIZATION_REQUEST,
  payload: { id, organization },
});

export const updateOrganizationResponse = (
  organization: OrganizationPublicFields,
): OrganizationAction => ({
  type: types.UPDATE_ORGANIZATION_RESPONSE,
  payload: { organization },
});

export const updateAllOrganizationsStatusRequest = (
  status: OrganizationStatus,
): OrganizationAction => ({
  type: types.UPDATE_ALL_ORGANIZATIONS_STATUS_REQUEST,
  payload: { status },
});

export const updateAllOrganizationsStatusResponse = (
  organizations: OrganizationPublicFields[],
): OrganizationAction => ({
  type: types.UPDATE_ALL_ORGANIZATIONS_STATUS_RESPONSE,
  payload: { organizations },
});

export const patchOrganizationRequest = (
  id: string,
  organization: Partial<Organization>,
): OrganizationAction => ({
  type: types.PATCH_ORGANIZATION_REQUEST,
  payload: { id, organization },
});

export const patchOrganizationResponse = (
  organization: OrganizationPublicFields,
): OrganizationAction => ({
  type: types.PATCH_ORGANIZATION_RESPONSE,
  payload: { organization },
});

export const deleteOrganizationRequest = (organizationId: string): OrganizationAction => ({
  type: types.DELETE_ORGANIZATION_REQUEST,
  payload: { organizationId },
});

export const deleteOrganizationResponse = (organization: Organization): OrganizationAction => ({
  type: types.DELETE_ORGANIZATION_RESPONSE,
  payload: { organization },
});
