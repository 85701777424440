import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, MouseEvent } from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}
export function LoadingButton({
  loading,
  onClick,
  children,
  disabled,
  ...props
}: LoadingButtonProps): JSX.Element {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      setShowLoading(true);
      setTimeout(() => {
        setShowLoading(false);
      }, 500);
    }
  }, [loading]);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    if (!loading && onClick) {
      onClick(e);
    }
  };

  return (
    <Button onClick={handleClick} disabled={loading || disabled} {...props}>
      {(loading || showLoading) && (
        <>
          <FontAwesomeIcon icon="sync" size="1x" color="white" className="pendingIcon" />{' '}
        </>
      )}
      {children}
    </Button>
  );
}
