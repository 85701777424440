import React from 'react';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Modal, Form } from 'react-bootstrap';
import { t } from 'i18next';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { LoadingButton } from 'components/shared/LoadingButton';
import { createUser } from 'services/users/operations';
import { NewUser, UserRole } from './types';
import { createUserValidation } from './validation';

interface FormValues {
  userName: string;
  password: string;
  confirmPassword: string;
  lastName: string;
  firstName: string;
  emailAddress: string;
  role: UserRole;
}

interface UserFormModalProps {
  show: boolean;
  organizationId: string | undefined;
  close: () => void;
}

const UserFormModal = ({ show, organizationId, close }: UserFormModalProps) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const initialValues: FormValues = {
    userName: '',
    password: '',
    confirmPassword: '',
    lastName: '',
    firstName: '',
    emailAddress: '',
    role: UserRole.TECH,
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);

    const newUser: NewUser = {
      organizationId: organizationId!,
      userName: values.userName,
      password: values.confirmPassword,
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.emailAddress,
      role: UserRole.TECH,
    };
    try {
      await createUser(newUser)(dispatch);
      close();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED CREATING TECH', setFieldError);
    }

    setSubmitting(false);
  };

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{t('addUser')}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={createUserValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="userName">
                <Form.Label>{t('userName')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.userName && touched.userName ? 'error-text' : ''}
                  type="text"
                  name="userName"
                  value={values.userName}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('userName', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="userName"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group controlId="password">
                <Form.Label>{t('password')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.password && touched.password ? 'error-text' : ''}
                  type="password"
                  name="password"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('password', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="password"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label>{t('confirmPassword')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.confirmPassword && touched.confirmPassword ? 'error-text' : ''}
                  type="password"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('confirmPassword', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="confirmPassword"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>

              <Form.Group controlId="emailAddress">
                <Form.Label>{t('emailAddress')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.emailAddress && touched.emailAddress ? 'error-text' : ''}
                  type="text"
                  name="emailAddress"
                  value={values.emailAddress}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('emailAddress', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="emailAddress"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('lastName')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.lastName && touched.lastName ? 'error-text' : ''}
                  type="text"
                  name="lastName"
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('lastName', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="lastName"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>{t('firstName')}</Form.Label>
                <Form.Control
                  autoComplete="off"
                  className={errors.firstName && touched.firstName ? 'error-text' : ''}
                  type="text"
                  name="firstName"
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={(event) => {
                    setFieldValue('firstName', event.target.value);
                  }}
                />
                <ErrorMessage
                  name="firstName"
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                {t('submit')}
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default UserFormModal;
