import React from 'react';
import { t } from 'i18next';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drug, DrugWarnings } from './types';
import { DrugDependencies } from './DrugsTable';

interface DrugRowProps {
  drug: Drug;
  canUpdate?: boolean;
  canDelete?: boolean;
  dependencies: DrugDependencies;
}

export const notValidatedDrugWarning = (drugId: string) => (
  <span className="flag-cell">
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={drugId}>{t('notValidatedDrug')}</Tooltip>}
      trigger={['hover', 'click']}
    >
      <FontAwesomeIcon icon="exclamation-triangle" color="#ffc107" />
    </OverlayTrigger>
  </span>
);

const DrugRow = ({ drug, canUpdate, canDelete, dependencies }: DrugRowProps): JSX.Element => (
  <tr>
    <td>
      <Button
        variant="link"
        onClick={() => {
          dependencies.setSelectedDrugId(drug.id);
          dependencies.setShowDrugDetails(true);
        }}
      >
        {drug.DIN}
      </Button>
    </td>
    <td>
      {drug.name.toUpperCase()}{' '}
      {drug.warnings?.includes(DrugWarnings.NOT_VALIDATED) && notValidatedDrugWarning(drug.id)}
    </td>
    <td>{drug && drug.force ? drug.force.toUpperCase() : '-'}</td>
    <td>{drug ? drug.format : '-'}</td>
    {(canUpdate || canDelete) && (
      <td>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {canUpdate && (
            <Button
              variant="outline-secondary"
              onClick={() => {
                dependencies.setSelectedDrugId(drug.id);
                dependencies.setShowUpdateDrug(true);
              }}
            >
              <FontAwesomeIcon icon="pen" />
            </Button>
          )}
          {canDelete && (
            <Button
              variant="outline-danger"
              onClick={() => {
                dependencies.setSelectedDrugId(drug.id);
                dependencies.setShowDeleteDrug(true);
              }}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          )}
        </div>
      </td>
    )}
  </tr>
);

export default DrugRow;
