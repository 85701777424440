import React, { useEffect, useState } from 'react';
import {
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import CustomTooltip from '../CustomTooltip';
import '../style.scss';
import { PatientPerDayStats } from '../types';

interface Props {
  patientPerDay: PatientPerDayStats[] | undefined;
  loading: boolean;
}

const PatientPerDay: React.FC<Props> = ({ patientPerDay, loading }): JSX.Element => {
  const { t } = useTranslation();
  const [patientPerDayData, setPatientPerDayData] = useState<PatientPerDayStats[]>([]);
  const [totalValue, setTotalValue] = useState<number | null>(null);
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    if (patientPerDay) {
      setNoData(false);
      const formattedData = patientPerDay.map((item: PatientPerDayStats) => {
        const date = parseISO(item.date);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
        return {
          date: format(localDate, 'd MMM'),
          value: item.value,
        };
      });

      setPatientPerDayData(formattedData);

      const total = formattedData.reduce((sum, item) => sum + item.value, 0);
      setTotalValue(total);
      if (patientPerDay.length === 0) {
        setNoData(true);
      }
    }
  }, [patientPerDay, t]);

  return (
    <div className="componentBase patient-day-container">
      {loading && (
        <div className="spinner">
          <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
        </div>
      )}
      {!loading && (
        <>
          <div className="total-value">
            <span>{totalValue}</span>
          </div>
          <p style={{ fontSize: '14px', fontWeight: 'lighter' }}>{t('PatientForThisMonth')}</p>
          {!noData && (
            <ResponsiveContainer width="100%" height="70%">
              <AreaChart
                width={100}
                margin={{ top: 0, right: 0, left: -40, bottom: 0 }}
                data={patientPerDayData}
              >
                <defs>
                  <linearGradient id="colorValue2" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0071e9" stopOpacity={0.2} />
                    <stop offset="95%" stopColor="#0071e9" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid vertical={false} stroke="#f5f5f5" />

                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickLine={false}
                  domain={['dataMin', 'dataMax']}
                  tick={{ fill: '#999', fontSize: 12 }}
                  interval={1}
                />
                <YAxis axisLine={false} tickLine={false} tick={{ fill: '#999', fontSize: 12 }} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#0071e9"
                  fillOpacity={1}
                  fill="url(#colorValue2)"
                  strokeWidth={2}
                />
                <Tooltip content={CustomTooltip} />
              </AreaChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </div>
  );
};

export default PatientPerDay;
