import {
  OrderFailureStatus,
  CardMap,
  DrugOrder,
  DrugDistribution,
  DrugOrderStatus,
  DrugOrderExcludedReason,
} from './types';

export function failureStatusText(failureStatus: OrderFailureStatus): string {
  switch (failureStatus) {
    case 'BUSY':
      return 'Occupée';
    case 'FAILURE':
      return 'Erreur';
    default:
      return 'Inconnue';
  }
}

export function getDailyDistribution(cardMap: CardMap, day: number) {
  return cardMap.map((time) => time[day]);
}

export const emptyCardMap = (): CardMap => [
  ['', '', '', '', '', '', ''],
  ['', '', '', '', '', '', ''],
  ['', '', '', '', '', '', ''],
  ['', '', '', '', '', '', ''],
];

export const calculateTotalQuantity = (drugOrder: DrugOrder) => {
  const cardQty = (cardMap: CardMap) =>
    cardMap.reduce(
      (sum, row) => sum + row.reduce((rowSum, drugQty) => rowSum + parseInt(drugQty, 10), 0),
      0,
    );

  const quantity = drugOrder.distributions.reduce((sum, distribution) => {
    if (distribution.isExcluded) {
      return 0;
    }
    return sum + cardQty(distribution.cardMap);
  }, 0);
  return quantity;
};

export const isSplitted = (barcode: string, distributions: DrugDistribution[]): boolean =>
  distributions
    .filter((distribution) => distribution.barcode === barcode)
    .some((distribution, _, arr) => arr.some((d) => d.isExcluded !== distribution.isExcluded));

export const someSplittedCards = (barcodes: string[], distributions: DrugDistribution[]): boolean =>
  barcodes.some((barcode) => isSplitted(barcode, distributions));

export const mergeWholeAndPartial = (excludedQty: string, includedQty: string) =>
  excludedQty.replace(/^./, includedQty);

export const isPartialQuantity = (quantity: string) =>
  quantity.endsWith('.5') || quantity.endsWith('.25') || quantity.endsWith('.75');

export const getUnitedPreview = (distributions: DrugDistribution[]): DrugDistribution[] =>
  distributions.reduce((unitedDistributions: DrugDistribution[], distribution) => {
    const splittedDistribution = distributions.find(
      (d) => d.barcode === distribution.barcode && d.isExcluded !== distribution.isExcluded,
    );

    const alreadyMerged = unitedDistributions.some((ud) => ud.barcode === distribution.barcode);

    if (splittedDistribution && !alreadyMerged) {
      unitedDistributions.push({
        ...distribution,
        cardMap: mergedCardMap([distribution.cardMap, splittedDistribution.cardMap]),
        isExcluded: true,
        excludedReason: DrugOrderExcludedReason.BUCKET_DEACTIVATED,
        status: DrugOrderStatus.UNDISTRIBUTED,
      });
    } else if (!alreadyMerged) {
      unitedDistributions.push(distribution);
    }

    return unitedDistributions;
  }, []);

const mergedCardMap = (cardsToMerge: CardMap[]): CardMap => {
  const mergedCard = emptyCardMap();

  mergedCard.forEach((timeSlot, time) => {
    timeSlot.forEach((_, day) => {
      const [qty1, qty2] = cardsToMerge.map((card) => card[time][day]);

      if (isPartialQuantity(qty1) || isPartialQuantity(qty2)) {
        const { partial, whole } = isPartialQuantity(qty1)
          ? { partial: qty1, whole: qty2 }
          : { partial: qty2, whole: qty1 };
        mergedCard[time][day] = mergeWholeAndPartial(partial, whole);
      } else {
        mergedCard[time][day] = qty1 === '0' ? qty2 : qty1;
      }
    });
  });

  return mergedCard;
};
export const parseBarcode = (barcode: string) => (barcode ? barcode.replace(/_\d$/, '') : '');
