import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './TopMenu.style.scss';

interface TopMenuProps {
  onToggle: () => void;
}

export function TopMenu({ onToggle }: TopMenuProps): JSX.Element {
  return (
    <div className="top-menu">
      <button onClick={() => onToggle()}>
        <FontAwesomeIcon icon="bars" />
      </button>
    </div>
  );
}
