import React, { useState } from 'react';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Button, Collapse, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as yup from 'yup';
import { t } from 'i18next';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { LoadingButton } from 'components/shared/LoadingButton';
import { createMachine } from 'services/machines/operations';
import { Organization } from '../types';

const validationSchema = yup.object().shape({
  name: yup.string().required('machineNameRequired'),
});

interface FormValues {
  name: string;
}

interface AddMachineFormProps {
  organization: Organization;
}

const AddMachineForm = ({ organization }: AddMachineFormProps) => {
  const dispatch = useDispatch<Dispatch<any>>();

  const initialValues: FormValues = {
    name: '',
  };

  const [open, setOpen] = useState(false);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    setSubmitting(true);
    try {
      await createMachine({ organizationId: organization.id, name: values.name })(dispatch);
      resetForm();
      setOpen(false);
    } catch (err) {
      handleHttpResponseError(err, 'FAILED UPLOAD MACHINE', setFieldError);
    }
  };

  return (
    <>
      <div className="collapse-button-container">
        <Button
          onClick={() => setOpen(!open)}
          aria-controls="machine-collapse-form"
          aria-expanded={open}
          variant="link"
        >
          {t('addMachine')}{' '}
          {open ? <FontAwesomeIcon icon="angle-up" /> : <FontAwesomeIcon icon="angle-down" />}
        </Button>
      </div>
      <Collapse className="collapse-form" in={open}>
        <div id="machine-collapse-form">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form className="organization-machine-form" onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label>{t('name')}</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    className={errors.name && touched.name ? 'error-text' : ''}
                    type="text"
                    name="name"
                    value={values.name}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      setFieldValue('name', event.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <div className="collapse-submit-button-container">
                  <LoadingButton
                    variant="primary"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {t('submit')}
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Collapse>
    </>
  );
};
export default AddMachineForm;
