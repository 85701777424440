import React, { useEffect, useState } from 'react';
import '../RefillModals/style.scss';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { deactivateBucket, unloadBucket } from 'services/buckets/operations';
import { HttpResponseError } from 'services/api';
import { RootState } from 'services/store';
import { LoadingButton } from 'components/shared/LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drug } from 'components/drugs/types';
import { Bucket, DeactivatedReason } from '../types';
import ExpiredDrugRow from './ExpireDrugRow';
import RefillForm from '../RefillModals/RefillForm';
import LotTable from '../RefillModals/LotTable';

interface BucketModalProps {
  isOpen: boolean;
  bucketsId: string[];
  onClose: () => void;
}

function ExpiredDrugsModal({ isOpen, bucketsId, onClose }: BucketModalProps) {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('refillForm');
  const [bucketUnloading, setBucketUnloading] = useState<string | null>(null);
  const [refillBucketId, setRefillBucketId] = useState<string>('');
  const [handledExpiredIds, setHandledExpiredIds] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [buckets, setBuckets] = useState<Bucket[]>([]);
  const [drugs, setDrugs] = useState<Drug[]>([]);
  const allDrugs = useSelector((state: RootState) => state.drugs);
  const allBuckets = useSelector((state: RootState) => state.buckets);
  const isSingleBucket = buckets.length === 1;

  useEffect(() => {
    if (allBuckets) {
      setBuckets(allBuckets.filter((b) => bucketsId.includes(b.id)));
    }
  }, [isOpen, bucketsId, allBuckets]);

  useEffect(() => {
    if (allDrugs && buckets) {
      setDrugs(allDrugs);
    }
  }, [isOpen, allBuckets, allDrugs, buckets]);

  const refillComplete = async (bucket: Bucket) => {
    setRefillBucketId('');
    setHandledExpiredIds([...handledExpiredIds, bucket.id]);
    if (handledExpiredIds.length === bucketsId.length || bucketsId.length === 1) {
      onClose();
    }
  };

  const handleDeactivation = async (bucketId: string) => {
    try {
      await deactivateBucket(bucketId, DeactivatedReason.EXPIRED_DRUGS)(dispatch);
      setHandledExpiredIds([...handledExpiredIds, bucketId]);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        setError(content?.error?.message || t('errorOccurred'));
        return;
      }
      console.error('ERROR DEACTIVATING BUCKET', err);
    }
    if (handledExpiredIds.length === bucketsId.length || bucketsId.length === 1) {
      onClose();
    }
  };

  const emptyAndUnload = async (bucketId: string) => {
    try {
      setBucketUnloading(bucketId);
      await deactivateBucket(bucketId, DeactivatedReason.EXPIRED_DRUGS)(dispatch);
      await unloadBucket(bucketId, true)(dispatch);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        setError(content?.error?.message || t('errorOccurred'));
        return;
      }
      console.error('ERROR UNLOADING BUCKET', err);
    } finally {
      setBucketUnloading(null);
    }
  };

  const startRefill = async (bucketId: string) => {
    setRefillBucketId(bucketId);
    emptyAndUnload(bucketId);
  };

  return (
    <Modal show={isOpen} onHide={onClose} className="refill-buckets-modal">
      <Modal.Header closeButton>
        <Modal.Title>
          {t('drugsExpired')}
          {isSingleBucket && (
            <>
              <div>
                <div>
                  <br /> {t('bucket')} #{buckets[0].position}:{' '}
                  {drugs.find((d) => d.DIN === buckets[0].DIN)?.name?.toUpperCase()}{' '}
                  {drugs.find((d) => d.DIN === buckets[0].DIN)?.force?.toUpperCase()} (DIN{' '}
                  {buckets[0].DIN}) <br />
                  {buckets[0].nearestExpirationDate && (
                    <small>{t('expiredOn', { expDate: buckets[0].nearestExpirationDate })}</small>
                  )}
                </div>
              </div>
              <div>
                <LoadingButton
                  variant="primary"
                  onClick={() => emptyAndUnload(buckets[0].id)}
                  loading={bucketUnloading === buckets[0].id}
                >
                  {t('emptyAndUnload')}
                </LoadingButton>{' '}
                <Button
                  disabled={buckets[0].deactivatedReason === DeactivatedReason.EXPIRED_DRUGS}
                  onClick={() => handleDeactivation(buckets[0].id)}
                >
                  {t('deactivate')}
                </Button>
              </div>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="danger">
          <FontAwesomeIcon
            style={{ marginRight: '1.5%' }}
            icon="exclamation-triangle"
            size="1x"
            color="red"
          />
          {t('emptyBucketBeforeRefill')}
        </Alert>
        {!!error && <Alert>{t('error')}</Alert>}
        {!isSingleBucket &&
          buckets.map((bucket) => (
            <ExpiredDrugRow
              refillBucketId={refillBucketId}
              setRefillBucketId={setRefillBucketId}
              handledExpiredIds={handledExpiredIds}
              bucket={bucket}
              drugs={drugs}
              startRefill={startRefill}
              refillComplete={refillComplete}
              bucketUnloading={bucketUnloading}
              emptyAndUnload={emptyAndUnload}
              handleDeactivation={handleDeactivation}
            />
          ))}
        {isSingleBucket && (
          <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k ?? 'refillForm')}>
            <Tab eventKey="refillForm" title={t('addLot')}>
              <RefillForm
                show={isOpen && isSingleBucket}
                bucket={buckets[0]}
                drugs={drugs}
                submitDisabled={buckets[0].quantity > 0}
                submitComplete={refillComplete}
              />
            </Tab>
            <Tab eventKey="lotTable" title="Lots">
              <LotTable bucket={buckets[0]} />
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ExpiredDrugsModal;
