import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { Order, OrderStatus } from 'components/orders/types';
import { failureStatusText } from 'components/orders/helpers';
import OngoingStatusCell from './OngoingStatusCell';

interface OrderStatusCellProps {
  order: Order;
}

export const OrderStatusCell = ({ order }: OrderStatusCellProps) => {
  const halfDone =
    order.progression?.nbCardsProduced && order.progression.nbCardsProduced < order.nbCards;
  switch (order.status) {
    case OrderStatus.COMPLETED: {
      return <FontAwesomeIcon icon="check" size="1x" color="green" />;
    }

    case OrderStatus.CANCELLED: {
      return <FontAwesomeIcon icon="times" size="1x" color="red" />;
    }

    case OrderStatus.UNPROCESS: {
      return <FontAwesomeIcon icon="book" size="1x" color="grey" />;
    }

    case OrderStatus.ARCHIVED: {
      return <FontAwesomeIcon icon="archive" size="1x" color="grey" />;
    }

    case OrderStatus.UNKNOWN: {
      return (
        <>
          <FontAwesomeIcon icon="question" size="1x" color="grey" />

          <ProgressBar
            now={Math.round(
              order.progression?.percentage ? order.progression.percentage * 100 : 100,
            )}
            label={
              order.progression?.percentage
                ? `${Math.round(order.progression.percentage * 100)}%`
                : '???'
            }
            variant="secondary"
          />
        </>
      );
    }

    case OrderStatus.INTRAY: {
      return (
        <>
          <FontAwesomeIcon icon="inbox" size="1x" color="grey" style={{ marginRight: 15 }} />{' '}
          {order.trayIds[0]} {order.trayIds.length === 2 ? <> & {order.trayIds[1]}</> : <></>}{' '}
          {halfDone && (
            <ProgressBar
              now={Math.round(0.5 * 100)}
              striped
              label={`${order.progression!.nbCardsProduced}/${order.nbCards}`}
              variant="secondary"
            />
          )}
        </>
      );
    }

    case OrderStatus.FAILED: {
      return (
        <>
          <FontAwesomeIcon icon="clock" size="1x" color="#dc3545" />
          {order.failure && (
            <ProgressBar
              now={Math.round(order.failure.progression ? order.failure.progression * 100 : 100)}
              label={
                order.failure.progression
                  ? `${Math.round(order.failure.progression * 100)}%`
                  : failureStatusText(order.failure.status)
              }
              variant="danger"
            />
          )}
        </>
      );
    }

    case OrderStatus.ONGOING: {
      return <OngoingStatusCell order={order} />;
    }

    case OrderStatus.PENDING: {
      return (
        <>
          <FontAwesomeIcon icon="clock" size="1x" color="#007bff" />{' '}
          {halfDone && (
            <ProgressBar
              now={Math.round(0.5 * 100)}
              striped
              label={`${order.progression!.nbCardsProduced}/${order.nbCards}`}
              variant="secondary"
            />
          )}
        </>
      );
    }

    default: {
      return null;
    }
  }
};
