import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { Button } from 'react-bootstrap';
import { LoadingButton } from 'components/shared/LoadingButton';
import { Bucket } from '../types';
import RefillForm from './RefillForm';

interface BucketRefillRowProps {
  refillBucketId: string;
  setRefillBucketId: (id: string) => void;
  refilledBucketsIds: string[];
  bucket: Bucket;
  bucketUnloading: string | null;
  drugs: any;
  startRefill: (id: string) => void;
  refillComplete: (bucket: Bucket) => void;
  removeFromList: (bucket: Bucket) => void;
  startUnloading: (bucketId: string) => void;
}

const BucketRefillRow = ({
  bucket,
  bucketUnloading,
  refillBucketId,
  refilledBucketsIds,
  drugs,
  startRefill,
  refillComplete,
  removeFromList,
  setRefillBucketId,
  startUnloading,
}: BucketRefillRowProps) => {
  const handleNameClick = () => {
    if (refillBucketId === bucket.id) {
      setRefillBucketId('');
    } else {
      setRefillBucketId(bucket.id);
    }
  };
  return (
    <div>
      <tr>
        <td id="refillDone">
          {refilledBucketsIds.includes(bucket.id) && (
            <FontAwesomeIcon icon="check" size="1x" color="green" />
          )}
        </td>
        <td id="bucketPosition">#{bucket.position}</td>
        <td id="bucketDrug">
          <Button variant="link" onClick={handleNameClick} className="">
            {' '}
            {drugs.find((d) => d.DIN === bucket.DIN)?.name?.toUpperCase()}{' '}
            {drugs.find((d) => d.DIN === bucket.DIN)?.force?.toUpperCase()}{' '}
          </Button>
        </td>
        <td id="bucketDIN"> DIN {bucket.DIN}</td>
        <td id="monthlyQty">{`${t('qtymonth')}: ${bucket.monthlyQty}`}</td>
        <td id="refillBtns">
          {refillBucketId !== bucket.id && (
            <div>
              <Button
                disabled={refilledBucketsIds.includes(bucket.id)}
                onClick={() => startRefill(bucket.id)}
              >
                {t('unload')}
              </Button>

              <Button
                id="removeBucketFromList"
                variant="outline-secondary"
                onClick={() => removeFromList(bucket)}
              >
                <FontAwesomeIcon icon="times" />
              </Button>
            </div>
          )}
          {refillBucketId === bucket.id && (
            <div>
              <LoadingButton
                variant="primary"
                onClick={() => startUnloading(bucket.id)}
                loading={bucketUnloading === bucket.id}
              >
                {t('unload')}
              </LoadingButton>
              <Button variant="outline-secondary" onClick={() => setRefillBucketId('')}>
                <FontAwesomeIcon icon="chevron-up" />
              </Button>
            </div>
          )}
        </td>
      </tr>
      {refillBucketId === bucket.id && !refilledBucketsIds.includes(bucket.id) && (
        <RefillForm
          show={refillBucketId === bucket.id && !refilledBucketsIds.includes(bucket.id)}
          bucket={bucket}
          drugs={drugs}
          submitDisabled={false}
          submitComplete={refillComplete}
        />
      )}
      <hr />
    </div>
  );
};

export default BucketRefillRow;
