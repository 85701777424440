import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { ErrorMessage, FieldArray, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { FormValues } from '../DrugFormModal';

interface UPCsProps {
  handleBlur: FormikProps<FormValues>['handleBlur'];
  setFieldValue: FormikProps<FormValues>['setFieldValue'];
  values: FormikProps<FormValues>['values'];
}

function UPCs({ handleBlur, setFieldValue, values }: UPCsProps) {
  return (
    <Form.Group controlId="UPC">
      <FieldArray
        name="UPC"
        render={(arrayHelpers) => (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1%',
              }}
            >
              <Form.Label>UPCs</Form.Label>
              <Button onClick={() => arrayHelpers.push('')} variant="outline-secondary" size="sm">
                <FontAwesomeIcon icon="plus" />
              </Button>
            </div>
            {values.UPC.map((upc, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '1%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Form.Control
                    type="text"
                    name={`UPC[${index}]`}
                    value={upc}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const newUPCS = [...values.UPC];
                      newUPCS[index] = event.target.value;
                      setFieldValue('UPC', newUPCS);
                    }}
                  />
                  <Button
                    variant="outline-danger"
                    style={{ marginLeft: '1%' }}
                    onClick={async () => arrayHelpers.remove(index)}
                    size="sm"
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                </div>
                {upc?.length === 13 && <div style={{ color: 'orange' }}>{t('EANWarning')}</div>}
                <ErrorMessage
                  name={`UPC[${index}]`}
                  render={(msg) => <span className="error-message">{t(msg)}</span>}
                />
              </div>
            ))}
          </div>
        )}
      />
    </Form.Group>
  );
}

export default UPCs;
