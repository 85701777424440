import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import * as yup from 'yup';
import { t } from 'i18next';
import { RootState } from 'services/store';
import { handleHttpResponseError } from 'components/shared/helpers';
import { LoadingButton } from 'components/shared/LoadingButton/LoadingButton';
import ResourcesHandler from 'components/ResourcesHandler';
import { fetchOrganizations } from 'services/organizations/operations';
import { importBucketList } from 'services/buckets/operations';
import './style.scss';

const validationSchema = yup.object().shape({
  organizationName: yup.string().required('Required'),
  bucketList: yup.string().required('Required'),
});

interface FormValues {
  organizationName: string;
  organizationId: string;
  bucketList: any;
}

interface Changes {
  position: string;
  oldDIN: string;
  newDIN: string;
  oldUPC: string;
  newUPC: string;
}

const initialValues = {
  organizationName: '',
  organizationId: '',
  bucketList: null,
};

interface ImportBucketListModalProps {
  show: boolean;
  close: () => void;
}

const ImportBucketListModal = ({ show, close }: ImportBucketListModalProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const organizations = useSelector((state: RootState) => state.organizations);
  const actualBucketList = useSelector((state: RootState) => state.buckets);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errorJson, setErrorJson] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [previewChanges, setPreviewChanges] = useState([] as Changes[]);
  const [bucketOrganization, setBucketOrganization] = useState<any[]>([]);

  useEffect(() => {
    if (!show) {
      setPreviewChanges([]);
    }
  }, [show]);

  const handleSubmit = (values: FormValues): void => {
    setFormValues(values);
    setShowConfirmation(true);
  };

  function formatDIN(din: number | string): string {
    const dinStr = String(din).trim();

    if (dinStr.startsWith('R')) {
      return dinStr;
    }

    return `00000000${dinStr}`.slice(-8);
  }

  const normalizeImportedData = (importedData) =>
    importedData.map((item) => ({
      position: item['#'],
      DIN: formatDIN(item.DIN),
      UPCs: item.UPC,
    }));

  const handleDifference = (importedData) => {
    const changes = importedData.reduce((acc, item) => {
      const bucket = bucketOrganization.find(
        (b) => b.position.toString() === item.position.toString(),
      );

      if (bucket) {
        let hasChanged = false;
        const change = {
          position: item.position,
          oldDIN: bucket.DIN,
          newDIN: item.DIN,
          oldUPC: bucket.UPCs.join(', '),
          newUPC: item.UPCs.join(', '),
        };

        if (bucket.DIN !== item.DIN) {
          hasChanged = true;
        } else if (!arraysEqual(bucket.UPCs, item.UPCs)) {
          hasChanged = true;
        }

        if (hasChanged) {
          acc.push(change);
        }
      }
      return acc;
    }, []);
    setPreviewChanges(changes);
  };

  function arraysEqual(a, b) {
    if (a.length !== b.length) return false;
    const sortedA = a.slice().sort();
    const sortedB = b.slice().sort();
    for (let i = 0; i < sortedA.length; i++) {
      if (sortedA[i] !== sortedB[i]) return false;
    }
    return true;
  }

  const handleConfirmedSubmit = async () => {
    if (!formValues) return;

    setIsSubmitting(true);
    setErrorJson(false);
    try {
      await importBucketList(
        JSON.parse(formValues.bucketList),
        formValues.organizationName,
      )(dispatch);
      closeAllModals();
    } catch (err) {
      handleHttpResponseError(err, 'FAILED UPLOAD BEST SELLERS', () => {
        setErrorJson(true);
      });
    }
    setIsSubmitting(false);
  };

  const closeAllModals = () => {
    setPreviewChanges([]);
    setShowConfirmation(false);
    close();
  };

  const handleFileChange = async (event, setFieldError, setFieldValue) => {
    const file = event.target?.files?.[0];
    if (!file) {
      setFieldError('bucketList', 'No file selected');
      return;
    }

    setPreviewChanges([]);

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContents = event.target?.result;
      if (typeof fileContents === 'string') {
        try {
          const importedData = JSON.parse(fileContents);
          const normalizedData = normalizeImportedData(importedData);
          setFieldValue('bucketList', fileContents);
          handleDifference(normalizedData);
        } catch (error) {
          console.error('Error parsing JSON', error);
          setFieldError('bucketList', 'Invalid JSON file');
        }
      }
    };
    reader.onerror = () => {
      setFieldError('bucketList', 'Error reading file');
    };

    reader.readAsText(file);
  };

  const handleOrganizationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: any,
  ) => {
    setFieldValue('organizationName', event.target.value);
    const organization = organizations.find((o) => o.name === event.target.value);
    if (organization) {
      setFieldValue('organizationId', organization.id);
      setFieldValue('bucketList', null);
      const filteredBuckets = actualBucketList.filter(
        (bucket) => bucket.organizationId === organization.id,
      );

      setBucketOrganization(filteredBuckets);
      setPreviewChanges([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const getModal = () => (
    <>
      <Modal show={show} onHide={close} className="bucket-list-modal">
        <Modal.Header>
          <Modal.Title>{t('importBestSellers')}</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldError }) => (
            <Form onSubmit={handleSubmit} className="importBox">
              <Modal.Body className="form-body">
                <Form.Group controlId="organizationName">
                  <Form.Label>{t('organizations')}:</Form.Label>
                  <select
                    name="organizationName"
                    onChange={(e) => handleOrganizationChange(e, setFieldValue)}
                    className={
                      errors.organizationName && touched.organizationName ? 'error-select' : ''
                    }
                  >
                    <option>Aucune</option>
                    {organizations.map((o) => (
                      <option key={o.name} value={o.name}>
                        {o.name}
                      </option>
                    ))}
                  </select>
                  {errors.organizationName && touched.organizationName && (
                    <span className="error-message">{t('chooseOrganization')}</span>
                  )}
                </Form.Group>
                <Form.Group controlId="bucketList">
                  <Form.Label>{t('bestSellers')}:</Form.Label>
                  <input
                    className={
                      errors.bucketList && touched.bucketList
                        ? 'form-control error-file'
                        : 'form-control'
                    }
                    id="bucketList"
                    name="bucketList"
                    ref={fileInputRef}
                    type="file"
                    accept=".json"
                    onBlur={handleBlur}
                    onChange={(event) => handleFileChange(event, setFieldError, setFieldValue)}
                  />
                  {errors.bucketList && touched.bucketList && (
                    <span className="error-message">{t('chooseJSONfile')}</span>
                  )}
                </Form.Group>
              </Modal.Body>
              {previewChanges.length > 0 && (
                <div className="file-changes">
                  <h5>{t('detectedChanges')} :</h5>
                  <ul>
                    {previewChanges.map((change, index) => (
                      <li key={index}>
                        {`Position: ${change.position}, ${t('oldDIN')}: ${change.oldDIN}, ${t(
                          'newDIN',
                        )}: ${change.newDIN}`}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {errorJson && <span className="error-message">{t('jsonContentError')}</span>}
              <Modal.Footer>
                <Button variant="primary" type="submit">
                  {t('submit')}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
        <Modal.Header>
          <Modal.Title>{t('confirmImport')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('confirmImportStatement')} {formValues?.organizationName} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmation(false)}>
            {t('cancel')}
          </Button>
          <LoadingButton
            variant="primary"
            onClick={handleConfirmedSubmit}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {t('confirm')}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );

  return (
    <ResourcesHandler
      resources={[organizations]}
      resourceFetchers={[() => dispatch(fetchOrganizations())]}
      getContents={getModal}
    />
  );
};

export default ImportBucketListModal;
