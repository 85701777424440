import React from 'react';

interface IconProps {
  selected: boolean;
  disabled: boolean;
}

const OneCardIcon = ({ selected, disabled }: IconProps) => {
  let color = '';
  if (disabled && !selected) {
    color = 'grey';
  } else if (selected) {
    color = '#FFFFFF';
  } else {
    color = '#007bff';
  }
  return (
    <svg
      id="svg-1w"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="251.88679245283018"
      viewBox="0, 0, 400,251.88679245283018"
    >
      <g id="svgg-1w">
        <path
          id="path-1w"
          d="M199.450 125.629 L 199.450 247.170 296.094 247.170 C 384.744 247.170,392.774 247.159,393.166 247.043 C 394.243 246.723,395.164 245.955,395.618 244.994 L 395.873 244.458 395.873 125.629 L 395.873 6.800 395.618 6.264 C 395.164 5.303,394.243 4.534,393.166 4.215 C 392.774 4.099,384.744 4.088,296.094 4.088 L 199.450 4.088 199.450 125.629 M5.582 4.394 C 4.270 4.818,3.346 5.760,2.957 7.070 C 2.762 7.728,2.762 243.845,2.957 244.502 C 3.351 245.832,4.325 246.806,5.655 247.200 C 6.047 247.317,14.077 247.327,102.727 247.327 L 199.371 247.327 199.371 125.786 L 199.371 4.245 102.693 4.250 C 10.255 4.254,5.995 4.260,5.582 4.394 M369.964 24.684 C 371.799 24.895,373.221 26.316,373.429 28.145 C 373.533 29.060,373.533 219.368,373.429 220.283 C 373.221 222.112,371.799 223.532,369.964 223.743 C 369.055 223.848,222.769 223.848,221.860 223.743 C 220.025 223.532,218.603 222.112,218.395 220.283 C 218.291 219.366,218.291 29.057,218.395 28.150 C 218.599 26.374,220.036 24.888,221.741 24.691 C 222.550 24.597,369.149 24.591,369.964 24.684 M176.960 24.842 C 178.795 25.053,180.218 26.473,180.426 28.302 C 180.530 29.217,180.530 219.525,180.426 220.440 C 180.218 222.269,178.795 223.690,176.960 223.901 C 176.052 224.005,29.766 224.005,28.857 223.901 C 27.022 223.690,25.600 222.269,25.392 220.440 C 25.287 219.523,25.288 29.214,25.392 28.307 C 25.596 26.531,27.033 25.046,28.738 24.848 C 29.547 24.754,176.146 24.748,176.960 24.842 M41.431 39.771 C 40.119 40.195,39.195 41.137,38.806 42.447 C 38.611 43.105,38.611 207.839,38.806 208.496 C 39.200 209.826,40.174 210.800,41.504 211.194 C 42.161 211.389,161.613 211.389,162.270 211.194 C 163.599 210.800,164.573 209.826,164.968 208.496 C 165.163 207.839,165.163 43.105,164.968 42.447 C 164.573 41.118,163.599 40.144,162.270 39.749 C 161.574 39.543,42.070 39.564,41.431 39.771 "
          stroke="none"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default OneCardIcon;
