import io, { Socket } from 'socket.io-client';

let socket: Socket | null = null;
export function initializeSocket() {
  socket = io('', { transports: ['websocket', 'polling'] });
  socket.connect();
  socket.on('connect', () => {
    console.log('CONNECTED');
  });
  socket.on('disconnect', () => {
    console.log('DISCONNECTED');
  });
  socket.on('error', (err) => {
    console.error('ERROR', err);
  });
}

export function getSocket(): Socket | null {
  return socket;
}

export function getSocketAsync(): Promise<Socket | null> {
  return new Promise((resolve, reject) => {
    getSocketInternal(resolve, reject, 0);
  });
}
export function getSocketInternal(
  resolve: (s: Socket) => void,
  reject: (error: string) => void,
  count: number,
): void {
  if (socket) {
    resolve(socket);
  }
  if (count > 10) {
    reject("Couldn't obtain socket after 10 tries");
  }
  setTimeout(() => {
    getSocketInternal(resolve, reject, count + 1);
  }, 500);
}
