import React, { useState } from 'react';
import OneCardIcon from 'components/orders/OrdersTable/Cells/CardsOptions/OneCardIcon';
import TwoCardsIcon from 'components/orders/OrdersTable/Cells/CardsOptions/TwoCardsIcon';
import { Order, nbCards } from 'components/orders/types';
import { Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { changeNbCards, removeTray } from 'services/orders/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import ReplaceTrayForm from './ReplaceTrayForm';
import './style.scss';
import BarcodesForm from '../BarcodesForm';

interface TrayEditionRowProps {
  order: Order;
  trayId: string;
  isCubi: boolean;
  handleClose: () => void;
}

const TrayEditionRow = ({ order, trayId, isCubi, handleClose }: TrayEditionRowProps) => {
  const barcodesEnabled = order.barcodes[0] !== '1';
  const [editTrayId, setEditTrayId] = useState<boolean>(false);
  const [addBarcode, setAddBarcode] = useState<boolean>(false);
  const [selected, setSelected] = useState<nbCards>(order.barcodes.length as nbCards);
  const multiTrayOrder = order.barcodes.length === 3 || order.barcodes.length === 4;
  const trayIndex = order.trayIds.indexOf(trayId);
  const lowerCardsCount = multiTrayOrder ? 3 : 1;
  const upperCardsCount = multiTrayOrder ? 4 : 2;

  const handleUnlink = async () => {
    if (order.trayIds.length === 1) {
      await removeTray(order.id, trayId);
      handleClose();
    } else {
      await removeTray(order.id, trayId);
    }
  };

  const handleToggle = async (cards: nbCards) => {
    setSelected(cards);
    if (cards > order.barcodes.length && barcodesEnabled) {
      setAddBarcode(true);
    } else {
      await changeNbCards(order.id, cards);
    }
  };
  return (
    <div>
      {!editTrayId && !addBarcode && (
        <div className="tray-row">
          <div className="tray-id">
            {t('tray')} #{trayId}
          </div>
          <div className="tray-edit-buttons">
            <Button onClick={() => setEditTrayId(true)}>
              <FontAwesomeIcon icon="pen" />
            </Button>
            {(!multiTrayOrder || (multiTrayOrder && trayIndex !== 0)) && (
              <div className={addBarcode ? 'noClick singleTrayOptions' : 'singleTrayOptions'}>
                {!isCubi && (
                  <ToggleButtonGroup
                    type="radio"
                    name="options"
                    value={selected}
                    onChange={handleToggle}
                  >
                    {' '}
                    <ToggleButton value={lowerCardsCount}>
                      <div className="TrayIconContainer">
                        <OneCardIcon
                          selected={selected === 1 || selected === 3}
                          disabled={addBarcode}
                        />
                      </div>
                    </ToggleButton>
                    <ToggleButton value={upperCardsCount}>
                      <div className="TrayIconContainer">
                        <TwoCardsIcon
                          selected={selected === 2 || selected === 4}
                          disabled={addBarcode}
                        />
                      </div>
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              </div>
            )}
            {!isCubi && <Button onClick={handleUnlink}>{t('unlink')}</Button>}
          </div>
        </div>
      )}
      {editTrayId && (
        <ReplaceTrayForm
          order={order}
          initialTrayId={trayId}
          setCompleted={() => setEditTrayId(false)}
        />
      )}
      {addBarcode && (
        <div className="col">
          <div>
            {t('tray')} #{trayId}
          </div>
          <BarcodesForm
            order={order}
            selectedNbcards={selected}
            close={(selected) => {
              setAddBarcode(false);
              setSelected(selected);
            }}
          />
        </div>
      )}
      <hr />
    </div>
  );
};

export default TrayEditionRow;
