import React from 'react';
import { t } from 'i18next';
import { DrugDistribution } from 'components/orders/types';
import { emptyCardMap, parseBarcode } from 'components/orders/helpers';
import SingleBlisterCard from './SingleBlisterCard';

interface TrayDistributionProps {
  blisterCards: DrugDistribution[];
  barcodes: string[];
  DIN: string;
}

const TrayDistribution = ({ blisterCards, barcodes, DIN }: TrayDistributionProps) => {
  const emptyDistribution: DrugDistribution = {
    barcode: '',
    cardMap: emptyCardMap(),
  };

  return (
    <div className="full-card">
      <SingleBlisterCard
        barcode={parseBarcode(barcodes[0])}
        blisterCard={blisterCards[0]}
        DIN={DIN}
        traySide="left-card"
      />
      {blisterCards.length > 1 ? (
        <SingleBlisterCard
          barcode={parseBarcode(barcodes[1])}
          blisterCard={blisterCards[1]}
          DIN={DIN}
          traySide="right-card"
        />
      ) : (
        <SingleBlisterCard
          barcode={t('unassigned')}
          blisterCard={emptyDistribution}
          DIN={DIN}
          traySide="right-card"
        />
      )}
    </div>
  );
};

export default TrayDistribution;
