/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { includeDrug, toggleFlaggedDrug } from 'services/orders/endpoints';
import { notValidatedDrugWarning } from 'components/drugs/DrugRow';
import { DrugWarnings } from 'components/drugs/types';
import {
  DrugOrder,
  DrugOrderExcludedReason,
  DrugOrderStatus,
  Order,
  OrderStatus,
} from '../../types';
import ScanFormUPC from './ScanFormUPC';
import './style.scss';
import MachineDistributionIcon from './MachineDistributionIcon';
import FlagModal from '../Modals/FlagModal';
import AltDrugModal from '../Modals/altDrugModal';
import ExcludedUnknownRow from './ExcludedUnknownRow';
import { ActiveRow } from '../ExpandedOrder';
import ExcludedDinUnavailableRow from './ExcludedDinUnavailableRow';

interface ExcludedRowProps {
  order: Order;
  activeRow: ActiveRow;
  drugOrder: DrugOrder;
  splitted: boolean;
  listNum: number;
  close: () => void;
  setActiveRow: (activeRow: ActiveRow) => void;
  setValidUPC: (valid: boolean) => void;
}

const ExcludedRow = ({
  order,
  activeRow,
  drugOrder,
  splitted,
  listNum,
  close,
  setActiveRow,
  setValidUPC,
}: ExcludedRowProps) => {
  const { t } = useTranslation();
  const buckets = useSelector((state: RootState) => state.buckets);
  const drugs = useSelector((state: RootState) => state.drugs);
  const drug = drugs.find((d) => d.DIN === drugOrder.DIN);
  const [UPCInput, setUPCInput] = useState<string>('');
  const [showAltModal, setShowAltModal] = useState<boolean>(false);
  const [showFlagModal, setShowFlagModal] = useState<boolean>(false);
  const [upcValidationVisual, setUpcValidationVisual] = useState<
    'neutral' | 'valid' | 'warning' | 'invalid'
  >('neutral');

  // const inTray = order.status === OrderStatus.INTRAY; BYPASS

  if (
    drugOrder.distributions.some(
      (distribution) => distribution.excludedReason === DrugOrderExcludedReason.DIN_UNAVAILABLE,
    )
  ) {
    return (
      <ExcludedDinUnavailableRow
        activeRow={activeRow}
        drugOrder={drugOrder}
        close={() => close()}
        listNum={listNum}
        setActiveRow={setActiveRow}
      />
    );
  }

  if (!drug) {
    return (
      <ExcludedUnknownRow
        order={order}
        activeRow={activeRow}
        drugOrder={drugOrder}
        splitted={splitted}
        listNum={listNum}
        close={() => close()}
        setActiveRow={setActiveRow}
        setValidUPC={setValidUPC}
      />
    );
  }

  const isActiveRow = activeRow && activeRow.DIN === drugOrder.DIN;

  const altAvailable = () => {
    if (
      !drug.alternatives ||
      drugOrder.distributions.some(
        (d) =>
          d.excludedReason === DrugOrderExcludedReason.DUPLICATE_DIN ||
          d.excludedReason === DrugOrderExcludedReason.DIN_UNAVAILABLE ||
          d.excludedReason === DrugOrderExcludedReason.NO_QUANTITY ||
          d.excludedReason === DrugOrderExcludedReason.UNEQUAL_DISTRIBUTION,
      )
    ) {
      return undefined;
    }

    for (const alt of drug.alternatives) {
      const matchingBucket = buckets.find((bucket) => bucket.DIN === alt && !bucket.isDeactivated);
      if (matchingBucket) {
        return drugs.find((drug) => drug.DIN === matchingBucket.DIN);
      }
    }

    return undefined;
  };

  useEffect(() => {
    if (
      !UPCInput &&
      isActiveRow &&
      drugOrder.distributions.some(
        (d) => d.excludedReason === DrugOrderExcludedReason.MANUAL_EXCLUSION,
      )
    ) {
      // This *if* is a temporary BYPASS. it will be removed at some point.
      setUpcValidationVisual('valid');
      setValidUPC(true);
    } else if (
      !UPCInput &&
      drugOrder.distributions.some((d) => d.status === DrugOrderStatus.DISTRIBUTED) &&
      isActiveRow
    ) {
      setUpcValidationVisual('valid');
      setValidUPC(true);
    } else if (!UPCInput && !drugOrder.isFlagged) {
      setUpcValidationVisual('neutral');
      setValidUPC(false);
    } else if (drug.UPC.includes(UPCInput)) {
      setUpcValidationVisual('valid');
      setValidUPC(true);
    } else if (drug.newUPC.includes(UPCInput) || (drugOrder.isFlagged && isActiveRow)) {
      setUpcValidationVisual('warning');
      setValidUPC(true);
    } else {
      setUpcValidationVisual('invalid');
      setValidUPC(false);
    }
  }, [UPCInput, isActiveRow, drug.newUPC, drug.newUPC.length, drug.UPC, drugOrder, setValidUPC]);

  const handleNameClick = () => {
    if (isActiveRow) {
      close();
    } else {
      setActiveRow({ DIN: drugOrder.DIN, excluded: true });
    }
  };
  return (
    <div className={isActiveRow ? `${upcValidationVisual} excluded-row` : 'excluded-row'}>
      <div className="drug-info">
        <span className="completed-cell">
          {drugOrder.distributions.some(
            (d) => d.isExcluded && d.status === DrugOrderStatus.DISTRIBUTED,
          ) && <FontAwesomeIcon icon="check" size="1x" color="green" />}
        </span>
        <span className="split-cell">
          {listNum}
          {splitted ? '*' : ' '}
        </span>
        <span className="drugName-cell">
          <Button variant="link" onClick={handleNameClick}>
            {drug.name.toUpperCase()}
          </Button>
          <span> DIN {drugOrder.DIN}</span>
        </span>
        <span className="drugForce-cell">{drug && drug.force ? drug.force.toUpperCase() : ''}</span>
        {/* BYPASS <span className={inTray ? 'noClick flag-cell' : 'flag-cell'}> */}
        <span className="flag-cell">
          {/* ^ this is temporary */}
          {(drugOrder.isFlagged || isActiveRow) && (
            <FontAwesomeIcon
              onClick={
                drugOrder.isFlagged
                  ? () => toggleFlaggedDrug(order.id, drugOrder.DIN, '')
                  : () => setShowFlagModal(true)
              }
              icon="flag"
              size="1x"
              color={drugOrder.isFlagged ? 'red' : 'grey'}
            />
          )}
        </span>
        {drug.warnings?.includes(DrugWarnings.NOT_VALIDATED) && notValidatedDrugWarning(drug.id)}
        <span className="action-cell">
          {' '}
          {altAvailable() && (
            <Button
              /* BYPASS disabled={inTray} */ variant="warning"
              onClick={() => setShowAltModal(true)}
            >
              <FontAwesomeIcon icon="exchange-alt" size="1x" color="black" /> {t('generic')}
            </Button>
          )}
          {drugOrder.distributions.some(
            (d) => d.excludedReason === DrugOrderExcludedReason.MANUAL_EXCLUSION,
          ) && (
            <Button
              className="btn-include-drug"
              /* BYPASS disabled={inTray} */
              onClick={() => includeDrug(order.id, drugOrder.DIN)}
              variant="outline-secondary"
            >
              <MachineDistributionIcon /> {t('include')}
            </Button>
          )}
        </span>
        <span className="closeActive-cell">
          {isActiveRow && <FontAwesomeIcon onClick={close} icon="times" size="1x" color="grey" />}
        </span>
      </div>

      {isActiveRow &&
        order.status === OrderStatus.PENDING &&
        drugOrder.distributions.some((d) => d.status === DrugOrderStatus.UNDISTRIBUTED) && (
          <div className="matrix-and-upc-form">
            <ScanFormUPC drug={drug} setUPC={setUPCInput} visual={upcValidationVisual} />
          </div>
        )}

      {drugOrder.isFlagged && isActiveRow && (
        <p className="flag-reason">
          <FontAwesomeIcon icon="flag" color="grey" /> : {drugOrder.isFlagged}
        </p>
      )}

      <AltDrugModal
        show={showAltModal}
        drugsData={{ currentDrug: drug, alternativeDrug: altAvailable() }}
        order={order}
        handleClose={() => {
          setShowAltModal(false);
        }}
      />
      <FlagModal
        show={showFlagModal}
        orderId={order.id}
        drugDIN={drugOrder.DIN}
        close={() => setShowFlagModal(false)}
      />
    </div>
  );
};

export default ExcludedRow;
