import React, { ChangeEvent, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, Form, Table, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { RootState } from 'services/store';
import DrugRow from './DrugRow';
import { parseForce } from './helpers';
import './style.scss';
import { DrugWarnings } from './types';

export interface DrugDependencies {
  setSelectedDrugId: (drugId: string) => void;
  setShowDrugDetails: (show: boolean) => void;
  setShowUpdateDrug: (show: boolean) => void;
  setShowDeleteDrug: (show: boolean) => void;
}

function DrugsTable({
  setSelectedDrugId,
  setShowDrugDetails,
  setShowUpdateDrug,
  setShowDeleteDrug,
}: DrugDependencies): JSX.Element {
  const [query, setQuery] = useState<string>('');
  const [invalidSearch, setInvalidSearch] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.login);
  const drugs = useSelector((state: RootState) => state.drugs);
  const [filterNotValidatedDrug, setFilterNotValidatedDrug] = useState<boolean>(false);
  const [drugFormats, setDrugFormats] = useState<string[]>([]);
  const [selectedFormats, setSelectedFormats] = useState<string[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(
    null,
  );
  const { t } = useTranslation();
  const notValidatedDrugCount = drugs.filter((drug) =>
    drug.warnings?.includes(DrugWarnings.NOT_VALIDATED),
  ).length;

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setInvalidSearch(false);
    const regex = /^[a-zA-Z0-9 /()-]+$/;
    if (regex.test(e.target.value) || !e.target.value) {
      setQuery(e.target.value);
    } else {
      setInvalidSearch(true);
    }
  };

  useEffect(() => {
    const getAllDrugFormats = (): string[] => {
      const formats = new Set<string>();
      drugs.forEach((drug) => {
        if (drug.format) {
          formats.add(drug.format);
        } else {
          formats.add(t('unspecified'));
        }
      });
      return Array.from(formats).sort();
    };
    const allDrugFormats = getAllDrugFormats();
    setDrugFormats(allDrugFormats);
  }, [drugs, t]);

  const handleFormatChange = (format: string) => {
    setSelectedFormats((prev) => {
      if (prev.includes(format)) {
        return prev.filter((f) => f !== format);
      }
      return [...prev, format];
    });
  };

  const handleSort = (columnKey: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key: columnKey, direction });
  };

  const filteredAndSortedDrugs = useMemo(() => {
    const compareValues = (a: any, b: any, key: string): number => {
      if (key === 'force') {
        return parseForce(a[key]) - parseForce(b[key]);
      }
      if (typeof a[key] === 'string') {
        return a[key].localeCompare(b[key]);
      }
      return a[key] - b[key];
    };

    const result = drugs.filter(
      (drug) =>
        (!query ||
          drug.name.toLowerCase().includes(query.toLowerCase()) ||
          drug.DIN.toLowerCase().includes(query.toLowerCase())) &&
        (!filterNotValidatedDrug ||
          (drug.warnings && drug.warnings.includes(DrugWarnings.NOT_VALIDATED))) &&
        (selectedFormats.length === 0 || selectedFormats.includes(drug.format || 'Non spécifié')),
    );

    if (sortConfig !== null) {
      result.sort((a, b) => {
        const comparison = compareValues(a, b, sortConfig.key);
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      });
    }

    return result;
  }, [drugs, sortConfig, query, filterNotValidatedDrug, selectedFormats]);

  const rows = filteredAndSortedDrugs.map((drug) => (
    <DrugRow
      key={drug.id}
      drug={drug}
      canUpdate={user?.permissions?.includes('drugs.update')}
      canDelete={user?.permissions?.includes('drugs.delete')}
      dependencies={{
        setSelectedDrugId,
        setShowDrugDetails,
        setShowUpdateDrug,
        setShowDeleteDrug,
      }}
    />
  ));

  return (
    <>
      <div style={{ display: 'flex', width: '85%' }}>
        <div style={{ position: 'relative', width: '75%' }}>
          <Form.Control
            type="text"
            onChange={handleChangeSearch}
            placeholder={`${t('search')}...`}
            isInvalid={invalidSearch}
          />
          <Form.Control.Feedback type="invalid">{t('invalidSearchQuery')}</Form.Control.Feedback>
          <FontAwesomeIcon
            icon="search"
            color="black"
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              display: 'inline-block',
              fontSize: 20,
              position: 'absolute',
              top: 10,
              right: 12,
              zIndex: 2,
            }}
          />
        </div>
        <ToggleButtonGroup className="ml-2" type="checkbox">
          <ToggleButton
            id="toggle-check"
            variant={notValidatedDrugCount > 0 ? 'outline-danger' : 'outline-secondary'}
            checked={filterNotValidatedDrug}
            value="1"
            onChange={(e) => setFilterNotValidatedDrug(e.currentTarget.checked)}
          >
            {t('notValidatedDrugCount', { notValidatedDrugCount })}
          </ToggleButton>
        </ToggleButtonGroup>

        <Dropdown className="ml-2">
          <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
            {selectedFormats.length > 0
              ? `${selectedFormats.length} ${t('selectedFormat')}`
              : t('format')}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {drugFormats.map((format) => (
              <Dropdown.Item
                key={format}
                onClick={() => handleFormatChange(format)}
                active={selectedFormats.includes(format)}
              >
                {format}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <br />
      <Table responsive bordered striped>
        <thead>
          <tr>
            <th style={{ width: 200, cursor: 'pointer' }} onClick={() => handleSort('DIN')}>
              {t('DIN')}{' '}
              {sortConfig?.key === 'DIN' && (
                <FontAwesomeIcon
                  icon={sortConfig.direction === 'asc' ? faChevronUp : faChevronDown}
                />
              )}
            </th>
            <th style={{ cursor: 'pointer' }} onClick={() => handleSort('name')}>
              {t('drug')}{' '}
              {sortConfig?.key === 'name' && (
                <FontAwesomeIcon
                  icon={sortConfig.direction === 'asc' ? faChevronUp : faChevronDown}
                />
              )}
            </th>
            <th style={{ width: 200, cursor: 'pointer' }} onClick={() => handleSort('force')}>
              {t('strength')}{' '}
              {sortConfig?.key === 'force' && (
                <FontAwesomeIcon
                  icon={sortConfig.direction === 'asc' ? faChevronUp : faChevronDown}
                />
              )}
            </th>
            <th style={{ width: 200, cursor: 'pointer' }} onClick={() => handleSort('format')}>
              {t('format')}{' '}
              {sortConfig?.key === 'format' && (
                <FontAwesomeIcon
                  icon={sortConfig.direction === 'asc' ? faChevronUp : faChevronDown}
                />
              )}
            </th>
            {user?.permissions?.some(
              (perm) => perm === 'drugs.update' || perm === 'drugs.delete',
            ) && <th style={{ width: '10%' }}>{t('actions')}</th>}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  );
}

export default DrugsTable;
