import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleHttpResponseError } from 'components/shared/helpers';
import { Formik, FormikHelpers, ErrorMessage } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { includeDrug, toggleFlaggedDrug } from 'services/orders/endpoints';
import { t } from 'i18next';
import { LoadingButton } from 'components/shared/LoadingButton';
import { drugSchema } from 'components/drugs/DrugForm/DrugFormModal';
import { createDrug } from 'services/drugs/operations';
import DrugForceFields from 'components/drugs/DrugForm/DrugForceFields';
import { DrugForms } from 'components/drugs/types';
// the eslint disable is part of the BYPASS
import { DrugOrder, DrugOrderExcludedReason, DrugOrderStatus, Order } from '../../types'; // eslint-disable-line
import './style.scss';
import MachineDistributionIcon from './MachineDistributionIcon';
import FlagModal from '../Modals/FlagModal';
import { ActiveRow } from '../ExpandedOrder';

interface ExcludedUnknownRowProps {
  order: Order;
  activeRow: ActiveRow;
  drugOrder: DrugOrder;
  splitted: boolean;
  listNum: number;
  close: () => void;
  setActiveRow: (activeRow: ActiveRow) => void;
  setValidUPC: (valid: boolean) => void;
}

interface FormValues {
  name: string;
  force: string;
  format: string;
  newUPC: string[];
}

const emptyFormValues = {
  name: '',
  force: '',
  format: '',
  newUPC: [],
};

const validationSchema = drugSchema.pick(['name', 'force', 'format', 'newUPC']);

const ExcludedUnknownRow = ({
  order,
  activeRow,
  drugOrder,
  splitted,
  listNum,
  close,
  setActiveRow,
  setValidUPC,
}: ExcludedUnknownRowProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [showFlagModal, setShowFlagModal] = useState<boolean>(false);
  const [upcValidationVisual, setUpcValidationVisual] = useState<'neutral' | 'valid' | 'warning'>(
    'neutral',
  );

  const loadInitialValues = (DIN: string): FormValues => {
    const savedValues = localStorage.getItem(`formValues_${DIN}`);
    return savedValues ? JSON.parse(savedValues) : emptyFormValues;
  };

  const [initialValues, setInitialValues] = useState<FormValues>(loadInitialValues(drugOrder.DIN));

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    try {
      setSubmitting(true);
      const drugInfos = {
        DIN: drugOrder.DIN,
        UPC: [],
        ...values,
      };
      localStorage.removeItem(`formValues_${drugOrder.DIN}`);
      await createDrug(drugInfos)(dispatch);
    } catch (err) {
      handleHttpResponseError(err, 'FAILED CREATE DRUG', setFieldError);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (activeRow && activeRow.DIN === drugOrder.DIN) {
      localStorage.setItem(`formValues_${drugOrder.DIN}`, JSON.stringify(initialValues));
    }
  }, [initialValues, activeRow, drugOrder.DIN]);

  useEffect(() => {
    if (activeRow && activeRow.DIN === drugOrder.DIN) {
      setInitialValues(loadInitialValues(drugOrder.DIN));
    }
  }, [activeRow, drugOrder.DIN]);

  // const inTray = order.status === OrderStatus.INTRAY; BYPASS
  const isActiveRow = activeRow && activeRow.DIN === drugOrder.DIN;

  useEffect(() => {
    if (
      isActiveRow &&
      drugOrder.distributions.some((d) => d.status === DrugOrderStatus.UNDISTRIBUTED) &&
      !drugOrder.isFlagged
    ) {
      setUpcValidationVisual('neutral');
      setValidUPC(true);
    } else if (isActiveRow && drugOrder.isFlagged) {
      setUpcValidationVisual('warning');
      setValidUPC(true);
    } else if (
      isActiveRow &&
      drugOrder.distributions.some((d) => d.status === DrugOrderStatus.DISTRIBUTED)
    ) {
      setUpcValidationVisual('valid');
      setValidUPC(true);
    }
  }, [isActiveRow, drugOrder, setValidUPC]);

  const handleNameClick = () => {
    if (isActiveRow) {
      setValidUPC(false);
      close();
    } else {
      setActiveRow({ DIN: drugOrder.DIN, excluded: true });
      setValidUPC(true);
    }
  };

  const handleInputChange = (
    eOrString: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  ) => {
    if (typeof eOrString === 'string') {
      const fieldName = 'force';
      const value = eOrString;
      setFieldValue(fieldName, value);
      setInitialValues((prev) => ({ ...prev, [fieldName]: value }));
    } else {
      const { name, value } = eOrString.target;
      setFieldValue(name, value);
      setInitialValues((prev) => ({ ...prev, [name]: value }));
    }
  };

  return (
    <div className={isActiveRow ? `${upcValidationVisual} excluded-row` : 'excluded-row'}>
      <div className="drug-info">
        <span className="completed-cell">
          {drugOrder.distributions.some((d) => d.status === DrugOrderStatus.DISTRIBUTED) && (
            <FontAwesomeIcon icon="check" size="1x" color="green" />
          )}
        </span>
        <span className="split-cell">
          {listNum}
          {splitted ? '*' : ' '}
        </span>
        <span className="drugName-cell">
          <Button variant="link" onClick={handleNameClick}>
            {t('unknownDrug')}
          </Button>
          <span> DIN {drugOrder.DIN}</span>
        </span>

        <span className="drugForce-cell" />

        {/* BYPASS <span className={inTray ? 'noClick flag-cell' : 'flag-cell'}> */}
        <span className="flag-cell">
          {/* this is temporary */}
          {(drugOrder.isFlagged || isActiveRow) && (
            <FontAwesomeIcon
              onClick={
                drugOrder.isFlagged
                  ? () => toggleFlaggedDrug(order.id, drugOrder.DIN, '')
                  : () => setShowFlagModal(true)
              }
              icon="flag"
              size="1x"
              color={drugOrder.isFlagged ? 'red' : 'grey'}
            />
          )}
        </span>

        <span className="action-cell">
          {drugOrder.distributions.some(
            (d) => d.excludedReason === DrugOrderExcludedReason.MANUAL_EXCLUSION,
          ) && (
            <Button
              className="btn-include-drug"
              /* BYPASS disabled={inTray} */
              onClick={() => includeDrug(order.id, drugOrder.DIN)}
              variant="outline-secondary"
            >
              <MachineDistributionIcon /> Inclure
            </Button>
          )}
        </span>
        <span className="closeActive-cell">
          {isActiveRow && <FontAwesomeIcon onClick={close} icon="times" size="1x" color="grey" />}
        </span>
      </div>
      {isActiveRow && (
        <div style={{ padding: '1%' }}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label>{t('name')}</Form.Label>
                  <Form.Control
                    autoFocus={isActiveRow}
                    className={errors.name && touched.name ? 'error-text' : ''}
                    type="text"
                    name="name"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      handleInputChange(event, setFieldValue);
                    }}
                    value={values.name}
                  />
                  <ErrorMessage
                    name="name"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <DrugForceFields
                  handleInputChange={handleInputChange}
                  setFieldValue={setFieldValue}
                  value={values.force}
                />
                <Form.Group controlId="format">
                  <Form.Label>{t('format')}</Form.Label>
                  <Form.Control
                    as="select"
                    className={errors.name && touched.name ? 'error-text' : ''}
                    name="format"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      handleInputChange(event, setFieldValue);
                    }}
                    value={values.format}
                  >
                    <option value="" />
                    {DrugForms.map((form) => (
                      <option key={form} value={form}>
                        {form}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>UPC</Form.Label>
                  <Form.Control
                    className={errors.newUPC && touched.newUPC ? 'error-text' : ''}
                    type="text"
                    name="newUPC"
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const newUPC = event.target.value !== '' ? [event.target.value] : [];
                      handleInputChange(
                        { target: { name: 'newUPC', value: newUPC } },
                        setFieldValue,
                      );
                    }}
                    placeholder="Scan UPC"
                    value={values.newUPC[0]}
                  />
                  {values.newUPC[0]?.length === 13 && (
                    <div style={{ color: 'orange' }}>{t('EANWarning')}</div>
                  )}
                  <ErrorMessage
                    name="newUPC"
                    render={(msg) => <span className="error-message">{t(msg)}</span>}
                  />
                </Form.Group>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <LoadingButton
                    variant="primary"
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {t('submit')}
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {drugOrder.isFlagged && isActiveRow && (
        <p className="flag-reason">
          <FontAwesomeIcon icon="flag" color="grey" /> : {drugOrder.isFlagged}
        </p>
      )}
      <FlagModal
        show={showFlagModal}
        orderId={order.id}
        drugDIN={drugOrder.DIN}
        close={() => setShowFlagModal(false)}
      />
    </div>
  );
};

export default ExcludedUnknownRow;
