import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DrugsTable from 'components/drugs/DrugsTable';
import { fetchDrugs, deleteDrug } from 'services/drugs/operations';
import { fetchUsers } from 'services/users/operations';
import { RootState } from 'services/store';
import { Button } from 'react-bootstrap';
import ImportDrugListModal from 'components/drugs/ImportDrugListModal';
import DrugFormModal from 'components/drugs/DrugForm/DrugFormModal';
import DrugDetailsModal from 'components/drugs/DrugDetailsModal';
import ConfirmationModal from 'components/shared/ConfirmationModal/ConfirmationModal';

const DrugsPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const drugs = useSelector((state: RootState) => state.drugs);
  const user = useSelector((state: RootState) => state.login);
  const { t } = useTranslation();

  const [selectedDrugId, setSelectedDrugId] = useState<string | null>(null);
  const [showImportDrugList, setShowImportDrugList] = useState<boolean>(false);
  const [showCreateDrug, setShowCreateDrug] = useState<boolean>(false);
  const [showDrugDetails, setShowDrugDetails] = useState<boolean>(false);
  const [showUpdateDrug, setShowUpdateDrug] = useState<boolean>(false);
  const [showDeleteDrug, setShowDeleteDrug] = useState<boolean>(false);

  const handleDeleleDrug = async () => {
    try {
      if (selectedDrugId) await deleteDrug(selectedDrugId)(dispatch);
    } catch (err) {
      console.error(err);
    } finally {
      setShowDeleteDrug(false);
      setSelectedDrugId(null);
    }
  };

  const deleteConfirmationMessage = (drugId: string | null): JSX.Element => {
    const drug = drugs.find((drug) => drug.id === drugId);
    return (
      <>
        {drug && (
          <>
            <p>{t('deleteDrugConfirmation')}</p>
            <p style={{ textAlign: 'center' }}>
              <strong>{drug.DIN}</strong> - <strong>{drug.name.toUpperCase()}</strong> -{' '}
              <strong>{drug.force ? drug.force.toUpperCase() : ''}</strong>
            </p>
          </>
        )}
      </>
    );
  };

  const getPage = () => (
    <>
      <h2>{t('drugs')}</h2>
      <div
        style={{
          float: 'right',
          marginBottom: 20,
          cursor: 'pointer',
        }}
      >
        {user?.permissions?.includes('drugs.create') && (
          <>
            <Button
              className="mr-2"
              variant="outline-secondary"
              onClick={() => setShowImportDrugList(true)}
            >
              <FontAwesomeIcon icon="file-import" />
            </Button>
            <Button variant="outline-secondary" onClick={() => setShowCreateDrug(true)}>
              <FontAwesomeIcon icon="plus" />
            </Button>
          </>
        )}
      </div>
      <ImportDrugListModal show={showImportDrugList} close={() => setShowImportDrugList(false)} />
      <DrugFormModal
        show={showCreateDrug || showUpdateDrug}
        close={() => {
          setShowCreateDrug(false);
          setShowUpdateDrug(false);
        }}
        onExited={() => setSelectedDrugId(null)}
        drugId={selectedDrugId}
        changeInContextDrugId={setSelectedDrugId}
      />
      {selectedDrugId && (
        <DrugDetailsModal
          show={showDrugDetails}
          close={() => setShowDrugDetails(false)}
          onExited={() => setSelectedDrugId(null)}
          drugId={selectedDrugId}
        />
      )}

      <ConfirmationModal
        show={showDeleteDrug}
        onHide={() => {
          setSelectedDrugId(null);
          setShowDeleteDrug(false);
        }}
        onConfirm={handleDeleleDrug}
        message={deleteConfirmationMessage(selectedDrugId)}
      />
      <DrugsTable
        {...{
          setSelectedDrugId,
          setShowDrugDetails,
          setShowUpdateDrug,
          setShowDeleteDrug,
        }}
      />
    </>
  );

  return (
    <ResourcesHandler
      resources={[drugs]}
      resourceFetchers={[() => dispatch(fetchDrugs()), () => dispatch(fetchUsers())]}
      getContents={getPage}
    />
  );
};

export default DrugsPage;
