import React, { useState } from 'react';
import { Order } from 'components/orders/types';
import { FieldArray, Formik, isEmptyArray } from 'formik';
import { addTray, getTrayOrder, removeTray } from 'services/orders/endpoints';
import * as yup from 'yup';
import { Alert, Button, Form } from 'react-bootstrap';
import { t } from 'i18next';
import { handleHttpResponseError } from 'components/shared/helpers';
import { parseBarcode } from 'components/orders/helpers';
import AlreadyLinkedAlert from '../AlreadyLinkedAlert';
import './style.scss';

interface FormValues {
  trayId: string | undefined;
  barcodes: string[] | undefined;
}

interface AddTrayFormProps {
  order: Order;
  setCompleted: () => void;
}

interface FormSubmitProps {
  values: FormValues;
  setSubmitting: (isSubmitting: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;
}

const AddTrayForm = ({ order, setCompleted }: AddTrayFormProps) => {
  const barcodesEnabled = order.barcodes[0] !== '1';
  const [alreadyLinked, setAlreadyLinked] = useState<{
    order: Order;
    trayId: string;
    barcodes: string[] | undefined;
  } | null>(null);
  const [unlinkFailed, setUnlinkFailed] = useState<boolean>(false);
  const [hasDuplicates, setHasDuplicates] = useState<boolean>(false);
  const initialValues: FormValues = {
    trayId: undefined,
    barcodes: barcodesEnabled
      ? order.barcodes.concat(new Array(4 - order.barcodes.length).fill(''))
      : undefined,
  };

  const hasDuplicatesfct = (array: Array<string>) => {
    const seen = new Set<string>();
    const duplicates: number[] = [];
    array.forEach((item, index) => {
      if (seen.has(item)) {
        duplicates.push(index);
      } else {
        seen.add(item);
      }
    });
    return duplicates;
  };

  const handleBarcodes = async ({ values, setSubmitting, setFieldError }: FormSubmitProps) => {
    if (!values.barcodes!.every((b) => b !== '' && b !== null)) {
      return;
    }
    setSubmitting(true);
    setHasDuplicates(false);
    handleAddTray({ values, setSubmitting, setFieldError });
  };

  const unlinkAndAdd = async () => {
    try {
      await Promise.all([
        removeTray(alreadyLinked!.order.id, alreadyLinked!.trayId),
        addTray(order.id, alreadyLinked!.trayId, alreadyLinked!.barcodes),
        setCompleted(),
      ]);
      setAlreadyLinked(null);
    } catch (err) {
      console.error('FAILED TO UNLINK AND ADD', err);
      setUnlinkFailed(true);
    }
  };

  const handleAddTray = async ({ values, setSubmitting, setFieldError }: FormSubmitProps) => {
    const inTray = await getTrayOrder(values.trayId!).then((res) => res.order);

    if (!inTray) {
      try {
        await Promise.all([addTray(order.id, values.trayId!, values.barcodes), setCompleted()]);
        setSubmitting(false);
      } catch (err) {
        handleHttpResponseError(err, 'FAILED TO ADD TRAY', setFieldError);
      }
    } else {
      setAlreadyLinked({ order: inTray, trayId: values.trayId!, barcodes: values.barcodes });
    }

    setSubmitting(false);
  };

  const formSubmit = async ({ values, setSubmitting, setFieldError }: FormSubmitProps) => {
    setSubmitting(true);
    setHasDuplicates(false);
    setAlreadyLinked(null);
    setUnlinkFailed(false);

    if (barcodesEnabled) {
      const duplicates = hasDuplicatesfct(values.barcodes!);
      if (!isEmptyArray(duplicates)) {
        setHasDuplicates(true);
        setSubmitting(false);
        return;
      }
    }

    handleAddTray({ values, setSubmitting, setFieldError });
  };

  const schema = yup.object().shape({
    trayId: yup
      .string()
      .matches(/^[0-9]+$/, t('fieldOnlyAcceptNumbers'))
      .required(t('trayIdRequired')),
    barcodes: yup.array().of(yup.string().required('barcodeRequired')),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        formSubmit({ values, setSubmitting, setFieldError });
      }}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setSubmitting,
        setFieldError,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="trayId">
            <Form.Label>{t('addTray_4cards')}</Form.Label>
            <Form.Control
              type="text"
              name="trayId"
              autoFocus
              value={values.trayId}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.trayId}
            />
            <Form.Control.Feedback type="invalid">
              {errors.trayId && t(errors.trayId)}
            </Form.Control.Feedback>
          </Form.Group>
          {barcodesEnabled && (
            <Form.Group controlId="barcodes">
              <FieldArray
                name="barcodes"
                render={() => (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '1%',
                      }}
                    >
                      <Form.Label>{t('pleaseScanTheCardLabel')}</Form.Label>
                    </div>
                    {values.barcodes!.map((barcode, index) => (
                      <div
                        key={index}
                        style={{
                          marginBottom: '1%',
                        }}
                      >
                        <div className="row">
                          <div className="col">
                            <Form.Control
                              type="text"
                              name={`barcodes[${index}]`}
                              value={parseBarcode(barcode)}
                              readOnly={index < order.barcodes.length}
                              placeholder={parseBarcode(values.barcodes![index])}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              isInvalid={!!errors.barcodes && !!errors.barcodes[index]}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                              {errors.barcodes && t(errors.barcodes[index])}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
            </Form.Group>
          )}
          {hasDuplicates && <Alert variant="warning">{t('uniqueBarcodes')}</Alert>}
          {alreadyLinked && (
            <AlreadyLinkedAlert linkedOrder={alreadyLinked.order} nextAction={unlinkAndAdd} />
          )}
          {unlinkFailed && <p className="error-message">{t('encounterError')}</p>}
          {!hasDuplicates && (
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {t('submit')}
            </Button>
          )}
          {hasDuplicates && (
            <Button
              variant="warning"
              type="submit"
              disabled={isSubmitting}
              onClick={() => handleBarcodes({ values, setSubmitting, setFieldError })}
            >
              {t('confirm')}
            </Button>
          )}{' '}
          <Button onClick={setCompleted}> {t('cancel')}</Button>
        </Form>
      )}
    </Formik>
  );
};

export default AddTrayForm;
