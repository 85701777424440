import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import UsersTable from 'components/users/UsersTable';
import { RootState } from 'services/store';
import { fetchUsers } from 'services/users/operations';
import { fetchOrganizations } from 'services/organizations/operations';
import { UserRole } from 'components/users/types';
import { checkIsAdmin } from 'utils/permissions';

const UsersPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const users = useSelector((state: RootState) => state.users);
  const organizations = useSelector((state: RootState) => state.organizations);
  const { t } = useTranslation();
  const loginState = useSelector((state: RootState) => state.login);
  const isAdmin = checkIsAdmin(loginState);

  const getPage = () => (
    <>
      <h2>{t('users')}</h2>
      <Tabs defaultActiveKey={UserRole.TECH}>
        <Tab eventKey={UserRole.TECH} title={t('technicians')}>
          <UsersTable userRoles={[UserRole.TECH, UserRole.SUPER_TECH, UserRole.UNKNOWN]} />
        </Tab>
        {isAdmin && (
          <Tab eventKey={UserRole.ADMIN} title={t('administrators')}>
            <UsersTable userRoles={[UserRole.ADMIN, UserRole.CHRONO_TECH]} />
          </Tab>
        )}
      </Tabs>
    </>
  );

  return (
    <ResourcesHandler
      resources={[users, organizations]}
      resourceFetchers={[() => dispatch(fetchUsers()), () => dispatch(fetchOrganizations())]}
      getContents={getPage}
    />
  );
};

export default UsersPage;
