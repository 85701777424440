import React from 'react';
import { DrugDistribution } from 'components/orders/types';
import TrayDistribution from './TrayDistribution';

interface VisualDistributionProps {
  blisterCards: DrugDistribution[];
  barcodes: string[];
  DIN: string;
}

const VisualDistribution = ({ blisterCards, barcodes, DIN }: VisualDistributionProps) => (
  <>
    <TrayDistribution blisterCards={blisterCards} barcodes={barcodes} DIN={DIN} />
    {blisterCards.length > 2 && (
      <TrayDistribution
        blisterCards={blisterCards.slice(2)}
        barcodes={barcodes.slice(2)}
        DIN={DIN}
      />
    )}
  </>
);

export default VisualDistribution;
