import React, { useEffect, useRef, useState } from 'react';
import RefillBucketsModal from 'components/buckets/RefillModals/RefillBucketsModal';
import LinkToTrayModal from 'components/orders/Modals/TraysModal/LinkToTrayModal';
import NewestOrderModal from 'components/orders/Modals/NewestOrderModal';
import RefillNeededModal from 'components/orders/Modals/RefillNeededModal';
import EditTraysModal from 'components/orders/Modals/TraysModal/EditTraysModal/EditTraysModal';
import TwoWeeksModal from 'components/orders/Modals/TwoWeeksModal';
import ExpiredDrugsModal from 'components/buckets/ExpiredDrugsModal/ExpiredDrugsModal';
import { Order, OrderType } from 'components/orders/types';
import './style.scss';
import { triggerOrder } from 'services/orders/endpoints';
import { Overlay } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { t } from 'i18next';
import MainButtons from './MainButtons';
import SecondaryButtons from './SecondaryButtons';

interface ButtonsCellProps {
  order: Order;
  status: OrderType | undefined;
}

export enum Actions {
  LINK = 'LINK',
  EDIT_TRAY = 'EDIT_TRAY',
  REFILL = 'REFILL',
}

export enum Warnings {
  NOT_NEW = 'NOT_NEW',
  OLDER_TWO_WEEKS = 'OLDER_TWO_WEEKS',
  REFILL_NEEDED = 'REFILL_NEEDED',
  NOT_ALL_DISTRIBUTE = 'NOT_ALL_DISTRIBUTE',
  NO_INCLUDED_DRUGS = 'NO_INCLUDED_DRUGS',
  EXPIRED_DRUGS_IN_BUCKET = 'EXPIRED_DRUGS_IN_BUCKET',
}

export const ButtonsCell = ({ order, status }: ButtonsCellProps) => {
  const [action, setAction] = useState<Actions | null>(null);
  const [warning, setWarning] = useState<Warnings | null>(null);
  const [toRefill, setToRefill] = useState<string[]>([]);
  const [expiredDrugs, setExpiredDrugs] = useState<string[]>([]);
  const [buttonTarget, setButtonTarget] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    if (warning === Warnings.NOT_ALL_DISTRIBUTE || warning === Warnings.NO_INCLUDED_DRUGS) {
      setTimeout(() => {
        setWarning(null);
      }, 3000);
    }
  }, [warning]);

  return (
    <div className="buttons-container">
      <div className="order-buttons">
        <div ref={ref} className="main-order-buttons">
          <MainButtons
            order={order}
            setAction={setAction}
            setWarning={setWarning}
            setToRefill={setToRefill}
            setExpiredDrugs={setExpiredDrugs}
            setButtonTarget={setButtonTarget}
          />
        </div>
        <div className="secondary-order-buttons">
          <SecondaryButtons
            order={order}
            status={status}
            setWarning={setWarning}
            setAction={setAction}
            setToRefill={setToRefill}
            setExpiredDrugs={setExpiredDrugs}
          />
        </div>
      </div>

      <Overlay
        show={warning === Warnings.NOT_ALL_DISTRIBUTE}
        target={buttonTarget}
        placement="left"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">{t('excludedNotDistributed')}</Popover>
      </Overlay>

      <Overlay
        show={warning === Warnings.NO_INCLUDED_DRUGS}
        target={buttonTarget}
        placement="left"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">{t('noIncludedDrugs')}</Popover>
      </Overlay>

      <LinkToTrayModal show={action === Actions.LINK} order={order} close={() => setAction(null)} />

      <ExpiredDrugsModal
        isOpen={warning === Warnings.EXPIRED_DRUGS_IN_BUCKET}
        bucketsId={expiredDrugs}
        onClose={() => {
          setExpiredDrugs([]);
          setWarning(null);
        }}
      />

      <RefillBucketsModal
        isOpen={action === Actions.REFILL}
        bucketsId={toRefill}
        editList={setToRefill}
        onClose={() => {
          setToRefill([]);
          setAction(null);
        }}
      />

      <EditTraysModal
        show={action === Actions.EDIT_TRAY}
        order={order}
        handleClose={() => setAction(null)}
      />

      <RefillNeededModal
        show={warning === Warnings.REFILL_NEEDED}
        bucketsId={toRefill}
        order={order}
        refill={() => setAction(Actions.REFILL)}
        link={() => setAction(Actions.LINK)}
        handleClose={() => setWarning(null)}
      />

      <TwoWeeksModal
        show={warning === Warnings.OLDER_TWO_WEEKS}
        order={order}
        handleClose={() => setWarning(null)}
        sendOrder={() => triggerOrder(order.id, order.machineId)}
      />
      <NewestOrderModal
        show={warning === Warnings.NOT_NEW}
        currentOrder={order}
        handleClose={() => setWarning(null)}
        sendOrder={() => triggerOrder(order.id, order.machineId)}
      />
    </div>
  );
};
