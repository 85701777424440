import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { loadMultipleBuckets } from 'services/buckets/operations';
import { HttpResponseError } from 'services/api';
import { useTranslation } from 'react-i18next';

interface LoadMultipleModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LoadMultipleModal = ({ isOpen, onClose }: LoadMultipleModalProps): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { t } = useTranslation();

  const handleLoadMultipleBuckets = async () => {
    try {
      await loadMultipleBuckets()(dispatch);
    } catch (err) {
      if (err instanceof HttpResponseError) {
        const content = await err.response?.json();
        console.error('ERROR LOADING BUCKET', content);
        return;
      }
      console.error('ERROR LOADING BUCKET', err);
    }
    onClose();
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('multipleLoadBucket')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('loadMultipleMessage')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleLoadMultipleBuckets()}>
          {t('send')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoadMultipleModal;
