import * as yup from 'yup';

export const validation = {
  userName: yup
    .string()
    .required('userNameRequired')
    .test('no-space', 'userNameAlphanumericValidation', (value: string | undefined) => {
      if (!value) return false;
      const alphanumericRegex = /^[a-zA-Z0-9_.-]+$/;
      return alphanumericRegex.test(value);
    }),
  password: yup
    .string()
    .required('passwordRequired')
    .min(8, 'minimumPasswordLength')
    .max(64, 'maximumPasswordLength')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,64}$/,
      'invalidPasswordFormat',
    ),
  confirmPassword: yup
    .string()
    .required('confirmPasswordRequired')
    .oneOf([yup.ref('password')], 'passwordMustMatch'),
  emailAddress: yup.string().email('invalidEmail').optional(),
  lastName: yup.string().required('lastNameRequired'),
  firstName: yup.string().required('firstNameRequired'),
};

export const validationAdmin = {
  userName: yup
    .string()
    .required('userNameRequired')
    .test('no-space', 'userNameAlphanumericValidation', (value: string | undefined) => {
      if (!value) return false;
      const alphanumericRegex = /^[a-zA-Z0-9_.-]+$/;
      return alphanumericRegex.test(value);
    }),
  password: yup
    .string()
    .required('passwordRequired')
    .min(8, 'minimumPasswordLength')
    .max(64, 'maximumPasswordLength')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,64}$/,
      'invalidPasswordFormat',
    ),
  confirmPassword: yup
    .string()
    .required('confirmPasswordRequired')
    .oneOf([yup.ref('password')], 'passwordMustMatch'),
  emailAddress: yup.string().email('invalidEmail').required('emailAddressRequired'),
  lastName: yup.string().required('lastNameRequired'),
  firstName: yup.string().required('firstNameRequired'),
};

export const createUserValidation = yup.object().shape(validation);

export const createAdminValidation = yup.object().shape(validationAdmin);

export const patchUserValidation = yup.object().shape({
  userName: validation.userName,
  lastName: validation.lastName,
  firstName: validation.firstName,
  emailAddress: validation.emailAddress,
});

export const passwordResetValidationSchema = yup.object().shape({
  password: validation.password,
  confirmPassword: validation.confirmPassword,
});
