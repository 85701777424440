import React, { useState } from 'react';
import { Modal, Form, Spinner } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { t } from 'i18next';
import { handleHttpResponseError } from 'components/shared/helpers';
import { LoadingButton } from 'components/shared/LoadingButton';
import { importDrugList } from 'services/drugs/operations';
import { Drug } from './types';
import { drugSchema } from './DrugForm/DrugFormModal';

const validationSchema = yup.array().of(drugSchema).typeError('invalidFormat');

interface FormValues {
  drugList: Omit<Drug, 'id' | 'newUPC'>[] | null;
}

const initialValues: FormValues = {
  drugList: null,
};

interface ImportDrugListModalProps {
  show: boolean;
  close: () => void;
}

const ImportDrugListModal = ({ show, close }: ImportDrugListModalProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [fileError, setFileError] = useState<string>('');
  const [fileLoading, setFileLoading] = useState<boolean>(false);

  const handleFileChange = async (event, setFieldValue) => {
    const file = event.target?.files?.[0];
    setFieldValue('drugList', null);
    if (file) {
      setFileLoading(true);
      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target instanceof FileReader && typeof event.target.result === 'string') {
          try {
            const parsedData = JSON.parse(event.target.result);
            try {
              await validationSchema.validate(parsedData);
              setFieldValue('drugList', parsedData);
              setFileError('');
            } catch (validationError: any) {
              setFileError(validationError.message);
            }
          } catch (jsonError) {
            setFileError('invalidJSONFile');
          } finally {
            setFileLoading(false);
          }
        } else {
          setFileError('cannotReadFile');
        }
      };
      reader.readAsText(file);
    } else {
      setFileError('fileRequired');
    }
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ): Promise<any> => {
    if (!values.drugList) {
      setFileError('fileRequired');
    } else {
      try {
        setSubmitting(true);
        await importDrugList(values.drugList)(dispatch);
        close();
      } catch (err) {
        handleHttpResponseError(err, 'FAILED UPLOAD DRUG LIST', setFileError);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setFileError('');
        close();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('importDrugList')}</Modal.Title>
      </Modal.Header>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                >
                  <Form.Control
                    type="file"
                    name="file"
                    accept="application/json"
                    onChange={(event) => handleFileChange(event, setFieldValue)}
                  />
                  {fileLoading && (
                    <Spinner animation="border" size="sm" style={{ marginLeft: '1%' }} />
                  )}
                </div>
                {fileError && <div className="error-message">{t(fileError)}</div>}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <LoadingButton
                variant="primary"
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || fileLoading || fileError !== ''}
              >
                {t('submit')}
              </LoadingButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ImportDrugListModal;
