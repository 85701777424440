import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drug } from 'components/drugs/types';
import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { addDrugUPC } from 'services/drugs/operations';
import './style.scss';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { t } from 'i18next';

export const validationSchema = yup.object().shape({
  UPC: yup
    .string()
    .matches(/^\d+$/, 'incorrectUPCFormat')
    .test(
      'lengthCheck',
      'incorrectUPCLength',
      (value) => !value || value.length === 12 || value.length === 13,
    ),
});

interface ScanFormUPCProps {
  drug: Drug;
  visual: string;
  setUPC: (UPC: string) => void;
}

const ScanFormUPC = ({ drug, visual, setUPC }: ScanFormUPCProps) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const scannedUPC = localStorage.getItem('scanned-upc');
  // eslint-disable-next-line no-unneeded-ternary

  const formik = useFormik({
    initialValues: {
      UPC: scannedUPC || '',
    },
    validationSchema,
    onSubmit: async (values) => {
      localStorage.setItem('scanned-upc', values.UPC);
      await addDrugUPC(drug.id, values.UPC)(dispatch);
    },
  });

  const handleRefresh = () => {
    formik.setFieldValue('UPC', '');
    setUPC('');
  };

  useEffect(() => {
    if (scannedUPC) {
      localStorage.setItem('scanned-upc', '');
      setUPC(scannedUPC);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    formik.handleChange(event);
    setUPC(inputValue);
  };

  return (
    <Form className={`${visual} scanUPC`} onSubmit={formik.handleSubmit}>
      <Form.Group>
        <div className="upc-and-refresh">
          <Form.Control
            autoFocus
            className={formik.errors.UPC && formik.touched.UPC ? 'error-text' : ''}
            type="text"
            name="UPC"
            value={formik.values.UPC}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            placeholder="Scan UPC"
          />
          <Button type="button" onClick={handleRefresh}>
            <FontAwesomeIcon icon="trash" />
          </Button>
          <Button disabled={!formik.isValid || visual !== 'invalid'} type="submit">
            <FontAwesomeIcon icon="plus" />
          </Button>
        </div>
        {formik.values.UPC?.length === 13 && (
          <div style={{ color: 'orange', marginLeft: '5%' }}>{t('EANWarning')}</div>
        )}
        {formik.errors.UPC && formik.touched.UPC ? (
          <span className="error-text" style={{ marginLeft: '5%' }}>
            {t(formik.errors.UPC)}
          </span>
        ) : (
          ''
        )}
      </Form.Group>
    </Form>
  );
};

export default ScanFormUPC;
