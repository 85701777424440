import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { enable2FA, verify2FA } from 'services/login/operations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Modal, Popover, OverlayTrigger } from 'react-bootstrap';
import '../login.scss';

interface TwoFAModalProps {
  show: boolean;
  userId: string;
  close: () => void;
  setTwoFAToken: (e) => void;
  handleRefresh2FA: () => void;
}

const TwoFAModal = ({
  show,
  userId,
  close,
  setTwoFAToken,
  handleRefresh2FA,
}: TwoFAModalProps): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const [qrCodeDataURL, setQrCodeDataURL] = useState('');
  const [twoFATokenModal, setTwoFATokenModal] = useState('');
  const [setupStep, setSetupStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberDevice, setRememberDevice] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const handleEnable2FA = async () => {
    try {
      const res: any = await dispatch(enable2FA(userId));
      if (res.payload.error) {
        setErrorMessage(t(res.payload.error));
      } else {
        setQrCodeDataURL(res.payload.qrCodeDataURL);
        setSetupStep(2);
      }
    } catch (error) {
      setErrorMessage(t('errorOccurred'));
    }
  };

  const handleVerify2FA = async () => {
    setIsLoading(true);
    try {
      const res: any = await dispatch(verify2FA(userId, twoFATokenModal, rememberDevice));

      if (res.payload.error) {
        const jsonBody = await res.payload.error.response?.json();
        if (jsonBody.invalidToken) {
          setErrorMessage(t('invalidToken'));
        }
      } else if (res) {
        await handleRefresh2FA();
        close();
      } else {
        setErrorMessage(t('invalidResponse'));
      }
    } catch (error) {
      setErrorMessage(t('errorOccurred'));
    } finally {
      setIsLoading(false);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{t('compatible2FAApps')}</Popover.Title>
      <Popover.Content>
        <ul>
          <li>Google Authenticator</li>
          <li>Authy</li>
          <li>Microsoft Authenticator</li>
          <li>LastPass Authenticator</li>
          <li>FreeOTP</li>
        </ul>
      </Popover.Content>
    </Popover>
  );

  return (
    <Modal show={show} onHide={close} className="two-fa-modal twoFAmodalP">
      <Modal.Header closeButton>
        <Modal.Title>{t('twoFASetup')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="twoFAmodal">
        {setupStep === 1 && (
          <div className="setup-step-1">
            <Button variant="primary" onClick={handleEnable2FA}>
              {t('enable2FA')}
            </Button>
            {errorMessage && <div className="error-twofamodal">{errorMessage}</div>}
          </div>
        )}
        {setupStep === 2 && (
          <div>
            <p>
              {t('scanQrCodeInstructions')}{' '}
              <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ cursor: 'pointer', marginLeft: '5px' }}
                />
              </OverlayTrigger>
            </p>
            <img
              style={{ width: '220px', height: '220px' }}
              src={qrCodeDataURL}
              alt="QR Code for 2FA setup"
            />
            <Form.Group controlId="twoFAToken">
              <Form.Label>{t('enter2FACode')}</Form.Label>
              <Form.Control
                type="text"
                name="twoFAToken"
                value={twoFATokenModal}
                onChange={(e) => {
                  setTwoFATokenModal(e.target.value);
                  setTwoFAToken(e.target.value);
                }}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleVerify2FA} disabled={isLoading}>
              {t('verify')}
            </Button>
            {errorMessage && <div className="error-twofamodal">{errorMessage}</div>}
          </div>
        )}
        <Form.Group controlId="rememberDevice" className="twoFAmodal-check">
          <Form.Check
            type="checkbox"
            label={t('rememberThisDevice')}
            checked={rememberDevice}
            onChange={(e) => setRememberDevice(e.target.checked)}
          />
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
};

export default TwoFAModal;
