import React from 'react';
import { TooltipProps } from 'recharts';
import './style.scss';

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload;

    return (
      <div className="custom-tooltip">
        <p className="intro">{dataPoint.value}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
