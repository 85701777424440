import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPills, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FaGaugeHigh } from 'react-icons/fa6';
import '../style.scss';
import { TotalDrugMean } from '../types';

export interface TotalMeanDrugDisplay {
  name: string;
  value: {
    total: number;
    mean: number;
  };
}

interface Props {
  totalDrugMean: TotalDrugMean | undefined;
  loading: boolean;
}

const TotalMeanDrug: React.FC<Props> = ({ totalDrugMean, loading }): JSX.Element => {
  const { t } = useTranslation();
  const [totalMeanDrug, setTotalMeanDrug] = useState<TotalMeanDrugDisplay>();
  const [noData, setNoData] = useState<boolean>(false);

  useEffect(() => {
    if (totalDrugMean) {
      setNoData(false);
      if (!totalDrugMean.value.mean) {
        setNoData(true);
        return;
      }
      setTotalMeanDrug({ name: t('meanDrugAndTotal'), value: totalDrugMean.value });
    }
  }, [totalDrugMean, t]);

  return (
    <>
      <div className="componentBase total-mean-container">
        {loading ? (
          <div style={{ marginLeft: '4vw' }}>
            <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
          </div>
        ) : (
          <>
            <div className="total-drug-icon">
              <FontAwesomeIcon icon={faPills} style={{ fontSize: '24px' }} />
            </div>
            <div className="time-sections">
              <div className="total-mean-placeholder">
                {noData ? 0 : totalMeanDrug?.value.total.toFixed(0)}
                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{t('totalDrug')}</div>
                <div style={{ fontSize: '10px', fontWeight: 'bold', color: '#b1b1b1' }}>
                  ({t('thisMonth')})
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="componentBase mean-container">
        {loading ? (
          <div style={{ marginLeft: '4vw' }}>
            <FontAwesomeIcon icon={faSpinner} style={{ fontSize: '38px', color: 'gray' }} spin />
          </div>
        ) : (
          <>
            <div className="mean-drug-icon">
              <FaGaugeHigh style={{ fontSize: '25px' }} />{' '}
            </div>
            <div className="time-sections">
              <div className="total-mean-placeholder">
                {noData ? 'N/A' : totalMeanDrug?.value.mean.toFixed(0)}
                <div style={{ fontSize: '12px', fontWeight: 'bold' }}>{t('meanBucket')}</div>
                <div style={{ fontSize: '10px', fontWeight: 'bold', color: '#b1b1b1' }}>
                  ({t('thisMonth')})
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TotalMeanDrug;
