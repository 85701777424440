import React from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ResourcesHandler from 'components/ResourcesHandler';
import SettingsTable from 'components/settings/SettingsTable';
import { RootState } from 'services/store';
import { fetchSettings } from 'services/settings/operations';
import { fetchUsers } from 'services/users/operations';

const SettingsPage = (): JSX.Element => {
  const dispatch = useDispatch<Dispatch<any>>();
  const settings = useSelector((state: RootState) => state.settings);
  const { t } = useTranslation();

  const getPage = () => (
    <>
      <h2>{t('settings')}</h2>
      <SettingsTable />
    </>
  );

  return (
    <ResourcesHandler
      resources={[settings]}
      resourceFetchers={[() => dispatch(fetchSettings()), () => dispatch(fetchUsers())]}
      getContents={getPage}
    />
  );
};

export default SettingsPage;
