import { CreateMachine, Machine } from 'components/machines/types';
import types from './types';

export type MachineReducerState = Machine[];

export type MachineAction =
  | { type: types.CREATE_MACHINE_REQUEST; payload: { machine: CreateMachine } }
  | { type: types.CREATE_MACHINE_RESPONSE; payload: { machine: Machine } }
  | { type: types.GET_MACHINES_REQUEST }
  | { type: types.GET_MACHINES_RESPONSE; payload: { machines: Machine[] } }
  | {
      type: types.UPDATE_MACHINE_REQUEST;
      payload: { id: string; machine: Machine };
    }
  | { type: types.UPDATE_MACHINE_RESPONSE; payload: { machine: Machine } }
  | { type: types.DELETE_MACHINE_REQUEST; payload: { machineId: string } }
  | { type: types.DELETE_MACHINE_RESPONSE; payload: { machine: Machine } };

export default function reducers(
  state: MachineReducerState = null!,
  action: MachineAction,
): MachineReducerState {
  switch (action.type) {
    case types.GET_MACHINES_RESPONSE: {
      return action.payload.machines;
    }
    case types.CREATE_MACHINE_RESPONSE: {
      return (state || []).concat(action.payload.machine);
    }
    case types.UPDATE_MACHINE_RESPONSE: {
      return state.map((machine) => {
        if (machine.id === action.payload.machine.id) {
          return action.payload.machine;
        }
        return machine;
      });
    }
    case types.DELETE_MACHINE_RESPONSE: {
      return state.filter((machine) => machine.id !== action.payload.machine.id);
    }
    default:
      return state;
  }
}
